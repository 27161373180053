import {EventEmitter, Injectable} from "@angular/core";
import {isRouteSecured, ToBase64} from '../helpers/string.helper';
import {AuthInfo, LoginEmitterMessage, LoginResult} from "../interfaces/general";
import {Sentences, SettingsService} from "./settings.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";
import {CartService} from "../modules/cart/cart.service";
import {removeFromSession, saveToSession} from "../helpers/cookie.helper";
import {CartTokenService} from "../modules/cart/cart-token.service";
import {finalize, take} from "rxjs/operators";
import {GeneralDialogService} from "../modules/general-dialog/general-dialog.service";
import {MergeCartDialogComponent} from "../modules/merge-cart-dialog/merge-cart-dialog/merge-cart-dialog.component";
import {GeneralDialogConfig} from "../modules/general-dialog/general-dialog-config";
import {IMergeCartDialogConfig} from "../modules/merge-cart-dialog/commons/commons";
import {IMergeCartResult, MergeCartType} from "../modules/cart/commons/commons";
import {Observable} from "rxjs";
import {DataService} from "./data.service";
import {UserService} from "./user.service";

declare let moment: any;

// todo: UNIT TEST FOR THIS SERVICE

@Injectable()
export class DigestService {

    badCredentialsMessage: string = 'bad credentials, login failed';
    errorType: string = 'error';

    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;

    loginStatus: EventEmitter<LoginEmitterMessage>;
    onLoginQuery: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private http: HttpClient,
        private seSvc: SettingsService,
        private router: Router,
        private cartSvc: CartService,
        private usrSvc: UserService,
        private cartTokenSvc: CartTokenService,
        private dialogSvc: GeneralDialogService,
        private dataSvc: DataService,
        private route: ActivatedRoute
    ) {

        this.route.queryParams
            .subscribe(params => {
                if(params?.ecmid){
                    saveToSession("ecmid",params.ecmid);
                }
            });

        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            DigestService.initNotSigned();
        } else {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.userName = ai.userName;
        }

        this.loginStatus = new EventEmitter();
    }

    private static initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
    }

    login(strategy: ILoginConfig): void {

        /*if(strategy instanceof FacebookLogin){
            throw new Error('Not implemented');
        }*/

        this.requestedUri = 'api/login';
        this.requestedMethod = 'GET';

        let headers: HttpHeaders = strategy.GetHeaders();
        headers = headers.append('Captcha-Secret', strategy.Secret());

        this.dataSvc.dataLoading = true;
        this.http.get<LoginResult>(this.requestedUri, {headers: headers})
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (r) => {
                    CredentialStorage.authInfo = {
                        userName: r.userName,
                        displayName: r.companyDisplayName,
                        loggedIn: true,
                        validTo: moment().add(r.tokenExpirationMinutes, 'minutes').format(),
                        newsletterSubscribed: r.newsletterSubscribed,
                        isB2B: r.isB2B,
                        userId: r.userId,
                        companyId: r.companyId
                    };

                    this.userName = r.userName;

                    this.cartTokenSvc.cartTokens = r.cartTokens;

                    if (!this.seSvc.bindCurrencyAndCultureToDomain) {
                        this.seSvc.setCulture(r.cultureId, r.currencyId);
                    }

                    // BLOCK ADJUST CART

                    /**
                     here you can freely implement any cart-adjusting logic
                     because on Flex there is an agreement that the carts should be allways merged
                     I call merge method
                     */
                    this.usrSvc.reloadUserContactInfo();
                    this.usrSvc.loaded.subscribe(()=>{
                        this.cartSvc.cart.withDeliveryAddress = this.usrSvc.preferredDeliveryAddress != null;
                    });


                    if (r.mergeInfo?.CurrentCartProducts?.length && r.mergeInfo?.PreviousCartProducts?.length) {

                        const data: IMergeCartDialogConfig = {
                            MergeInfo: r.mergeInfo
                        };
                        const config: GeneralDialogConfig<IMergeCartDialogConfig> = {
                            data: data,
                            cssClassModifier: 'merge',
                            isCloseAble: false,
                            title: Sentences.sen['merge-cart--dialog-title']
                        };
                        const dialogRef = this.dialogSvc.open(MergeCartDialogComponent, config);
                        dialogRef.afterClosed
                            .subscribe((res: MergeCartType) => {

                                let tmp: Observable<IMergeCartResult> = null;

                                if (res === MergeCartType.Merge) {
                                    tmp = this.cartSvc.MergeCart({
                                        Type: MergeCartType.Merge,
                                        Current: null,
                                        Previous: null,
                                        Tokens: r.cartTokens.map(x => x.cartToken)
                                    });
                                } else if (res === MergeCartType.KeepPrevious) {
                                    tmp = this.cartSvc.MergeCart({
                                        Type: MergeCartType.KeepPrevious,
                                        Current: r.cartTokens.find(x => x.isCurrent === true).cartToken,
                                        Previous: r.cartTokens.filter(x => x.isCurrent === false).map(x => x.cartToken),
                                        Tokens: null
                                    });
                                } else if (res === MergeCartType.KeepCurrent) {
                                    tmp = this.cartSvc.MergeCart({
                                        Type: MergeCartType.KeepCurrent,
                                        Current: r.cartTokens.find(x => x.isCurrent === true).cartToken,
                                        Previous: r.cartTokens.filter(x => x.isCurrent === false).map(x => x.cartToken),
                                        Tokens: null
                                    });
                                } else {
                                    throw 'NotImplementedException()';
                                }

                                this.dataSvc.dataLoading = true;
                                tmp.pipe(take(1))
                                    .pipe(finalize(() => {
                                        this.dataSvc.dataLoading = false;
                                    }))
                                    .subscribe(() => {
                                        this.redirectAfterLogin(r.reqInfo);
                                    });
                            });
                    } else {
                        this.dataSvc.dataLoading = true;
                        this.cartSvc.MergeCart({
                            Type: MergeCartType.Merge,
                            Current: null,
                            Previous: null,
                            Tokens: r.cartTokens.map(x => x.cartToken)
                        }).pipe(finalize(() => {
                            this.dataSvc.dataLoading = false;
                        })).subscribe(() => {
                            this.redirectAfterLogin(r.reqInfo);
                        });
                    }

                    // BLOCK ADJUST CART
                },
                (e: HttpErrorResponse) => this.loginStatus.emit({
                    type: this.errorType,
                    message: this.badCredentialsMessage,
                    data: e
                })
            );
    }

    private redirectAfterLogin(reqInfo: boolean): void {
        //this.dataSvc.dataLoading = true;  //navigation back keeps the loader on, also keeps secured content...

        let root: string = (location.host == 'localhost') ? '/elimacz/' : '/';

        if (reqInfo) {
            location.href = root + 'muj-ucet/osobni-udaje';
        } else if (this.router.url == '/kosik/obsah') {
            location.href = root + 'kosik/obsah';
        } else if (this.router.url == '/kosik/doprava-a-platba') {
            location.href = root + 'kosik/obsah';
        } else {
            location.href = root;
        }
    }

    logOut(routeToIndex?: boolean) {
        this.http.get(`api/signout`)
            .pipe(take(1))
            .subscribe(() => {
                CredentialStorage.removeAuthInfo();
                this.userName = undefined;
                this.password = undefined;
                removeFromSession('cadj');

                this.cartSvc.cleanCartOnLogout();
                this.cartTokenSvc.clear();

                let fwd = this.router.url;
                let url;
                if (routeToIndex || isRouteSecured(fwd)) {
                    if (routeToIndex) {
                        url = '/';
                        this.router.navigateByUrl(url).then(() => {
                            location.reload();
                        });
                    } else {
                        url = fwd ? `/upozorneni?fwd=${fwd}` : '/upozorneni';
                        location.href = url;
                        location.reload();
                    }

                } else {
                    location.reload();
                }
            })
    }

    queryLogin(): void {
        this.onLoginQuery.emit();
    }

    /**private getLoginHeaders(): HttpHeaders {
        let result = `Basic username="${this.userName}", password="${this.password}"`;

        return new HttpHeaders({'Authorization': result});
    }**/
}

export interface ILoginConfig {
    GetHeaders(): HttpHeaders;

    LoginType(): string;

    Secret(): string;
}

export class StandardLogin implements ILoginConfig {
    constructor(private userName: string, private password: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Standard", username="${ToBase64(this.userName)}", password="${ToBase64(this.password)}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Standard";
    }

    Secret(): string {
        return this.secret;
    }
}

export class AdminLogin implements ILoginConfig {
    constructor(private userName: string, private password: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Admin", username="${this.userName}", password="${this.password}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Admin";
    }

    Secret(): string {
        return this.secret;
    }
}

export class FacebookLogin implements ILoginConfig {
    constructor(private accessToken: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Facebook", accessToken="${this.accessToken}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Facebook";
    }

    Secret(): string {
        return this.secret;
    }
}

export class GoogleLogin implements ILoginConfig {
    constructor(private accessToken: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Google", accessToken="${this.accessToken}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Google";
    }

    Secret(): string {
        return this.secret;
    }
}
