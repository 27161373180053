import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MergeCartDialogComponent } from './merge-cart-dialog/merge-cart-dialog.component';



@NgModule({
  declarations: [
    MergeCartDialogComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MergeCartDialogModule { }
