import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {

    transform(value: string, replacementString: string, replaceValue: string): string {
        const tmp = value.replace(new RegExp(replacementString, 'g'), replaceValue);
        return tmp;
    }

}
