<div [formGroup]="form" class="modal-cookies">
    <div class="modal-cookies__header">
        <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/img-cookies.svg'" alt="Cookies">

        <div class="modal-cookies__title heading-h1">
            {{sen['cookies-panel--title']}}
        </div>
    </div>

    <div class="modal-cookies__text">
        <div [innerHTML]="cookiesConsentTexts?.about"></div>
    </div>

    <div class="modal-cookies-tabs">
        <ng-container *ngFor="let group of allGroups, let i = index">
            <div class="modal-cookies-tabs__item" [class.modal-cookies-tabs__item--active]="group.isShown" *ngIf="isInForm(group.code)">
                <div class="modal-cookies-tabs__title" (click)="makeActive(group.id)">
                    <div class="form-checkbox">
                        <input id="volba-{{i}}" type="checkbox" [formControlName]="group.code" [checked]="group.code === 'functionality_storage'" [attr.disabled]="group.code === 'functionality_storage' ? 'disabled' : null">

                        <label for="volba-{{i}}">
                            {{group.name}} ({{group.cookiesCount}})
                        </label>
                    </div>

                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-down"/>
                        </svg>
                    </div>
                </div>

                <div class="modal-cookies-tabs__content">
                    <ng-container *ngTemplateOutlet="tpl"></ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="modal-cookies__actions">
        <button class="btn btn--conversion btn--medium" (click)="enableSelected()">
            {{sen['cookies-consent-enable-selected']}}
        </button>

        <button class="btn btn--conversion btn--medium" (click)="enableRequired()">
            {{sen['cookies-consent-functional-only']}}
        </button>
    </div>

    <div class="modal-cookies__note" *ngIf="ccSvc?.lastUpdate">
        Prohlášení o cookies bylo naposledy aktualizováno {{ccSvc?.lastUpdate}}
    </div>
</div>

<ng-template #tpl>
    <p>
        {{activeDescription}}
    </p>

    <cmp-cookies-list [groupId]="activeGroupId"></cmp-cookies-list>
</ng-template>