<div class="footer-store">
    <div class="footer-store__address">
        {{sen['footer-store-address']}}
    </div>

    <a href="" class="footer-store__link btn-link">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right"/>
            </svg>
        </div>

        {{sen['footer-store-show-on-map']}}
    </a>

    <div class="footer-store__table" [innerHTML]="sen['footer-opening-hours']">
    </div>
</div>