import {Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AddressSelector, CompanySelector, UserContactSummarySelector, UserSelector} from "../modules/address/common";
import {take} from "rxjs/operators";
import {ErrorService} from "./error.service";
import {Observable, ReplaySubject} from "rxjs";
import {CredentialStorage} from "./credential-storage.service";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {IRights} from "../modules/rights/commons/commons";
import {CartService} from "../modules/cart/cart.service";

@Injectable()
export class UserService {

    loaded: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    //region public fields
    company: CompanySelector = null;
    invoiceAddress: AddressSelector = null;
    deliveryAddresses: Array<AddressSelector> = [];
    user: UserSelector = null;
    rights: IRights;
    //endregion

    //region public properties
    get preferredDeliveryAddress(): AddressSelector {
        return this.deliveryAddresses?.find(da => da.isPreferred);
    }

    //endregion


    constructor(private http: HttpClient, private eSvc: ErrorService, private injector: Injector) {
        this.init();
    }

    private init(): void {
        this.reloadUserContactInfo();
    }

    reloadUserContactInfo(cartDeliveryAddressUpdate:boolean = false): void {
            if (CredentialStorage.userLoggedIn) {
                let url = `api/account/getCompleteUserInfo`;
                this.http.get<UserContactSummarySelector>(url)
                    .pipe(take(1))
                    .subscribe(
                        (res) => {
                            // console.log(res);
                            this.company = res.company;
                            this.invoiceAddress = res.invoiceAddress;
                            this.deliveryAddresses = res.deliveryAddresses;
                            this.user = res.user;
                            if(cartDeliveryAddressUpdate){
                                const cartSvc: CartService = <CartService>this.injector.get(CartService);
                                cartSvc.cartContentChanged.pipe(take(1)).subscribe(()=>{
                                    if(cartSvc.cart){
                                        cartSvc.cart.withDeliveryAddress = this.preferredDeliveryAddress != null;
                                        if(this.preferredDeliveryAddress){
                                            cartSvc.cart.deliveryAddress = this.preferredDeliveryAddress;
                                        }
                                        cartSvc.persist();
                                    }
                                })
                            }
                            this.loaded.next(true);
                        },
                        (err) => {
                            this.eSvc.handleError({error: err, request: {url: url, method: 'get'}}, 'getUserContactInfo');
                            this.loaded.next(false);
                        }
                    );
            }

    }

    public UserNameExistValidator(id: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const o = new Observable<ValidationErrors>((ob) => {
                let request: any = {
                    UserName: control.value,
                    UserId: id
                };

                this.http.post('api/user/user-name-exists-validation', request)
                    .pipe(take(1))
                    .subscribe((res: any) => {
                        if (res.Exists) {
                            ob.next({
                                exists: true
                            });
                        } else {
                            ob.next(null);
                        }
                        ob.complete();
                    });
            });

            return o;
        }
    }
}
