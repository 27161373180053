/**
 * Created by jiri.kopel on 06.03.2019.
 */
import {Component, ElementRef, HostListener, Input, ViewChild, ViewEncapsulation} from "@angular/core";
import {
    Translatable,
    ISearchRequest,
    ISearchResult
} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {HttpClient} from "@angular/common/http";
import {fromEvent, Observable, Subject} from "rxjs";
import {map, filter, debounceTime, switchMap, takeUntil, finalize} from "rxjs/operators";
import {Router} from "@angular/router";
import {SearchService} from "../../services/search.service";
import {ToQueryStringParam} from "../../helpers/string.helper";
import {MenuService} from "../../services/menu.service";

declare let $: any;
declare let StringView: any;

@Component({
    selector: 'cmp-flex-suggest',
    templateUrl: '../../tpl/flex-suggest.html',
    styleUrls: ['../../assets/styles/3-layout/header/search.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FlexSuggestComponent extends Translatable {
    get suggestVisible(): boolean {
        return this._suggestVisible;
    }

    set suggestVisible(value: boolean) {
        this._suggestVisible = value;
    }

    @Input() place: string;
    @ViewChild('phrase', {static: true}) phrase: ElementRef;
    searchBoxVisible: boolean = false;
    private _suggestVisible: boolean = false;
    suggestResult: ISearchResult;
    enterFired: Subject<any> = new Subject<any>();
    ngUnsubscribe: Subject<any> = new Subject<any>();
    loading: boolean = false;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private http: HttpClient,
        private router: Router,
        private searchSvc: SearchService,
        private elRef: ElementRef,
        private menuSvc:MenuService
    ) {
        super(dataSvc, seSvc);
    }

    ngAfterViewInit(): void {
        this.bindSuggest();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.enterFired.complete();
    }

    private bindSuggest(): void {
        const searchBox = this.phrase.nativeElement;
        fromEvent(searchBox, 'input')
            .pipe(
                map((e: any) => e.target.value),
                filter(text => text.length > 2),
                debounceTime(333),
                switchMap((s: string) => this.getSuggest(s)),
                takeUntil(this.enterFired),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res: ISearchResult) => {
                this.suggestResult = res;
                this.suggestVisible = true;
            });

        fromEvent(searchBox, 'focus')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.suggestVisible = true;
            });
    }

    searchKeyUp(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
            this.suggestVisible = false;
        }
    }

    search(phrase: string): void {
        if (phrase.length == 0) return;
        this.phrase.nativeElement.value = '';
        this.suggestVisible = false;
        this.router.navigateByUrl(`/vysledky-vyhledavani?q=${phrase}`)
    }

    searchKeyPress(evt: KeyboardEvent, phrase: string): void {
        this.searchSvc.fireSearchInputChanged(phrase);

        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            this.search(phrase);
            this.suggestVisible = false;
            this.enterFired.next();
            this.bindSuggest();
            return;
        }
    }

    onFormSubmit(): boolean {
        return false;
    }

    public get expression(): string {
        return this.phrase?.nativeElement?.value;
    }

    getSuggest(phrase: string): Observable<ISearchResult> {
        let request: ISearchRequest = {
            Expression: decodeURI(phrase),
            PageIndex: 0,
            PageSize: 7,
            OrderBy: 'rank'
        };

        this.loading = true;
        return this.http.get<ISearchResult>(`api/search?params=${ToQueryStringParam(request)}`)
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .pipe(map(x => {
                return {
                    Products: x.Products,
                    ProductCount: x.ProductCount,
                    Categories: x.Categories.slice(0, Math.min(x.Categories.length, 3)),
                    CategoryCount: x.CategoryCount,
                    Articles: x.Articles.slice(0, Math.min(x.Articles.length, 3)),
                    ArticleCount: x.ArticleCount
                };
            }));
    }

    public toggleSearch(): void {
        if (!this.searchBoxVisible) {
            $('.search').addClass("search--active");
            this.searchBoxVisible = true;

        } else {
            $('.search').removeClass("search--active");
            this.searchBoxVisible = false;
        }
        this.menuSvc.deactivateMenu();
    }

    @HostListener('document:click', ['$event'])
    public OnHostListener(ev: MouseEvent) {
        const $this = $(this.elRef.nativeElement);
        const $target = $(ev.target);

        if ($target.closest($this).length === 0) {
            this.suggestVisible = false;
        }
    }
}
