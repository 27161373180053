<div class="footer-contact">
    <div class="footer-contact__address" [innerHTML]="sen['footer-contact-address']"></div>

    <div class="footer-contact__list">
        <a href="mailto: {{sen['contact-mail']}}" class="footer-contact__item">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#email"/>
                </svg>
            </div>

            {{sen['footer-contact-e-mail']}}
        </a>

        <a href="tel: {{sen['footer-contact-phone1']}}" class="footer-contact__item">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone"/>
                </svg>
            </div>

            {{sen['footer-contact-phone1']}}
        </a>

        <a href="tel: {{sen['footer-contact-phone2']}}" class="footer-contact__item">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone"/>
                </svg>
            </div>

            {{sen['footer-contact-phone2']}}
        </a>
    </div>
</div>
