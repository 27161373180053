import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {GeneralDialogRef} from "../../general-dialog/common";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {IMergeCartDialogConfig} from "../commons/commons";
import {MergeCartType} from "../../cart/commons/commons";

@Component({
    selector: 'cmp-merge-cart-dialog',
    templateUrl: './merge-cart-dialog.component.html',
    styleUrls: ['./merge-cart-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MergeCartDialogComponent extends Translatable implements OnInit {

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        public dialogRef: GeneralDialogRef,
        public config: GeneralDialogConfig<IMergeCartDialogConfig>
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    public SelectCurrentCart(): void {
        this.dialogRef.close(MergeCartType.KeepCurrent);
    }

    public SelectPreviousCart(): void {
        this.dialogRef.close(MergeCartType.KeepPrevious);
    }

    public SelectMergeCart(): void {
        this.dialogRef.close(MergeCartType.Merge);
    }

}
