/**
 * Little shopping cart view available on every page.
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CartService} from "../cart/cart.service";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CartItemSelectorBrief} from "../cart/common";
import {finalize, take} from "rxjs/operators";
import {MessageBoxButtons} from "../general-dialog/common";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";

@Component({
    selector: 'cmp-cart-indicator',
    templateUrl: '../../tpl/cart-indicator.html',
    styleUrls: ['../../assets/styles/3-layout/header/cart-indicator.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CartIndicatorComponent extends Translatable {
    @Input() place: string;

    imagePathPrefix: string;
    currencySymbol: string;
    decimalPattern: string = '1.0-0';

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        public cartSvc: CartService,
        private dialogSvc: GeneralDialogService
    ) {
        super(dataSvc, seSvc);

        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.Basket}-${this.seSvc.settings.decimalSettings.Basket}`;
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.imagePathPrefix = this.seSvc.settings.newImageServerUrl;
    }

    ngOnInit(): void {
        this.cartSvc.getCartContentForToken();
    }

    removeFromCart(cartItemId: number) {
        let req: CartItemSelectorBrief = {
            id: cartItemId
        };
        this.cartSvc.removeFromCart(req);
    }

    public ItemCount(): number {
        return (this.cartSvc?.productList?.length + this.cartSvc?.coupons?.length);
    }

    public OnRemoveCartItemsClick(): void {
        this.dialogSvc.showMessageBox(
            this.s('cart-remove-item-dialog--title'),
            this.s('cart-remove-item-dialog--text'),
            [MessageBoxButtons.Yes(), MessageBoxButtons.No()]
        ).subscribe((res) => {

            if (res === MessageBoxButtons.Yes().value) {

                this.dataSvc.dataLoading = true;
                this.cartSvc.RemoveCartItems()
                    .pipe(finalize(() => {
                        this.dataSvc.dataLoading = false;
                    }))
                    .pipe(take(1))
                    .subscribe(() => {
                        this.dialogSvc.showMessageBox(this.s('cart-remove-item-result-dialog--title'), this.s('cart-remove-item-result-dialog--text'), [MessageBoxButtons.OK()]);
                    });

            }

        });
    }
}
