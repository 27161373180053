import {Injectable} from '@angular/core';
import {AsyncSubject, Observable} from "rxjs";
import {IFacebookLoginStatus} from "../interfaces/IFacebookLoginStatus";
import {IFacebookSettings} from "../interfaces/IFacebookSettings";
import {IFacebookUserInfo} from "../interfaces/IFacebookUserInfo";
import {FacebookLoginStatus} from "../interfaces/FacebookLoginStatus";
import {take} from "rxjs/operators";
import {IFacebookShareRequest} from "../interfaces/IFacebookShareRequest";
import {IFacebookShareResponse} from "../interfaces/IFacebookShareResponse";

declare let FB: any;

@Injectable({
    providedIn: 'root'
})
export class FacebookSdkService {
    private scriptLoaded: AsyncSubject<boolean> = new AsyncSubject();

    constructor() {
    }

    public Init(settings: IFacebookSettings): void {
        const $this = this;
        (<any>window).fbAsyncInit = function () {
            FB.init({
                appId: settings.ApplicationId,
                cookie: false,
                xfbml: true,
                version: 'v15.0'
            });

            FB.AppEvents.logPageView();

            $this.scriptLoaded.next(true);
            $this.scriptLoaded.complete();
        };

        const id = 'facebook-jssdk';
        var js: HTMLScriptElement = null;
        var fjs = document.getElementsByTagName('script')[0];
        if (document.getElementById(id)) {
            return;
        }
        js = <HTMLScriptElement>document.createElement('script');
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.appendChild(js);
    }

    public GetLoginStatus(): Observable<IFacebookLoginStatus> {
        const o: Observable<IFacebookLoginStatus> = new Observable<IFacebookLoginStatus>((o) => {

            this.scriptLoaded
                .pipe(take(1))
                .subscribe(() => {

                    FB.getLoginStatus((response: IFacebookLoginStatus) => {
                        o.next(response);
                        o.complete();
                    });

                });

        });

        return o;
    }

    public Login(): Observable<IFacebookLoginStatus> {
        const o: Observable<IFacebookLoginStatus> = new Observable<IFacebookLoginStatus>((o) => {
            this.scriptLoaded
                .pipe(take(1))
                .subscribe(() => {

                    FB.login((response: IFacebookLoginStatus) => {
                        o.next(response);
                        o.complete();
                    }, {scope: 'email', auth_type: 'reauthorize'}); // scope: 'email, user_location'

                });
        });

        return o;

    }

    public Logout(): Observable<void> {
        const o: Observable<void> = new Observable<void>((o) => {

            this.scriptLoaded
                .pipe(take(1))
                .subscribe(() => {

                    FB.logout((_response: any) => {
                        o.next();
                        o.complete();
                    });

                });

        });

        return o;
    }

    public GetCurrentUserInfo(): Observable<IFacebookUserInfo> {
        const o: Observable<IFacebookUserInfo> = new Observable<IFacebookUserInfo>((o) => {

            this.scriptLoaded
                .pipe(take(1))
                .subscribe(() => {

                    this.GetLoginStatus().subscribe((loginStatus) => {
                        if (loginStatus.status === FacebookLoginStatus.Connected) {

                            FB.api('/me?fields=picture,name', (response: any) => {
                                const res: IFacebookUserInfo = {
                                    Id: response.id,
                                    Name: response.name,
                                    ImageUrl: response.picture && response.picture.data && response.picture.data.url
                                };

                                o.next(res);
                                o.complete();
                            });

                        }
                    }, (err) => {
                        o.error(err);
                    });

                });

        });

        return o;
    }

    public Share(request: IFacebookShareRequest): Observable<IFacebookShareResponse> {
        const ob: Observable<any> = new Observable<any>(o => {
            FB.ui({
                method: 'share',
                href: request.Url,
                quote: request.Quote
            }, function (response: IFacebookShareResponse) {
                o.next(response);
                o.complete();
            });
        });

        return ob;
    }
}
