import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IAvailability, IAvailabilityDialogConfig} from "../commons/commons";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {GeneralDialogService} from "../../general-dialog/general-dialog.service";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {AvailabilityDialogComponent} from "../availability-dialog/availability-dialog.component";

@Component({
    selector: 'cmp-availability',
    templateUrl: './availability.component.html',
    styleUrls: ['../../../assets/styles/2-components/availability.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AvailabilityComponent extends Translatable implements OnInit {

    private _availability: IAvailability = null;

    @Input() set data(value: IAvailability) {
        this._availability = value;

        if (value) {
            this.inStockStoreCount = value?.Availabilities?.filter(x => x.Amount > 0)?.length || 0;
            this.centralAmount = value?.Availabilities?.find(x => x.Store.IsCentral)?.Amount || 0;
            this.totalAmount = 0;
            for (let i = 0; i < value?.Availabilities?.length; i++) {
                const item = value.Availabilities[i];
                this.totalAmount += item?.Amount || 0;
            }
        } else {
            this.centralAmount = null;
            this.totalAmount = null;
        }
    }

    get data(): IAvailability {
        return this._availability;
    }

    public centralAmount: number;
    public totalAmount: number;
    public inStockStoreCount: number

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private dialogSvc: GeneralDialogService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    public OnAvailabilityClick(): void {
        const data: IAvailabilityDialogConfig = {...this.data};

        const config: GeneralDialogConfig<IAvailabilityDialogConfig> = {
            data: data,
            cssClassModifier: null,
            isCloseAble: true,
            title: null
        };

        const dialogRef = this.dialogSvc.open(AvailabilityDialogComponent, config);
        dialogRef.afterClosed
            .subscribe(() => {
            });
    }

}
