<div class="menu">
    <div class="menu-toggle" (click)="toggleMenu()">
        <div class="menu-toggle__icon">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#menu"/>
                </svg>
            </div>
        </div>

        {{sen['app-show-catalog']}}

        <div class="menu-toggle__arrow">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-down"/>
                </svg>
            </div>
        </div>
    </div>

    <nav class="menu__in">
        <ul class="menu__list">
            <li *ngFor="let category of categories" class="menu__item"   [ngClass]="{'menu__item--active': isActiveCat(category.id)}" >
                <a [routerLink]="['/c/' + category.id + '/' + category.seoUrl]" class="menu__link" (click)="deactivateMenu(); categoryClick(category.id)">
                    {{category.displayName}}
                </a>

                <div *ngIf="category.hasSubCats" class="menu__toggle menu__toggle--responsive" (click)="categoryClick(category.id)">
                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#dropdown-right"/>
                        </svg>
                    </div>
                </div>

                <div #dropdownMenu *ngIf="category.hasSubCats" class="menu__toggle menu__toggle--desktop" (click)="categoryClick(category.id); onCategoryClick('/c/' + category.id + '/' + category.seoUrl)">
                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#dropdown-right"/>
                        </svg>
                    </div>
                </div>

                <ng-container *ngIf="category.subCats?.length > 0 && category.isActive" class="submenu">
                    <div class="submenu__in">
                        <cmp-sub-menu [categories]="category.subCats" [nestingLvl]="1"></cmp-sub-menu>

                    </div>
                </ng-container>

            </li>
        </ul>
    </nav>
</div>
