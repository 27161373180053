<div [formGroup]="userForm" class="modal-login" (keyup)="onKey($event)">
    <div class="modal-login__form">
        <div class="modal-login__item">
            <label class="form-label" for="login">
                {{sen['login-email']}} <span>*</span>
            </label>

            <div class="modal-login__value">
                <input formControlName="userName" class="form-input" id="login" type="text" />

                <div class="validation" *ngIf="userForm.controls['userName'].invalid && userForm.controls['userName'].dirty">
                    <cmp-val-msg [msg]="sen['login-invalid-email']"></cmp-val-msg>
                </div>
            </div>
        </div>

        <div class="modal-login__item">
            <label class="form-label" for="password">
                {{sen['login-password']}} <span>*</span>
            </label>

            <div class="modal-login__value">
                <cmp-input-password formControlName="password" id="password"></cmp-input-password>

                <div class="validation">
                    <cmp-val-msg *ngIf="userForm.controls['password'].invalid && userForm.controls['password'].dirty" [msg]="sen['login-invalid-password']"></cmp-val-msg>
                    <cmp-val-msg *ngIf="isError && !activated" [msg]="sen['login-not-activated']"></cmp-val-msg>
                    <cmp-val-msg *ngIf="isError && !confirmed" [msg]="s('login-not-confirmed')"></cmp-val-msg>
                    <cmp-val-msg *ngIf="isError && activated" [msg]="sen['login-error']"></cmp-val-msg>
                    <cmp-val-msg *ngIf="captchaFailed" [msg]="sen['app-captcha-failed']"></cmp-val-msg>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-login__actions">
        <button (click)="executeCaptcha()" [disabled]="!userForm.valid" [class.disabled]="!userForm.valid" class="btn btn--medium btn--primary">
            {{sen['app-login']}}
        </button>

        <a [routerLink]="['/zapomenute-heslo']" class="modal-login__link" (click)="closeDialog()">
            {{sen['login-forgot-password']}}
        </a>
    </div>

    <div class="modal-login__bottom">
        <a [routerLink]="['/registrace']" (click)="closeDialog()" class="btn btn--medium btn--secondary">
            <!-- TODO Preklad
            {{sen['login-register']}}
            -->
            Nemám účet, chci se zaregistrovat
        </a>
    </div>
</div>
