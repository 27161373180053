import {Component, Input, ViewEncapsulation} from "@angular/core";
import {CategoryItemSelectorBrief, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MenuService} from "../../services/menu.service";
import {document} from "ngx-bootstrap/utils";

@Component({
    selector: 'cmp-sub-menu',
    templateUrl: '../../tpl/sub-menu.html',
    styleUrls: ['../../assets/styles/3-layout/menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SubMenuComponent extends Translatable {

    private ngUnsubscribe: Subject<any> = new Subject<any>();

    @Input() categories: Array<CategoryItemSelectorBrief> = [];
    @Input() nestingLvl: number = 2;

    get submenu(): string {
        if(this.nestingLvl > 1){
            if(this.nestingLvl >2){
                return 'submenu-recursive';
            }
            return 'submenu' + this.nestingLvl;
        }else{
            return 'submenu';
        }
    }

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private http: HttpClient,
        private menuSvc: MenuService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onCategoryClick(href: string) {
        const category = document.querySelector(`[href="${href}"]`);
        if (category) {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;

            // Recalculate scroll position after layout change
            window.requestAnimationFrame(() => {
                const categoryTop = category.getBoundingClientRect().top;
                const targetScrollTop = scrollTop + categoryTop - (windowHeight / 2);

                window.scrollTo({
                    top: targetScrollTop,
                    behavior: 'smooth'
                });
            });
        }
    }

    categoryClick(catId: number){
        this.getCategorySubcats(catId);
        this.updateIsActiveById(catId);
    }
    isActiveCat(idToFind: number): boolean | undefined {
        return this.menuSvc.isActiveCat(idToFind);
    }
    updateIsActiveById(idToUpdate: number): void {
        this.menuSvc.updateIsActiveById(idToUpdate);
    }

    findNodeById(idToFind: number): CategoryItemSelectorBrief {
        return this.menuSvc.findNodeById(idToFind);
    }

    getCategorySubcats(catId: number): void {
        let cat = this.findNodeById(catId);
        if(cat.subCats.every(x=>x.subCats.length == 0)){
            this.http.get<CategoryItemSelectorBrief[]>(`api/category/getCategoryTwoLevelSubTree/${catId}`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    cat.subCats = res;
                });
        }
    }

    deactivateMenu():void{
        this.menuSvc.deactivate.emit();
    }
}
