import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['../../../assets/styles/2-components/input-password.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPasswordComponent),
            multi: true
        }
    ]
})
export class InputPasswordComponent extends Translatable implements ControlValueAccessor {

    @Input() id: string;
    @Input() preventPwdAutofill: boolean = false;

    public passwordVisibility: boolean = false;
    public form: FormGroup;
    private writeValueFunc: (_: any) => void = null;

    constructor(dataSvc: DataService,
                seSvc: SettingsService,
                private fb: FormBuilder) {

        super(dataSvc, seSvc)
    }

    ngOnInit(): void {
        this.CreateForm();
    }

    registerOnChange(fn: any): void {
        this.writeValueFunc = fn
    }

    registerOnTouched(fn: any): void {
        fn
    }

    writeValue(obj: any): void {
        obj
    }

    private CreateForm() {
        this.form = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(4)]]
        });

        this.form.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                this.writeValueFunc(res.password);
            });
    }

    public ShowHidePassword(input: HTMLInputElement){
        input.type = input.type == 'text' ? 'password' : 'text';
        this.passwordVisibility = input.type == 'text' ? true : false;
    }

}
