import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {GeneralDialogService} from "../../general-dialog/general-dialog.service";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {CookiesConsentComponent} from "../cookies-consent.component";
import {CookiesConsentService} from "../cookies-consent.service";
import {CookiesConsent, CookiesConsentTexts} from "../common";
import {take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'cmp-cookies-panel',
    templateUrl: './cookies-panel.component.html',
    styleUrls: ['../../../assets/styles/2-components/cookies-panel.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CookiesPanelComponent extends Translatable implements OnInit {

    get showWarning(): boolean {
        return this.ccSvc.showWarning;
    }

    set showWarning(value: boolean) {
        this.ccSvc.showWarning = value;
    }

    public isCookiesModalVisible: boolean = false;
    public cookiesConsentTexts: CookiesConsentTexts;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private dialogSvc: GeneralDialogService,
        private ccSvc: CookiesConsentService,
        private http: HttpClient
    ) {
        super(dataSvc, seSvc);
        this.getTexts();
    }

    ngOnInit(): void {
    }

    public OnOkButtonClick(): void {
        this.EnableAll();
    }

    public OnCustomButtonClick(): void {
        this.OpenCookieDialog();
    }

    private EnableAll(): void {
        const consent: CookiesConsent = new CookiesConsent();
        consent.date = new Date();
        consent.gtmConsent.setAllGranted();
        this.ccSvc.setConsent(consent);
        this.ccSvc.showWarning = false;
    }

    enableRequired() {
        const consent: CookiesConsent = new CookiesConsent();
        consent.date = new Date();
        consent.gtmConsent.setDefault();
        this.ccSvc.setConsent(consent);
        this.ccSvc.showWarning = false;
    }

    public OpenCookieDialog(): void {
        const config: GeneralDialogConfig<void> = {
            data: null,
            cssClassModifier: 'cookies',
            isCloseAble: false,
            title: null
        };

        this.isCookiesModalVisible = true;
        const dialogRef = this.dialogSvc.open(CookiesConsentComponent, config);
        dialogRef.afterClosed.subscribe(() => {
            this.isCookiesModalVisible = false;
        });
    }

    private getTexts() {
        this.http.get<CookiesConsentTexts>('api/cookies-consent/get-cookie-consent-texts')
            .pipe(take(1))
            .subscribe((res) => {
                this.cookiesConsentTexts = res;
            });
    }

}
