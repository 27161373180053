import {Injectable} from "@angular/core";
import {ActivatedRoute, NavigationCancel, NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {filter, map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class PageViewService {

    onPageView: BehaviorSubject<PageViewEvent> = new BehaviorSubject<PageViewEvent>(null);
    private lastUrl: string;
    private lastRouteData: any;

    constructor(private router: Router, private activRoute: ActivatedRoute) {
        this.router.events
            .pipe(filter((evt) => {
                    if (evt instanceof NavigationEnd || evt instanceof NavigationCancel) {
                        return true;
                    }
                    return false;
                }),
                map(() => {
                    let route = this.activRoute;

                    let child = route;
                    while (child) {
                        if (child.firstChild) {
                            child = child.firstChild;
                            route = child;
                        } else {
                            child = null;
                        }
                    }


                    const pathFromRoot = route.snapshot.pathFromRoot;
                    let url = pathFromRoot.map(i => i.url).map((urls) => {
                        if(!urls.length){
                            return '';
                        }

                        if(urls.length===1){
                            return urls[0].path;
                        }

                        return urls.map(url=>url.path).join('/');
                    }).join('/');
                    if (url === '//') {
                        url = '/';
                    }

                    return {route, url};
                })
            )
            .subscribe(i => {
                this.lastUrl = i?.url;
                this.lastRouteData = i?.route?.snapshot?.data;
                this.fireOnPageView();
            });
    }

    fireOnPageView() {
        const data = {url: this.lastUrl, routeData: this.lastRouteData};
        this.onPageView.next(data);
    }
}

export interface PageViewEvent {
    url: string;
    routeData: any;
}