import {AfterViewInit, Component, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {
    Translatable,
    Culture,
    CategoryItemSelector,
    ArticleBaseWithChildrenSelector,
    DomainSelectorExtended,
    ArticleBriefSelector,
    UserTokenLoginSelector,
    UserTokenLoginRequest, GtmPageType
} from "../../interfaces/general";
import {LoginComponent} from "../login/login.component";
import {SettingsService} from "../../services/settings.service";
import {AdminLogin, DigestService} from "../../services/digest.service";
import {NavigationStart, Router} from "@angular/router";
import {loadFromLocalStorage, loadFromSession} from "../../helpers/cookie.helper";
import {SharedAppSettings} from "../../shared-settings/shared-settings";
import {WishListService} from "../../services/wish-list-service";
import {CompareProductsService} from "../../services/compare-products.service";
import {filter, finalize, takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {CartService} from "../cart/cart.service";
import {LuigiSuggestComponent} from "../luigi-suggest/luigi-suggest.component";
import {ArticleService} from "../articles/article.service";
import {MenuService} from "../../services/menu.service";
import {SwUpdate} from "@angular/service-worker";
import {IWishList} from "../account/common";
import {CookiesConsentService} from "../cookies-consent/cookies-consent.service";
import {ScrollUtils} from "../../helpers/ScrollUtils";
import {HttpClient} from "@angular/common/http";
import {FlexCaptchaService} from "../../services/flex-captcha.service";
import {SklikService} from "../sklik/services/sklik.service";

declare let $: any;
declare let moment: any;

@Component({
    selector: 'app',
    templateUrl: '../../tpl/app.html',
    styleUrls: ['../../assets/styles/layout.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent extends Translatable implements AfterViewInit {

    @ViewChild(LoginComponent, {static: true}) loginComponent: LoginComponent;
    @ViewChild(LuigiSuggestComponent) suggest: LuigiSuggestComponent;

    categories: CategoryItemSelector[];
    cultureOptions: Culture[];
    selectedCulture: Culture;

    domains: DomainSelectorExtended[] = [];
    activeUrlInfo: DomainSelectorExtended;

    tim: any;
    responseMenuDisplay: boolean = false;
    menuArticleCode: string;
    footerArticleCode: string;
    wishLists: IWishList[];
    cultureExpanded: boolean = false;
    currencyExpanded: boolean = false;
    compoundExpanded: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();
    showUpperBar: boolean = true;
    selectedCurrencyId: number;
    bindCurrencyAndCultureToDomain: boolean;

    footerRootArticle: ArticleBaseWithChildrenSelector;
    copy: string;

    get articles(): Array<ArticleBriefSelector> {
        if (!this.footerRootArticle || !this.footerRootArticle.childArticles || !this.footerRootArticle.childArticles.length) {
            return null;
        }
        return (<Array<ArticleBriefSelector>>this.footerRootArticle.childArticles);
    }

    constructor(public dataSvc: DataService,
                public seSvc: SettingsService,
                public digestSvc: DigestService,
                private router: Router,
                private fcSvc: FlexCaptchaService,
                private saSvc: SharedAppSettings,
                private wishListSvc: WishListService,
                public compareSvc: CompareProductsService,
                public cartSvc: CartService,
                private articleSvc: ArticleService,
                private http: HttpClient,
                private menuSvc: MenuService,
                private swUpdate: SwUpdate,
                public sklikSvc: SklikService,
                public ccSvc: CookiesConsentService) {
        super(dataSvc, seSvc);
        this.bindCurrencyAndCultureToDomain = this.seSvc.bindCurrencyAndCultureToDomain;
        this.seSvc.domains.forEach(elem => {
            const item: DomainSelectorExtended = {
                currencyId: elem.currencyId,
                cultureId: elem.cultureId,
                id: elem.id,
                url: elem.url,
                cultureKey: this.seSvc.settings.cultures.filter(f => f.cultureId === elem.cultureId)[0].translationKey,
                currencyKey: this.seSvc.settings.currencies[elem.currencyId]
            };
            this.domains.push(item);
            if (item.cultureId === this.seSvc.activeUrlInfo.cultureId) {
                this.activeUrlInfo = item;
            }
        });

        this.wishLists = wishListSvc.wishLists;
        this.wishListSvc.onWishListsChanged.subscribe((res: IWishList[]) => {
            this.wishLists = res;
        });

        this.menuArticleCode = this.saSvc.Defaults.MenuArticleCode;
        this.footerArticleCode = this.saSvc.Defaults.FooterArticleCode;


        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;


        this.selectedCurrencyId = this.seSvc.currencyId;

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(res => {
                /**
                 * Ugly hack for not letting unauthorized users in on published DEV servers
                 */
                if (this.seSvc.settings.preAuth) {

                    if (res['url'].toLowerCase() != '/pa') {

                        let pa = this.seSvc.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');

                        let mt = moment();
                        let mtValidTo = pa ? moment(pa.validTo) : 0;

                        if (!pa || mt > mtValidTo) {
                            this.router.navigateByUrl('/pa').then(() => {
                            })
                        }
                    }
                }
                if (/registrace/.test(res['url']) && this.userLoggedIn) {
                    this.router.navigateByUrl('/').then(() => {
                    });
                }
                /**
                 * default title and description
                 */
                this.seSvc.settings.routesWithDefaultSeo.forEach(elem => {
                    if (elem.test(res['url'].toLowerCase())) {
                        this.setDefaultSeo();
                    }
                })
            });

        /* this.setCopy();*/
    }

    /*private setCopy(): void {
        let year = (new Date()).getFullYear().toString();
        this.copy = this.sen['app-copyright']
            .replace('yyyy', year)
            .replace('#ND_LINK#', `<a href="https://www.netdirect.cz" target="_blank" rel="noopener">NetDirect</a>`);
    }*/

    getCopy() {

    }

    readLoginToken(): void {
        let token: string;
        let params = window.location.search.substr(1).split('&');
        let tokenParam = params.find(q => q.startsWith('token='));
        token = tokenParam ? tokenParam.split('=')[1] : null;

        if (token) {
            let pending: UserTokenLoginRequest = {
                token: token
            };
            this.http.post<UserTokenLoginSelector>('api/user/token', pending)
                .subscribe((resToken) => {
                    if (resToken) {
                        this.dataSvc.dataLoading = true;
                        this.fcSvc.executeCaptcha('login', 'login-dialog.component.onCaptchaExecuted')
                            .pipe(finalize(() => {
                                this.dataSvc.dataLoading = false;
                            }))
                            .subscribe(res => {
                                if (res.success) {
                                    this.digestSvc.login(new AdminLogin(
                                        resToken.login,
                                        resToken.psw,
                                        res.secret
                                    ));
                                }
                            })


                    }
                });
        }
    }

    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCultureAndReload(true, id, this.seSvc.currencyId);
    }


    setCompoundCultureCultureAndCurrency(cultureId: number) {
        this.seSvc.setCultureAndReload(true, cultureId, this.seSvc.currencyId);
    }

    ngOnInit(): void {
        this.getArticles();

        if (this.swUpdate.isEnabled) {

            this.swUpdate.available.subscribe(() => {

                if (confirm("Je k dispozici nová verze aplikace, chcete ji nahrát?")) {
                    window.location.reload();
                }
            });
        }
    }

    getArticles(): void {
        this.articleSvc.getFooterArticles()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (res) => {
                    this.footerRootArticle = res;
                }
            );
    }

    ngAfterViewInit(): void {
        this.setDefaultSeo();
        this.readLoginToken();
    }

    private setDefaultSeo(): void {
        let can = this.dataSvc.domain;
        this.dataSvc.setPageHead(this.seSvc.settings.shopSeo.ShopDescription, can);
        this.dataSvc.setTitle(this.seSvc.settings.shopSeo.ShopTitle, GtmPageType.homepage);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        clearTimeout(this.tim);
    }

    toggleCulture(): void {
        this.cultureExpanded = !this.cultureExpanded;
        if (this.cultureExpanded) {
            $('.js-culture').slideDown(250)
        } else {
            $('.js-culture').slideUp(250);
        }
    }

    toggleCurrency(): void {
        this.currencyExpanded = !this.currencyExpanded;
        if (this.currencyExpanded) {
            $('.js-currency').slideDown(250)
        } else {
            $('.js-currency').slideUp(250);
        }
    }

    toggleCompound(): void {
        this.compoundExpanded = !this.compoundExpanded;
        if (this.compoundExpanded) {
            $('.js-compound').slideDown(250)
        } else {
            $('.js-compound').slideUp(250);
        }
    }

    onNoArticles(): void {
        this.showUpperBar = false;
    }


    toggleMenu(event: MouseEvent) {
        this.menuSvc.toggleMenu.emit(event);
    }

    scrollUp() {
        ScrollUtils.ScrollUp();
    }

    initNotifyStripeHeightVar() {
        setTimeout(() => {
            let el = document.querySelector("html");
            let notifyStripeHeight = document.querySelector('.notify-stripe')?.clientHeight;
            el.style.setProperty('--notifyStripeHeight', (notifyStripeHeight ?? '0') + 'px');
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(_event: any) {
        this.initNotifyStripeHeightVar();
    }
}
