<div [class]="submenu">
    <div [class]="submenu + '__in'">
        <ng-container *ngFor="let category of categories">
            <div [class]="submenu + '__item'" [ngClass]="isActiveCat(category.id) ? submenu + '__item--active' : ''">
                <div *ngIf="category.hasSubCats" [class]="submenu + '__toggle' + ' ' + submenu + '__toggle--desktop'" (click)="categoryClick(category.id); onCategoryClick('/c/' + category.id + '/' + category.seoUrl)">
                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#dropdown-right"/>
                        </svg>
                    </div>
                </div>
                <div *ngIf="category.hasSubCats" [class]="submenu + '__toggle' + ' ' + submenu + '__toggle--responsive'" (click)="categoryClick(category.id)">
                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#dropdown-right"/>
                        </svg>
                    </div>
                </div>
                <a [routerLink]="['/c/' + category.id + '/' + category.seoUrl]" (click)="deactivateMenu();categoryClick(category.id)" [class]="submenu + '__link'">
                    {{ category.displayName}}
                </a>

                <ng-container *ngIf="category.hasSubCats">
                        <cmp-sub-menu [categories]="category.subCats" [nestingLvl]="nestingLvl + 1"></cmp-sub-menu>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

