import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {ICreateConversionRequest, IVisitCategoryRequest, IVisitProductRequest} from "../commons/commons";
import {SettingsService} from "../../../services/settings.service";
import {CookiesConsentService} from "../../cookies-consent/cookies-consent.service";

@Injectable()
export class SklikService {
    private rend: Renderer2;

    constructor(
        private seSvc: SettingsService,
        rendererFactory: RendererFactory2,
        public ccSvc: CookiesConsentService,
    ) {
        this.rend = rendererFactory.createRenderer(null, null);

    }

    public createConversion(obj: ICreateConversionRequest): boolean {
        if (this.ccSvc.isSklikConversion()) {
            const wholeVat = Math.round(obj.priceWithVat);

            let bodyEl = document.querySelector('body');

            let scriptElUrl: HTMLScriptElement = <HTMLScriptElement>this.rend.createElement('script');
            scriptElUrl.addEventListener("load", () => {

                let scriptEl: HTMLScriptElement = <HTMLScriptElement>this.rend.createElement('script');
                this.rend.appendChild(bodyEl, scriptEl);
                scriptEl.innerHTML = `
                                var conversionConf = {
                                    id: ${this.seSvc.seznamSettings.sklikConversionId}, /* identifikátor konverze Sklik*/
                                    value: ${wholeVat}, /* hodnota objednávky v Kč*/
                                    consent: 1 /* souhlas od návštěvníka na odeslání konverzního hitu, povolené hodnoty: 0 (není souhlas) nebo 1 (je souhlas) */
                                };
                                
                                if (window.rc && window.rc.conversionHit) {
                                    window.rc.conversionHit(conversionConf);
                                    console.log('CreateRetarget::retargetingHit');
                                }
                            `;
            });

            this.rend.appendChild(bodyEl, scriptElUrl);
            scriptElUrl.src = `https://c.seznam.cz/js/rc.js`;

            console.log('createConversion', obj);
            return true;
        }
        return false;

    }

    public visitCategory(obj: IVisitCategoryRequest): boolean {
        if (this.ccSvc.isSklikRetargetEnabled()) {
            const js = `
                            var retargetingConfCat = {
                                rtgId: ${this.seSvc.seznamSettings.sklikRetargetingId}, /* identifikátor retargeting */
                                category: ${obj.categoryText ? "'" + obj.categoryText + "'" : 'null'}, /* kategorie eshopu */
                                pageType: "category", /* typ stránky - offerdetail, category */
                                consent: 1, /* souhlas od návštěvníka na odeslání retargetingového hitu, povolené hodnoty: 0 (není souhlas) nebo 1 (je souhlas) */
                            };
                            
                            // Ujistěte se, že metoda existuje, předtím než ji zavoláte
                            if (window.rc && window.rc.retargetingHit) {
                                window.rc.retargetingHit(retargetingConfCat);
                                console.log('CreateRetarget::retargetingHit');
                            } else {
                                console.log('there is no window.rc.retargetingHit');
                            }
                        `;
            this.createRecord(js);

            console.log('VisitCategory', obj);
            return true;
        }
        return false;
    }

    public visitProduct(obj: IVisitProductRequest): boolean {
        if (this.ccSvc.isSklikRetargetEnabled()){
            const js = `
                        var retargetingConfProd = {
                            rtgId: ${this.seSvc.seznamSettings.sklikRetargetingId}, /* identifikátor retargeting */
                            itemId: '${obj.itemId}', /* identifikátor nabídky */                 
                            pageType: 'offerdetail', 
                            consent: 1, /* souhlas od návštěvníka na odeslání retargetingového hitu, povolené hodnoty: 0 (není souhlas) nebo 1 (je souhlas) */
                        };
                        
                        // Ujistěte se, že metoda existuje, předtím než ji zavoláte
                        if (window.rc && window.rc.retargetingHit) {
                            window.rc.retargetingHit(retargetingConfProd);
                            console.log('CreateRetarget::retargetingHit');
                        } else {
                            console.log('there is no window.rc.retargetingHit');
                        }
                    `;

            this.createRecord(js);

            console.log('VisitProduct', obj);
            return true;
        }
        return false;
    }

    private createRecord(js: string): void {
        let bodyEl = document.querySelector('body');

        const scriptElASelector = 'sclick-retargeting-a';
        const scriptElBSelector = 'sclick-retargeting-b';

        let scriptElA: HTMLScriptElement = <HTMLScriptElement>document.querySelector(`script[id="${scriptElASelector}"]`);
        if (scriptElA) {
            this.rend.removeChild(bodyEl, scriptElA);
        }

        let scriptElB: HTMLScriptElement = <HTMLScriptElement>document.querySelector(`script[id="${scriptElBSelector}"]`);
        if (scriptElB) {
            this.rend.removeChild(bodyEl, scriptElB);
        }

        scriptElB = this.rend.createElement('script');
        scriptElB.addEventListener("load", () => {
            scriptElA = this.rend.createElement('script');
            this.rend.appendChild(bodyEl, scriptElA);
            scriptElA.type = 'text/javascript';
            scriptElA.innerHTML = js;

            scriptElA.id = scriptElASelector;
        });

        this.rend.appendChild(bodyEl, scriptElB);
        scriptElB.src = 'https://c.seznam.cz/js/rc.js';
        scriptElB.type = 'text/javascript';
        scriptElB.id = scriptElBSelector;

    }
}
