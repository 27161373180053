<ng-container *ngIf="totalAmount > 0; else elseNoAmount">
    <ng-container *ngIf="data.ProductionFinished === false; else elseDoprodej">
        <div class="availability availability--green">
            <div (click)="OnAvailabilityClick()">
                {{sen['availability--in-stock']}}
                <cmp-availability-amount [amount]="totalAmount"></cmp-availability-amount>
                <span [innerHTML]="data?.UnitShortcut"></span>
            </div>

            <div class="availability-tooltip">
                <div class="availability-tooltip__icon">
                    i
                </div>

                <div class="availability-tooltip__in">
                    {{sen['availability--next-orders']}} {{data.OrderTimeDays}} {{sen['availability--delivery-to--days']}}
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #elseDoprodej>
        <!--Doprodej-->
        <div class="availability availability--brown" (click)="OnAvailabilityClick()">
            <div>
                {{sen['availability--clearance-sale']}}
                <cmp-availability-amount [round]="false" [amount]="totalAmount"></cmp-availability-amount>
                <span [innerHTML]="data?.UnitShortcut"></span>
            </div>
        </div>
    </ng-template>
</ng-container>

<ng-template #elseNoAmount>
    <ng-container *ngIf="data.PurchasingBlocked === false; else elseUkonceno">
        <!--Dodání do 14 dnů-->
        <div class="availability availability--orange" (click)="OnAvailabilityClick()">
            {{sen['availability--delivery-to']}} {{data.OrderTimeDays}} {{sen['availability--delivery-to--days']}}
        </div>
    </ng-container>

    <ng-template #elseUkonceno>
        <!--Prodej ukoncen-->
        <div class="availability availability--red" (click)="OnAvailabilityClick()">
            {{sen['availability--sale-ended']}}
        </div>
    </ng-template>
</ng-template>