<div class="product-box-mini product-box-vk-{{item.variantKey}}" [ngClass]="{'product-box-mini--not-available': item.accessLevel !== 2}" *ngIf="item">
    <a [routerLink]="[item?.urlToDisplay]" class="product-box-mini__image" (click)="onClickDetail()" [id]="item?.variantKey">
        <picture>
            <source [srcset]="(imageServerUrl + '/catalog/100/100/' + item?.imagePath) | webp" type="image/webp"/>
            <img [src]="imageServerUrl + '/catalog/100/100/' + item?.imagePath" [alt]="item?.imageTitle?item?.imageTitle:item?.name" [title]="item?.imageTitle?item?.imageTitle:''"/>
        </picture>
    </a>

    <div class="product-box-mini__content">
        <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()" class="product-box-mini__title">
            {{item?.name}}
        </a>

        <div class="product-box-mini__prices">
            <div class="prices">
                <ng-container *ngIf="isUserB2B">
                    <div class="prices__item prices__item--main">
                        <cmp-price [price]="item.priceWithoutVAT"></cmp-price>
                        <span>{{sen['without-vat']}}</span>
                    </div>

                    <div class="prices__item prices__item--base">
                        <cmp-price [price]="item.priceWithVAT"></cmp-price>
                        <span>{{sen['with-vat']}}</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isUserB2B">
                    <div class="prices__item prices__item--main">
                        <cmp-price [price]="item.priceWithVAT"></cmp-price>
                        <span>{{sen['with-vat']}}</span>
                    </div>

                    <div class="prices__item prices__item--base">
                        <cmp-price [price]="item.priceWithoutVAT"></cmp-price>
                        <span>{{sen['without-vat']}}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="product-box-mini__buy" *ngIf="displayShoppingSection">
            <button [disabled]="dataSvc.dataLoading  || !idSvc.IsAddToCartAndOrderAllowed"
                    class="product-box-mini__btn btn btn--small btn--conversion"
                    (click)="buy(item.variantId, item.minQtyOrder)" data-action="buy"
            >
                <div class="icon icon--w16">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cart"/>
                    </svg>
                </div>

                {{sen['cart-buy']}}
            </button>
        </div>
    </div>
</div>
