import {Component, Input, OnInit} from '@angular/core';
import {IAvailability} from "../commons/commons";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

@Component({
    selector: 'cmp-store-availability',
    templateUrl: './store-availability.component.html',
    styleUrls: ['./store-availability.component.scss']
})
export class StoreAvailabilityComponent extends Translatable implements OnInit {

    private _availability: IAvailability = null;

    @Input() set data(value: IAvailability) {
        this._availability = value;
    }

    get data(): IAvailability {
        return this._availability;
    }

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

}
