<div class="cart-indicator" *ngIf="place == 'menu'">
    cart
</div>

<div class="cart-indicator" *ngIf="place != 'menu'">
    <a [routerLink]="['/kosik/obsah']" class="cart-indicator__link">
        <div class="cart-indicator__count">
            <ng-container *ngIf="!ItemCount()">
                0
            </ng-container>

            <ng-container *ngIf="ItemCount()">
                {{ItemCount() | number:'1.0-0'}}
            </ng-container>
        </div>

        <div class="cart-indicator__image">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cart" />
                </svg>
            </div>
        </div>

        <div class="cart-indicator__content">
            <ng-container *ngIf="!ItemCount()">
                0
            </ng-container>

            <ng-container *ngIf="ItemCount()">
                {{cartSvc.cart?.priceWithoutPaymentAndDeliveryWithVat | currency:currencySymbol:'symbol-narrow':decimalPattern}}
            </ng-container>
        </div>
    </a>

    <div *ngIf="ItemCount()" class="mini-cart">
        <div class="mini-cart__inner">
            <div class="mini-cart__head">
                <div class="mini-cart__col mini-cart__col--product">
                    {{sen['mini-cart--product']}}
                </div>

                <div class="mini-cart__col mini-cart__col--amount">
                    {{sen['mini-cart--amount']}}
                </div>

<!--                <div class="mini-cart__col mini-cart__col&#45;&#45;price">-->
<!--                    {{sen['mini-cart&#45;&#45;price']}}-->
<!--                </div>-->

                <div class="mini-cart__col mini-cart__col--remove">
                </div>
            </div>

            <div class="mini-cart__body">
                <div *ngFor="let item of cartSvc?.productList" class="mini-cart__item">
                    <a [routerLink]="['/v/' + item.productDetail.id + '/' +  item.productDetail.seoUrl]" class="mini-cart__image">
                        <picture>
                            <source [srcset]="(imagePathPrefix + '/cart_indicator/60/60/' + item.productDetail.imagePath) | webp" type="image/webp" />
                            <img [src]="imagePathPrefix + '/cart_indicator/60/60/' + item.productDetail.imagePath" [alt]="item.productDetail.name">
                        </picture>
                    </a>

                    <a [routerLink]="['/v/' + item.productDetail.id + '/' +  item.productDetail.seoUrl]" class="mini-cart__title">
                        {{item.productDetail.name}}
                    </a>

                    <div class="mini-cart__amount" [innerHTML]="(item.count| number:decimalPattern) + ' ' + item.productDetail?.unit.shortCut">
                    </div>

<!--                    <div class="mini-cart__price">-->
<!--                        <div class="prices">-->
<!--                            <div class="prices__item prices&#45;&#45;smaller prices__item&#45;&#45;main">-->
<!--                               <cmp-price [price]="item.count * item.productDetail.priceWithVAT"></cmp-price> {{sen['with-vat']}}-->
<!--                            </div>-->

<!--                            <div class="prices__item prices__item&#45;&#45;base">-->
<!--                                <cmp-price [price]="item.count * item.productDetail.priceWithoutVAT"></cmp-price> {{sen['without-vat']}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="mini-cart__remove">
                        <div class="icon" (click)="removeFromCart(item.id);$event.stopPropagation()">
                            <svg>
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div *ngFor="let item of cartSvc?.coupons" class="mini-cart__item mini-cart__item--coupon">
                    <a class="mini-cart__image">
                        %
                    </a>

                    <div class="mini-cart__title">
                        {{item.couponDetail.name}} '{{item.couponDetail.code}}'
                    </div>

                    <div class="mini-cart__amount">
                        1
                    </div>

<!--                    <div class="mini-cart__price">-->
<!--                        <div class="prices">-->
<!--                            <div class="prices__item prices__item&#45;&#45;main">-->
<!--                                <cmp-price [price]="-item.couponDetail.couponImpactOnOrderPrice"></cmp-price> {{sen['with-vat']}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="mini-cart__remove">
                        <div class="icon" (click)="removeFromCart(item.id); $event.stopPropagation()">
                            <svg>
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mini-cart__actions">
                <button class="btn btn--secondary btn--medium" (click)="OnRemoveCartItemsClick()">
                    {{s('cart--remove-items')}}
                </button>

                <a [routerLink]="['/kosik/obsah']" class="btn btn--conversion btn--medium">
                    {{sen['cart-indicator-cart']}}

                    <div class="icon icon--w10">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-right" />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
