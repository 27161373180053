<div class="input-password" [formGroup]="form">
    <input #password formControlName="password" class="form-input" [id]="id" type="password" [readOnly]="preventPwdAutofill" (focus)="preventPwdAutofill=false"/>

    <i (click)="ShowHidePassword(password)" class="input-password__icon">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#eye-show" *ngIf="!passwordVisibility" />
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#eye-hide" *ngIf="passwordVisibility" />
            </svg>
        </div>
    </i>
</div>
