<div class="nav-links">
	<!--a [routerLink]="['/']" title="{{sen['shop-name']}}" class="logo">
		<img [src]="seSvc.settings.newImageServerUrl + '/logo/157/30' + seSvc.brand.logoFirst" alt="{{sen['shop-name']}}" class="logo__image"/>
	</a-->

	<a *ngFor="let article of articles" [routerLink]="[article.seoUrl]" class="nav-links__item" (click)="closeMenu()">
		{{article.name}}
	</a>

	<a [routerLink]="['/prodejny']" class="nav-links__item">
		{{s('stores')}}
	</a>

	<a [routerLink]="['/porovnat']" class="nav-links__item">
		{{s('product-compare')}}
	</a>
</div>