import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {
    DeliveryPayment,
    DeliveryPaymentRelationSelector,
    DeliverySelector, IDeliveryAndPaymentResult,
    ParcelShopsRequest,
    PaymentSelector
} from "../cart/common";
import {DeliveryFreeFromResult} from "./common";
import {AsyncSubject, Observable} from "rxjs";
import {map, take, tap} from "rxjs/operators";
import {SettingsService} from "../../services/settings.service";
import {ToQueryStringParam} from "../../helpers/string.helper";
import {CredentialStorage} from "../../services/credential-storage.service";

@Injectable()
export class DeliveryPaymentService {

    get deliveryPayment(): DeliveryPayment {
        return this._deliveryPayment;
    }

    set deliveryPayment(value: DeliveryPayment) {
        this._deliveryPayment = value;
        if (!value || !value.delivery/* ||!value.payment*/) {
            //this.setDefaults();
        } else {
            this.deliveryPaymentSetFromPersistence.emit(value);
        }
    }

    get payments(): PaymentSelector[] {
        return this._payments;
    }

    set payments(value: PaymentSelector[]) {
        this._payments = value;
    }

    get deliveries(): DeliverySelector[] {
        return this._deliveries;
    }

    set deliveries(value: DeliverySelector[]) {
        this._deliveries = value;
    }

    get relations(): DeliveryPaymentRelationSelector[] {
        return this._relations;
    }

    set relations(value: DeliveryPaymentRelationSelector[]) {
        this._relations = value;
    }

    get cartPrice(): number {
        return this._cartPrice;
    }

    // from cart.service
    set cartPrice(value: number) {
        this._cartPrice = value;
    }

    get destinationCountryId(): number {
        return this._destinationCountryId;
    }

    set destinationCountryId(value: number) {
        this._destinationCountryId = value;
    }

    get deliveryFreeFrom(): number {
        return this._deliveryFreeFrom;
    }

    set deliveryFreeFrom(value: number) {
        this._deliveryFreeFrom = value;
    }

    private _deliveryFreeFrom: number;
    private _destinationCountryId: number;
    private _cartPrice: number;
    private _relations: DeliveryPaymentRelationSelector[] = [];
    private _deliveries: DeliverySelector[] = [];
    private _payments: PaymentSelector[] = [];
    private _deliveryPayment: DeliveryPayment;

    // toto je pro delivery-payment.component, abych mohl reagovat na to, ze jsi zastrcil
    // dopravu nebo platbu z persistence cart.service
    deliveryPaymentSetFromPersistence: EventEmitter<DeliveryPayment> = new EventEmitter<DeliveryPayment>();

    // toto je emitter pro konzumaci v cart.service
    deliveryPaymentChanged: EventEmitter<DeliveryPayment> = new EventEmitter<DeliveryPayment>();

    constructor(
        private http: HttpClient,
        protected _seSvc: SettingsService
    ) {
        // this.destinationCountryId = this.seSvc.settings.countries[0].id;
        this.deliveryPayment = {
            delivery: null,
            payment: null
        }
        // this.getData()
        //     .subscribe(res => {
        //         this.deliveryPaymentChanged.emit(res);
        //     });
    }

    OnDeliveryAndPaymentsDataLoaded: AsyncSubject<void> = new AsyncSubject<void>();

    getData(setDefault: boolean = false): Observable<DeliveryPayment> {
        return this.GetDeliveriesAndPayments()
            .pipe(
                take(1),
                tap(res => {
                    this.relations = res.Relations;
                    this.deliveries = res.Deliveries;
                    this.payments = res.Payments;

                    if(!this.OnDeliveryAndPaymentsDataLoaded.closed) {
                        this.OnDeliveryAndPaymentsDataLoaded.next();
                        this.OnDeliveryAndPaymentsDataLoaded.complete();
                    }

                    if(/*!this.deliveryPayment.payment ||*/ !this.deliveryPayment.delivery || setDefault) {
                        this.setDefaults();
                    }
                }),
                map((_) => this.deliveryPayment)
            );
    }

    public setDefaults(): void {
        if ((this.relations.length > 0)) {
            let defaultRelation = this.relations.find(y => y.isDefault) || this.relations[0];

            if(CredentialStorage.isUserB2B){
                let defB2BPayment = this.payments.find(x=>x.b2bDefault).id;
                defaultRelation = this.relations.find(y => y.paymentId == defB2BPayment) || this.relations.find(y => y.isDefault) || this.relations[0];
            }

            const defaultDeliveryId = defaultRelation.deliveryId;
            const defaultPaymentId = defaultRelation.paymentId;
            this.deliveryPayment.delivery = this.deliveries.find(x => x.id === defaultDeliveryId);
            this.deliveryPayment.payment = this.payments.find(x => x.id === defaultPaymentId);
        }
        // if (!this.deliveryPayment.delivery) {
        //     this.deliveryPayment.delivery = this.deliveries[0];
        //     this.deliveryPayment.payment = this.payments[0];
        // }
    }

    getDeliveryFreeFrom(countryId: number): Observable<DeliveryFreeFromResult> {
        return this.http.get<DeliveryFreeFromResult>(`api/deliverypayment/delivery-free-from/${countryId}`);
    }

    getDeliveryUlozenkaBranches(countryId: number): any {
        return this.http.get(`api/deliverypayment/get-ulozenka-deliveres?qs=${ToQueryStringParam({CountryId: countryId})}`);
    }

    getGlobalDeliveryFreeFromActive() {
        return this.http.get('api/settings/delivery-free-from-active');
    }

    pplInformation(request: ParcelShopsRequest): any {
        let url = `api/deliverypayment/get-ppl-filter`;
        return this.http.post(url, request);
    }

    cpBranchInformation(request: ParcelShopsRequest): any {
        let url = `api/deliverypayment/get-cppo-filter`;
        return this.http.post(url, request);
    }

    zasilkovnaApi(): any {
        let url = 'api/deliverypayment/get-zasilkovna-api';
        return this.http.get(url);
    }

    GetDeliveriesAndPayments(): Observable<IDeliveryAndPaymentResult> {
        const request: any = {
            CountryId: this.destinationCountryId
        };

        let url: string = `api/deliverypayment/get-deliveries-and-payments?qs=${ToQueryStringParam(request)}`;
        return this.http.get<IDeliveryAndPaymentResult>(url);
    }
}
