<div class="modal-availability">
    <div class="modal-availability__title heading-h3">
        {{s('availability-dialog--title-text')}}
    </div>

    <div class="modal-availability__in">
        <div class="modal-availability__item" [class.modal-availability__item--central]="item.Store?.IsCentral" *ngFor="let item of config.data?.Availabilities">
            <div class="modal-availability__name">
                {{item.Store.Name}}
            </div>

            <div class="modal-availability__amount" [class.modal-availability__amount--green]="item.Amount > 0" [class.availability-dialog__item-amount--red]="item.Amount <= 0">
                <ng-container *ngIf="item.Amount > 0; else elseNotInStock">
                    {{sen['availability--in-stock']}}
                    <cmp-availability-amount [amount]="item.Amount" [round]="!config.data.ProductionFinished"></cmp-availability-amount>
                    <span [innerHTML]="config.data?.UnitShortcut"></span>
                </ng-container>

                <ng-template #elseNotInStock>
                    {{s('availability--not-in-stock')}}
                </ng-template>
            </div>
        </div>
    </div>
</div>