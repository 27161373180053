import {Injectable} from "@angular/core";
import {
    CookieConsentCookieType,
    CookiesConsent,
    CookiesConsentCookieSelector,
    CookiesConsentGroupSelector,
    CookiesConsentScriptSelector,
    CookiesConsentStatus,
    GtmConsent
} from "./common";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import {SettingsService} from "../../services/settings.service";
import CookieInterceptor from "cookie-interceptor";
import {StorageInterceptor} from "../../helpers/storage.interceptor";
import {loadFromLocalStorage, saveToLocalStorage} from "../../helpers/cookie.helper";
import {ReplaySubject} from "rxjs";
import {PageViewService} from "../../services/page-view.service";

@Injectable()
export class CookiesConsentService {

    userConsentKey: string = 'userConsentKey';

    registeredCookies: Array<CookiesConsentCookieSelector>;
    registeredCookiesLoaded: boolean = false;

    dataLoadedEvent: ReplaySubject<any> = new ReplaySubject<any>(1);

    groups: Array<CookiesConsentGroupSelector> = null;
    scripts: Array<CookiesConsentScriptSelector> = null;

    defaultOrUpdate = 'default';
    showWarning: boolean = false; //is set in setDefaultConsent()


    private cookiesToRegister: Array<CookiesConsentCookieSelector> = [];
    private userConsent: CookiesConsent = null;

    get lastUpdate(): string {
        if (!this.userConsent?.date) {
            return null;
        }
        const tmp = new Date(this.userConsent.date.toString());
        return `${tmp.getDate()}.${tmp.getMonth() + 1}.${tmp.getFullYear()}`;
    }

    isGtm(): boolean {
        if (this.seSvc.gaSettings.gtmId) {
            return true;
        }
        return false;
    }

    isGoogleAnalytics(): boolean {
        if (this.seSvc.gaSettings.analyticsId) {
            return true;
        }
        return false;
    }

    isGoogleAds(): boolean {
        if (this.seSvc.gaSettings.adsId) {
            return true;
        }
        return false;
    }

    isGoogleAdsRemarketing(): boolean {
        if (this.seSvc.gaSettings.remarkId) {
            return true;
        }
        return false;
    }

    isSklikConversion(): boolean {
        if (this.seSvc.seznamSettings.sklikConversionId) {
            return true;
        }
        return false;
    }

    isSklikConversionEnabled() {
        if (!this.isSklikConversion()) {
            return false;
        }
        const script = this.scripts.find(s => s.code == 'sklik-conversion');
        return this.isScriptEnabled(script);
    }

    isSklikRetarget(): boolean {
        if (this.seSvc.seznamSettings.sklikRetargetingId) {
            return true;
        }
        return false;
    }

    isSklikRetargetEnabled(): boolean {
        if (!this.isSklikRetarget()) {
            return false;
        }

        const script = this.scripts.find(s => s.code == 'sklik-retargeting');
        return this.isScriptEnabled(script);
    }

    isZboziConversion(): boolean {
        if (this.seSvc.seznamSettings.zboziConversionBranchId) {
            return true;
        }
        return false;
    }

    isZboziConversionEnabled(): boolean {
        if (!this.isZboziConversion()) {
            return false;
        }

        const script = this.scripts.find(s => s.code == 'zbozi-conversion');
        return this.isScriptEnabled(script);
    }

    isHeureka(): boolean {
        if (this.seSvc.heurekaSettings.publicKey) {
            return true;
        }
        return false;
    }


    isHeurekaEnabled() {
        if (!this.isHeureka()) {
            return false;
        }
        const script = this.scripts.find(s => s.code == 'heureka');
        return this.isScriptEnabled(script);
    }


    constructor(private http: HttpClient, private seSvc: SettingsService, private pvSvc: PageViewService) {

        this.seSvc.startUpSettingsAreSet.pipe(take(1))
            .subscribe(() => {
                this.userConsent = this.loadUserContent();
                if (!this.userConsent || !this.userConsent.date) {
                    //uzivatel neklikl nikdy na nic
                    this.setDefaultConsent();
                } else {
                    this.defaultOrUpdate = 'update';
                    this.setConsent(this.userConsent);
                }
            });

        this.getAllCookies();
        this.getAllScripts();
    }

    get isStorageInterceptingActive(): boolean {
        //if on local or on dev
        return location.host == 'localhost'
            || location.host.endsWith('.loc')
            || location.host.indexOf('scdev.') > -1;

    }

    /// this method works only on localhost or .loc or scdev. domains
    // in production environment it does nothing
    setStorageHooks(): void {

        if (this.isStorageInterceptingActive) {
            //cookies
            CookieInterceptor.init(); // Hijack the `document.cookie` object
            //when cookie is written use>
            CookieInterceptor.write.use((val) => {
                //our CookiesConsentService should know
                this.onSetCookie(val);
                return val;
            });

            StorageInterceptor.hijackLocalStorage(this);
            StorageInterceptor.hijackSessionStorage(this);
        }
    }

    private registerCookies(): void {
        if (!this.cookiesToRegister || !this.cookiesToRegister.length) {
            return;
        }
        const data = this.cookiesToRegister.filter(() => true);
        this.cookiesToRegister = [];
        data.forEach((i) => {
            console.warn('new storage item was registered', i);
        });
        this.http.post<boolean>('api/cookies-consent/register', data)
            .pipe(take(1))
            .subscribe(() => {

                this.getAllCookies();
            });
    }

    private onSetCookie(cookie: string) {
        let splitted: Array<string> = cookie.split('=');
        const name: string = splitted[0];
        // splitted = splitted[1].split(';');
        // const value = splitted[0];
        splitted = cookie.split(';');
        let expires: number = null;
        let maxAge: number = null;

        if (splitted.length > 1) {
            for (let i = 1; i < splitted.length; i++) {
                const current: string = splitted[i];
                const currentSplitted = current.split('=');

                switch (currentSplitted[0]) {
                    case 'max-age':
                        maxAge = Number(currentSplitted[1]);
                        break;
                    case 'expires':
                        expires = Date.parse(currentSplitted[1]);
                        break;
                    default:
                        break;
                }
            }
        }

        if (expires) {
            const now = Date.parse(new Date().toUTCString());
            maxAge = (expires - now) / 1000;
        }

        const cookieObj: CookiesConsentCookieSelector = {
            name,
            groupId: null,
            groupCode: null,
            id: null,
            type: CookieConsentCookieType.cookie,
            vendor: null,
            maxAge,
            description: null,
            script: null,
            scriptId: null
        };

        if (!this.isCookieRegistered(cookieObj)) {
            this.cookiesToRegisterPush(cookieObj);
            if (this.registeredCookiesLoaded) {
                this.registerCookies();
            }
        }
    }

    private isCookieRegistered(cookie: CookiesConsentCookieSelector): boolean {
        if (!this.registeredCookies || !this.registeredCookies.length) {
            return false;
        }
        if (this.registeredCookies.find((rc) => {
            return rc.name == cookie.name && rc.type == cookie.type;
        })) {
            return true;
        }
        return false;
    }

    onLocalStorageSetItem(key: string) {
        const item: CookiesConsentCookieSelector = {
            name: key,
            groupId: null,
            groupCode: null,
            id: null,
            type: CookieConsentCookieType.localStorage,
            vendor: null,
            maxAge: null,
            description: null,
            script: null,
            scriptId: null
        };
        if (!this.isCookieRegistered(item)) {
            this.cookiesToRegisterPush(item);

            if (this.registeredCookiesLoaded) {
                this.registerCookies();
            }
        }
    }

    onSessionStorageSetItem(key: string) {
        const item: CookiesConsentCookieSelector = {
            name: key,
            groupId: null,
            groupCode: null,
            id: null,
            type: CookieConsentCookieType.sessionStorage,
            vendor: null,
            maxAge: null,
            description: null,
            script: null,
            scriptId: null
        };
        if (!this.isCookieRegistered(item)) {
            this.cookiesToRegisterPush(item);

            if (this.registeredCookiesLoaded) {
                this.registerCookies();
            }
        }
    }

    private cookiesToRegisterPush(item: CookiesConsentCookieSelector) {
        if (!this.cookiesToRegister) {
            this.cookiesToRegister = [];
        }
        if (this.cookiesToRegister.find(i => i.name == item.name && i.type == item.type)) {
            return;
        }
        this.cookiesToRegister.push(item);
    }

    private cleanCookiesToRegister() {
        this.cookiesToRegister = this.cookiesToRegister.filter((cr) => {
            return !this.isCookieRegistered(cr);
        })
    }

    getAllCookies(): void {
        this.http.get<Array<CookiesConsentCookieSelector>>('api/cookies-consent/get-all-cookies')
            .pipe(take(1))
            .subscribe((res) => {
                this.registeredCookies = res;
                this.registeredCookiesLoaded = true;
                this.cleanCookiesToRegister();
                this.registerCookies();
                this.dataLoadedEvent.next();
            });
    }

    getAllScripts(): void {

        this.http.get<Array<CookiesConsentScriptSelector>>('api/cookies-consent/get-all-scripts')
            .pipe(take(1))
            .subscribe((res) => {
                this.scripts = res;
                this.dataLoadedEvent.next();
            });
    }

    getAllGroups(): void {
        this.http.get<Array<CookiesConsentGroupSelector>>('api/cookies-consent/get-all-groups')
            .pipe(take(1))
            .subscribe((res) => {
                this.groups = res;
                this.dataLoadedEvent.next();
            });
    }

    getCookies(groupId: number): Array<CookiesConsentCookieSelector> {
        let ret: Array<CookiesConsentCookieSelector> = null;
        if (!this.registeredCookies) {
            //
        } else if (!groupId) {
            ret = this.registeredCookies;
        } else {
            ret = this.registeredCookies.filter(c => c.groupId == groupId);
        }
        return ret;
    }

    gtag_(arg0: string, arg1: string | Date, arg2: GtmConsent = null): void {
        (<any>window).dataLayer = (<any>window).dataLayer || [];

        const args: any = {'0': arg0, '1': arg1};

        if (arg2) {
            args['2'] = arg2;
        }
        (<any>window).dataLayer.push(args);
    }
    gtag(_arg0?: string, _arg1?: string | Date, _arg2?: GtmConsent): void {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push(arguments);
        console.log('gtag push', arguments);
    }
    setConsent(consent: CookiesConsent): void {
        console.log(consent);
        this.userConsent = consent;
        this.storeUserConsent();
        this.gtag('consent', this.defaultOrUpdate, consent.gtmConsent);
        this.defaultOrUpdate = 'update';
        this.gtag('js', new Date());
        this.gtag('config', this.seSvc.gaSettings.analyticsId);
        this.pvSvc.fireOnPageView();
    }

    private storeUserConsent() {
        saveToLocalStorage(this.userConsentKey, this.userConsent);
    }

    private loadUserContent(): CookiesConsent {
        return loadFromLocalStorage(this.userConsentKey);
    }

    setDefaultConsent(): void {
        this.showWarning = true;
        const consent = new CookiesConsent();
        consent.gtmConsent.setDefault();
        this.setConsent(consent);
    }


    private isScriptEnabled(script: CookiesConsentScriptSelector) {
        if (!script) {
            return false;
        }

        if (script.cookies?.find(c => c.groupCode == "unclassified_storage")
        ) {
            return false;
        }

        if (!this.userConsent || !this.userConsent.gtmConsent) {
            return false;
        }

        if (this.userConsent.gtmConsent.ad_storage == CookiesConsentStatus.denied
            && script.cookies?.find(c => c.groupCode == "ad_storage")
        ) {
            return false;
        }

        if (this.userConsent.gtmConsent.ad_user_data == CookiesConsentStatus.denied && script.cookies?.find(c => c.groupCode == "ad_storage")) {
            return false;
        }

        if (this.userConsent.gtmConsent.ad_personalization == CookiesConsentStatus.denied && script.cookies?.find(c => c.groupCode == "ad_storage")) {
            return false;
        }

        if (this.userConsent.gtmConsent.analytics_storage == CookiesConsentStatus.denied
            && script.cookies?.find(c => c.groupCode == "analytics_storage")
        ) {
            return false;
        }

        if (this.userConsent.gtmConsent.personalization_storage == CookiesConsentStatus.denied
            && script.cookies?.find(c => c.groupCode == "personalization_storage")
        ) {
            return false;
        }

        if (this.userConsent.gtmConsent.security_storage == CookiesConsentStatus.denied
            && script.cookies?.find(c => c.groupCode == "security_storage")
        ) {
            return false;
        }

        return true;
    }


    isAnalyticsConsent(): boolean {
        if (this.userConsent?.gtmConsent?.analytics_storage == CookiesConsentStatus.granted) {
            return true;
        }
        return false;
    }


    isAdsConsent(): boolean {
        if (this.userConsent?.gtmConsent?.ad_storage == CookiesConsentStatus.granted) {
            return true;
        }
        return false;
    }

    isSecurityConsent(): boolean {
        if (this.userConsent?.gtmConsent?.security_storage == CookiesConsentStatus.granted) {
            return true;
        }
        return false;
    }

    isPersonalizationConsent(): boolean {
        if (this.userConsent?.gtmConsent?.personalization_storage == CookiesConsentStatus.granted) {
            return true;
        }
        return false;
    }


    isFunctionalityConsent(): boolean {
        //mel by byt dycky true
        if (this.userConsent?.gtmConsent?.functionality_storage == CookiesConsentStatus.granted) {
            return true;
        }
        return false;
    }
}