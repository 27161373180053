import {Component, ElementRef, HostListener, Renderer2, ViewChild, ViewEncapsulation} from "@angular/core";
import {CategoryItemSelectorBrief, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {filter, takeUntil} from "rxjs/operators";
import {document} from "ngx-bootstrap/utils";
import {MenuService} from "../../services/menu.service";
import {NavigationEnd, Router} from "@angular/router";

declare let $: any;

@Component({
    selector: 'cmp-menu',
    templateUrl: '../../tpl/menu.html',
    styleUrls: ['../../assets/styles/3-layout/menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MenuComponent extends Translatable {

    private ngUnsubscribe: Subject<any> = new Subject<any>();
    @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

    private menuActive: boolean = false;
    get categories(): Array<CategoryItemSelectorBrief>{
        return this.menuSvc.categories;
    };

    private _menu_element: any;
    private get menu_element(): any {
        if (!this._menu_element) {
            this._menu_element = document.body.querySelector('.menu');
        }
        return this._menu_element;
    }

    private _js_menu_element: any;
    private get js_menu_element(): any {
        if (!this._js_menu_element) {
            this._js_menu_element = document.body.querySelector('.js-menu');
        }
        return this._js_menu_element;
    }

    private _header_nav_element: any;
    private get header_nav_element(): any {
        if (!this._header_nav_element) {
            this._header_nav_element = document.body.querySelector('.header-nav');
        }
        return this._header_nav_element;
    }

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private http: HttpClient,
        private menuSvc: MenuService,
        private renderer: Renderer2,
        private router: Router
    ) {
        super(dataSvc, seSvc);

        this.menuSvc.toggleMenu
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.toggleMenu();
            });
        this.menuSvc.deactivate
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.deactivateMenu();
            });

/*        this.router.events.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                if( this.categories != null && this.categories.length > 0){
                    this.menuSvc.setAllIsActive(false,this.categories);
                }
            });*/

        this.router.events.pipe(filter(evt => evt instanceof NavigationEnd))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
                if (!/^\/c\//i.test(res.url) ) {
                    if( this.categories != null && this.categories.length > 0){
                        this.menuSvc.setAllIsActive(false);
                    }
                }
            });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getCategorySubcats(catId: number): void {
        let cat = this.findNodeById(catId);
        if( cat && !cat?.subCats){
            this.http.get<CategoryItemSelectorBrief[]>(`api/category/getCategoryTwoLevelSubTree/${catId}`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    cat.subCats = res;
                });
        }
    }

    isActiveCat(idToFind: number): boolean | undefined {
        return this.menuSvc.isActiveCat(idToFind);
    }

    findNodeById(idToFind: number): CategoryItemSelectorBrief {
        return this.menuSvc.findNodeById(idToFind);
    }

    updateIsActiveById(idToUpdate: number): void {
        this.menuSvc.updateIsActiveById(idToUpdate);
    }

    categoryClick(catId: number): void {
        this.getCategorySubcats(catId);
        this.updateIsActiveById(catId);
    }

    onCategoryClick(href: string) {
        const category = document.querySelector(`[href="${href}"]`);
        if (category) {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;

            // Recalculate scroll position after layout change
            window.requestAnimationFrame(() => {
                const categoryTop = category.getBoundingClientRect().top;
                const targetScrollTop = scrollTop + categoryTop - (windowHeight / 2);

                window.scrollTo({
                    top: targetScrollTop,
                    behavior: 'smooth'
                });
            });
        }
    }

    deactivateMenu() {
        if (this.menu_element) {
            this.renderer.removeClass(this.menu_element, "menu--active");
        }
        if (this.header_nav_element) {
            this.renderer.removeClass(this.header_nav_element, "header-nav--active");
        }
        this.renderer.removeClass(document.documentElement, "menu-active");

        this.menuActive = false;
    }

    toggleMenu(): void {
        if (!this.menuActive) {
            if (this.menu_element) {
                this.renderer.addClass(this.menu_element, "menu--active");
            }
            if (this.header_nav_element) {
                this.renderer.addClass(this.header_nav_element, "header-nav--active");
            }
            this.renderer.addClass(document.documentElement, "menu-active");

            this.getCategorySubcats(this.categories.find((x) => x.isActive === true)?.id);

            this.menuActive = true;
        } else {
            this.deactivateMenu();
        }
    }

    @HostListener('document:click', ['$event'])
    handleClick(ev: any) {
        const $target = $(ev.target);

        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }
        var test = document.body.querySelector('.responsive-menu');
        if($target.closest(this.menu_element).length === 0 && $target.closest(test).length === 0){
            this.deactivateMenu();
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler() {
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }
    }
}
