import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CredentialStorage} from "../../services/credential-storage.service";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {QuestionFormComponent} from "../question-form/question-form/question-form.component";

@Component({
    selector: 'cmp-footer-links',
    templateUrl: '../../tpl/footer-links.html',
    styleUrls: ['../../assets/styles/3-layout/footer/footer-links.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FooterLinksComponent extends Translatable implements OnInit {

    @Input() showQuestionFormLink: boolean = false;
    @Input() article: ArticleBriefSelector = null;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        public dialogSvc: GeneralDialogService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {

    }

    public isArticleItemAllowed(articleItem: ArticleBriefSelector): boolean {

        //console.log(CredentialStorage.isUserB2B === false
        //    , this.article?.code
        //    , articleItem.name
        //    , articleItem.code );

        if (CredentialStorage.isUserB2B === false
            && this.article?.code == 'footer-col-3'
            && articleItem.code == 'footer-price-lists') {
            return false;
        }

        return true;
    }

    public OnSendQuestionClick(): void {
        const data: any = {};

        const config: GeneralDialogConfig<void> = {
            data: data,
            cssClassModifier: null,
            isCloseAble: true,
            title: null
        };

        const dialogRef = this.dialogSvc.open(QuestionFormComponent, config);
        dialogRef.afterClosed
            .subscribe(() => {
            });
    }
}
