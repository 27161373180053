import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CartService} from "../cart/cart.service";
import {CompareProductsService} from "../../services/compare-products.service";
import {PageStateService} from "../../services/page-state.service";
import {ProductBoxComponent} from "./product-box.component";
import {CartItemSelectorBrief} from "../cart/common";
import {IdentityService} from "../../services/identity.service";

declare let $: any;

@Component({
    selector: 'cmp-mini-product-box',
    templateUrl: '../../tpl/mini-product-box.html',
    styleUrls: ['../../assets/styles/2-components/product-box-mini.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MiniProductBoxComponent extends ProductBoxComponent {

    @Input() fromCart: boolean = false;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        cartSvc: CartService,
        compareSvc: CompareProductsService,
        pageStateSvc: PageStateService,
        public idSvc: IdentityService
    ) {
        super(dataSvc, seSvc, cartSvc, compareSvc, pageStateSvc, idSvc);
    }

    buy(id: number, amount: number): void {
        let cart: CartItemSelectorBrief = {
            count: amount,
            productId: id,
            fromCart: this.fromCart
        };

        this.cartSvc.addToCart(cart);
    }
}


