<div class="product-list product-list--swiper product-list--box" id="{{productCarouselId}}" *ngIf="viewType === CarouselViewType.ProductBox && items.length > 0">
    <div class="swiper-nav swiper-nav--prev">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-left" />
            </svg>
        </div>
    </div>

    <div class="swiper-nav swiper-nav--next">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-right" />
            </svg>
        </div>
    </div>

    <swiper [config]="config">
        <ng-template swiperSlide *ngFor="let product of items;">
            <cmp-product-box [fullContent]="fullContent" [showFlags]="showFlags" [productboxproduct]="product | ProductSelectorToProductBox">
            </cmp-product-box>
        </ng-template>
    </swiper>
</div>

<div class="product-list product-list--box-mini" id="{{productCarouselId}}" *ngIf="viewType === CarouselViewType.MiniProductBox && items.length > 0">
    <div class="swiper-nav swiper-nav--prev">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-left" />
            </svg>
        </div>
    </div>

    <div class="swiper-nav swiper-nav--next">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-right" />
            </svg>
        </div>
    </div>

    <swiper [config]="config">
        <ng-template swiperSlide *ngFor="let product of items;">
            <cmp-mini-product-box  [fullContent]="fullContent" [showFlags]="showFlags" [productboxproduct]="product | ProductSelectorToProductBox">
            </cmp-mini-product-box>
        </ng-template>
    </swiper>
</div>
