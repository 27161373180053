/**
 * Component for displaying product related, alternative etc items in sick carousel.
 */
import {Component, Input,ViewEncapsulation} from '@angular/core';
import {SettingsService} from "../../services/settings.service";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {ProductSelector} from "../product/common";
import SwiperCore, {Autoplay, Navigation, Pagination, SwiperOptions} from "swiper";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination]);

export enum CarouselViewType {
    ProductBox,
    MiniProductBox
};

@Component({
    selector: 'cmp-product-carousel-v2',
    templateUrl: '../../tpl/product-carousel-v2.html',
    styleUrls: ['../../assets/styles/2-components/product-list.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductCarouselV2 extends Translatable {

    public CarouselViewType = CarouselViewType;

    @Input() viewType: CarouselViewType = CarouselViewType.ProductBox;
    @Input() products: ProductSelector[];
    @Input() productCarouselId: string;
    @Input() titleSentenceKey: string;
    @Input() fullContent: boolean = true;
    @Input() showFlags: boolean = true;

    items: ProductSelector[] = [];
    selector: string;

    config: SwiperOptions = {};
    private delay: number = 3000;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
    ) {
        super(dataSvc, seSvc);
    }

    private initSwiper(): void {
        this.config = {  //swiper init values, workaround: if needed in modal/cart - dynamic values set to swiper inputs in the template
            slidesPerView: 1,
            spaceBetween: 0,
            autoplay: {delay: this.delay, disableOnInteraction: false, pauseOnMouseEnter: true},
            pagination: {clickable: true, type: 'bullets'},
            navigation: {
                nextEl: ".product-list .swiper-nav--next",
                prevEl: ".product-list .swiper-nav--prev",
            },
            breakpoints: {
                "480": {
                    slidesPerView: 2,
                    spaceBetween: 0
                },
                "768": {
                    slidesPerView: 3,
                    spaceBetween: 0
                },
                "1200": {
                    slidesPerView: 4,
                    spaceBetween: 0
                }
            },
        }
    }

    ngOnInit(): void {
        this.items = this.products;
        this.selector = `#${this.productCarouselId}`;
        this.initSwiper();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
