import {ParametricFilterRequest} from "../../interfaces/general";
import {ProductSelector} from "../product/common";
import {ParameterSelector} from "../product-parameters-filter/common";
import {ProducerSelectorBrief} from "../producer-filter/common";
import {IStoreFilterItem} from "../stores/common";

export interface ProductRequest {
    categoryId: number;
    cultureId: number;
    pageIndex: number;
    pageSize: number;
    priceRange: number[];
    orderByKeys: string[];
    producers: number[];
    spots: number[];
    flags: string[];
    showAvailableOnly: boolean;
    parametricFilter?: ParametricFilterRequest;
    stores: number[];
}

export interface OrderByOptions {
    id: string;
    name: string;
}

export class CategoryConfigInitializer {
    categoryId?: number;
    cultureId?: number;
    pageSizeIndex?: number;
    pageIndex?: number;
    selectedSpots?: number[];
    selectedProducers?: number[];
    selectedStores: number[];
    selectedFlags: string[];
    selectedPriceRange: number[];
    selectedOrderBy: OrderByOptions;
    orderByIndex: number;
    filterIsActive: boolean;
    showAvailableOnly: boolean;
    paramsHash: string;
    pageSizes: any[];
}

export class CategoryConfig extends CategoryConfigInitializer {

    constructor(init: CategoryConfigInitializer) {
        super();
        this.categoryId = init.categoryId;
        this.cultureId = init.cultureId;
        this.pageSizeIndex = init.pageSizeIndex;
        this.pageIndex = init.pageIndex;
        this.selectedProducers = init.selectedProducers;
        this.selectedFlags = init.selectedFlags;
        this.selectedPriceRange = init.selectedPriceRange;
        this.selectedOrderBy = init.selectedOrderBy;
        this.orderByIndex = init.orderByIndex;
        this.filterIsActive = init.filterIsActive;
        this.showAvailableOnly = init.showAvailableOnly;
        this.paramsHash = init.paramsHash;
        this.pageSizes = init.pageSizes;
        this.selectedStores = init.selectedStores;
    }

    public get pageSize(): number {

        if (!this.pageSizes || this.pageSizeIndex == null || this.pageSizeIndex < 0) {
            return null;
        }
        return this.pageSizes[this.pageSizeIndex].value;
    }

    public set pageSize(value: number) {
        if (!this.pageSizes || !value) {
            return;
        }

        let pageSize = this.pageSizes.find(ps => ps.value == value);
        if (pageSize) {
            this.pageSizeIndex = this.pageSizes.indexOf(pageSize);
        }
    }
}

export interface SeoInfoSelector {
    seoTitle: string;
    seoDescription: string;
    seoUrl: string;
}

export interface ProductResponseModel {
    products: ProductSelector[];
    totalCount: number;
    minPrice: number;
    maxPrice: number;
}


export interface CategoryDetailDataSelector {
    products: ProductResponseModel;
    parameters: Array<ParameterSelector>;
    producers: Array<ProducerSelectorBrief>;
    availableOnlyCount: number;
    prices: Array<number>;
    priceMin: number;
    priceMax: number;
    actionCount:number;
    newsCount:number;
    recommendedCount:number;
    sellOutCount:number;
    stores: IStoreFilterItem[];

    // just for debugging and informative purposes
    passesCount?: number
}
