<ng-container *ngIf="resultState === null; else elseResultState">

    <div class="modal-form" [formGroup]="form">
        <div class="modal-form__in">
            <div class="modal-form__item">
                <label for="qfn" class="form-label">
                    {{s('question-name')}} <span>*</span>
                </label>

                <div class="modal-form__value">
                    <input id="qfn" type="text" formControlName="Name" class="form-input">
                    <cmp-validation [control]="form.get('Name')"></cmp-validation>
                </div>
            </div>

            <div class="modal-form__item">
                <label for="quest_email" class="form-label">
                    {{s('question-email')}} <span>*</span>
                </label>

                <div class="modal-form__value">
                    <input id="quest_email" type="text" formControlName="Email" class="form-input">
                    <cmp-validation [control]="form.get('Email')"></cmp-validation>
                </div>
            </div>

            <div class="modal-form__item">
                <label for="quest_subject" class="form-label">
                    {{s('question-subject')}} <span>*</span>
                </label>

                <div class="modal-form__value">
                    <input id="quest_subject" type="text" formControlName="Subject" class="form-input">
                    <cmp-validation [control]="form.get('Subject')"></cmp-validation>
                </div>
            </div>

            <div class="modal-form__item modal-form__item--full">
                <label for="question_content" class="form-label">
                    {{s('question-content')}} <span>*</span>
                </label>

                <div class="modal-form__value">
                    <textarea formControlName="Content" id="question_content" class="form-textarea" wrap="hard"></textarea>

                    <cmp-validation [control]="form.get('Content')"></cmp-validation>
                </div>
            </div>
        </div>

        <div class="modal-form__actions">
            <button class="btn btn--primary btn--medium" (click)="OnSendClick()">
                {{s('question-send')}}
            </button>

            <div class="modal-form__policy" *ngIf="termsAndConditionsMessage">
                <span [innerHTML]="termsAndConditionsMessage"></span>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #elseResultState>
    <cmp-alert-box mode="success" [text]="s('question-send-success')" *ngIf="resultState === true; else elseResultStateError"></cmp-alert-box>
    <ng-template #elseResultStateError>
        <cmp-alert-box mode="error" [text]="s('question-send-fail')"></cmp-alert-box>
    </ng-template>
</ng-template>


