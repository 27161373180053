<cmp-js-scripts></cmp-js-scripts>

<cmp-notify-stripe></cmp-notify-stripe>

<div class="top-bar" *ngIf="false">
    <div class="container">
        <div class="top-bar__in">
            <div class="top-bar__right">
                <cmp-login [classModificator]="'desktop'"></cmp-login>
                <!--nema to tu byt a netusim proc na ostre to nebylo a na devu jo-->
                <cmp-header-option *ngIf="false"></cmp-header-option>
            </div>
        </div>
    </div>
</div>

<header class="header" sticky [stickyClass]="'header--sticky'">
    <div class="container">
        <div class="header__in">
            <div class="responsive-menu" style="cursor:pointer" (click)="toggleMenu($event)">
                <div class="responsive-menu__line"></div>
                <div class="responsive-menu__line"></div>
                <div class="responsive-menu__line"></div>
            </div>

            <a [routerLink]="['/']" title="{{sen['shop-name']}}" class="logo">
                <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/img-logo.svg'" width="209" height="40" alt="{{sen['shop-name']}}" class="logo__image"/>
            </a>

            <div class="header__in__right">
                <cmp-flex-suggest #suggest></cmp-flex-suggest>

                <a href="{{'tel: ' + sen['index-phone-number']}}" class="header-contact header-contact--desktop">
                    <div class="header-contact__image">
                        <div class="icon">
                            <svg>
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone"/>
                            </svg>
                        </div>
                    </div>

                    <div class="header-contact__content">
                        <div class="header-contact__value">
                            {{sen['index-phone-number']}}
                        </div>

                        <div class="header-contact__text">
                            {{sen['index-opening-hours']}}
                        </div>
                    </div>
                </a>

                <div class="header__in__btns">
                    <cmp-login></cmp-login>

                    <cmp-cart-indicator></cmp-cart-indicator>
                </div>
            </div>
        </div>
    </div>

    <div class="header-nav">
        <div class="container">
            <div class="header-nav__in">
                <div class="header-nav__menu">
                    <cmp-menu></cmp-menu>
                </div>

                <div class="header-nav__links">
                    <cmp-article-main-menu></cmp-article-main-menu>
                </div>

                <div class="header-nav__contact">
                    <a href="{{'tel: ' + sen['index-phone-number']}}" class="header-contact header-contact--responsive">
                        <div class="header-contact__image">
                            <div class="icon">
                                <svg>
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone"/>
                                </svg>
                            </div>
                        </div>

                        <div class="header-contact__content">
                            <div class="header-contact__value">
                                {{sen['index-phone-number']}}
                            </div>

                            <div class="header-contact__text">
                                {{sen['index-opening-hours']}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>

<router-outlet></router-outlet>

<cmp-newsletter-registration *ngIf="dataSvc.displayNewsletter"></cmp-newsletter-registration>

<footer class="footer">
    <div class="container">
        <cmp-footer-columns-list [articles]="articles"></cmp-footer-columns-list>

        <div class="copyright">
            <div class="copyright__text">
                {{seSvc.copyright?.text}}
            </div>

            <div class="copyright__right">
                <div class="copyright__cookies">
                    <a (click)="ccSvc.showWarning=true" href="javascript:void(0)">
                        {{sen['cookies-consent-setting']}}
                    </a>
                </div>

                <div class="copyright__up" (click)="scrollUp()">
                    {{sen['index-scroll-up']}}

                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-up"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- preloader -->
<cmp-preloader [loading]="dataSvc.dataLoading"></cmp-preloader>

<!-- Cookie usage warning -->
<cmp-cookie-warning [DelayToShow]="3000" [ExpireInHours]="720" *ngIf="false"></cmp-cookie-warning>

<!-- Cookie consent new and fancy yet modern -->
<!--<cmp-cookie-consent></cmp-cookie-consent>-->

<cmp-cookies-panel></cmp-cookies-panel>