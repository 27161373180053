
/**
 * Re-Created by jiri.kopel on 06.03.2019.
 */

import {FlexSuggestComponent} from "./flex-suggest.component";
import {FlexSuggestListComponent} from "./flex-suggest-list.component";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {UiDirectivesModule} from "../../directives/ui-directives.module";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {DebugModule} from "../debug/debug.module";
import {PriceModule} from "../price/price.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ImageCacheBustModule,
    UiDirectivesModule,
    CustomPipesModule,
    DebugModule,
    PriceModule
  ],
    declarations: [FlexSuggestComponent, FlexSuggestListComponent],
    exports: [FlexSuggestComponent, FlexSuggestListComponent]
})

export class FlexSuggestModule {}
