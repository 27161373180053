import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ValidationModule} from "../validation/validation.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {AlertBoxModule} from "../alert-box/alert-box.module";
import {QuestionFormComponent} from "./question-form/question-form.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ValidationModule,
        FormsModule,
        ReactiveFormsModule,
        GeneralDialogModule,
        AlertBoxModule
    ],
    declarations: [
        QuestionFormComponent
    ],
    exports: [
        QuestionFormComponent
    ]
})

export class QuestionFormModule {
}