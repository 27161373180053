import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'cmp-availability-amount',
    templateUrl: './availability-amount.component.html',
    styleUrls: ['./availability-amount.component.scss']
})
export class AvailabilityAmountComponent implements OnInit {

    private _round: boolean = true;
    @Input() set round(value: boolean) {
        this._round = value;
        this.Round();
    };

    public get round(): boolean {
        return this._round;
    }

    private _amount: number = null;
    @Input()
    public set amount(value: number) {
        this._amount = value;
        this.Round();
    }

    public get amount(): number {
        return this._amount;
    }

    public isRounded: boolean = null;
    public presentedAmount: number = null;

    private steps: number[] = [100, 1000, 10000, 100000];

    constructor() {
    }

    ngOnInit(): void {
    }

    private Round(): void {
        if (this.round) {
            this.steps = this.steps.sort((a, b) => {
                return a == 0 ? 0 : a > b ? -1 : 1;
            });

            const filtered = this.steps.filter(x => x < this.amount);

            if (filtered.length) {
                const first = filtered[0];
                this.presentedAmount = first;
                this.isRounded = true;
            } else {
                this.presentedAmount = this.amount;
                this.isRounded = false;
            }
        } else {
            this.presentedAmount = this.amount;
            this.isRounded = false;
        }
    }

}
