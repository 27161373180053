import {Component, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {CartItemSelectorFull} from "../cart/common";
import {Subject} from "rxjs";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {CartDialogConfig} from "./common";
import {ProductSelector} from "../product/common";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import SwiperCore, {Autoplay, Navigation, Pagination, SwiperOptions} from "swiper";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination]);

@Component({
    selector: 'cmp-cart-change-modal',
    templateUrl: '../../tpl/cart-change-modal.html',
    styleUrls: ['../../assets/styles/2-components/modal-window/modal-buy.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CartChangeModalComponent extends Translatable {

    cartItem: CartItemSelectorFull;
    productImagePathPrefix: string;
    timeoutId: any;
    timeOutDelay: number = 0;
    decimalPattern: string = '1.0-0';
    ngUnsubscribe: Subject<any> = new Subject<any>();
    currencySymbol: string;
    relatedItems: ProductSelector[] = [];

    swiperConfig: SwiperOptions = {};

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private config: GeneralDialogConfig<CartDialogConfig>,
        private dialogRef: GeneralDialogRef,
        private http: HttpClient
    ) {
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.cartItem = this.config.data.cartItem;
        }

        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.productImagePathPrefix = seSvc.settings.newImageServerUrl;
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.ProductBox}-${this.seSvc.settings.decimalSettings.Basket}`;

        if (this.timeOutDelay > 0) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.closeCartAddDialog();
            }, this.timeOutDelay);
        }
    }

    closeCartAddDialog(): void {
        this.dialogRef.close();
        this.cartItem = null;
    }
    ngOnInit() {
        this.getRelated()
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    private getRelated(): void {
        this.http.get<ProductSelector[]>(`api/product/getRelated/${this.cartItem.productDetail.variantKey}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.relatedItems = /*res.length > 3 ? res.slice(0, 3) :*/ res;

                setTimeout(() => {
                    this.initSwiper();
                }, 400);
            });
    }

    private initSwiper(): void {

        this.swiperConfig = {  //swiper init values, workaround - dynamic values set to swiper inputs in the template (for modal/cart)
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: {delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true},
            navigation: {
                nextEl: ".modal-buy-related .swiper-nav--next",
                prevEl: ".modal-buy-related .swiper-nav--prev",
            },
            breakpoints: {
                "480": {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                "768": {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        }
    }

}
