declare let $: any;

export interface IScrollToArgs {
    element?: HTMLElement,
    selector?: string;
    speed?: number;
    margin?: number;
}

export class ScrollUtils {

    public static ScrollUp(): void {
        ScrollUtils.ScrollTo({selector: 'html, body'});
    }

    public static ScrollTo(data: IScrollToArgs): void {
        const d: IScrollToArgs = Object.assign({}, {
            selector: data.selector,
            speed: data.speed == null ? 250 : data.speed,
            margin: data.margin == null ? 0 : data.margin,
            element: data.element
        })
        const elements = d.element ? $(d.element) : $(`${d.selector}`);

        if (elements.length) {
            $('html, body').animate({
                scrollTop: elements.offset().top - d.margin
            }, d.speed);
        }
    }

}