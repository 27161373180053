export class CurrencyFactory {
    GetCurrency(): number {
        const userLang = navigator.language || (<any>navigator).userLanguage;
        let currencyIdDefault: number = null;

        switch (userLang) {
            case 'cs-CZ':
            case 'cs':
                currencyIdDefault = 3;
                break;
            default:
                currencyIdDefault = 3;
                break;
        }

        return currencyIdDefault;
    }
}