import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: 'cmp-val-msg',
    templateUrl: '../../tpl/validation-msg.html',
    styleUrls: ['../../assets/styles/2-components/validation.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ValidatorMessage {

    @Input() msg: string;

    get message(){
        let re = /(\r\n|\r|\n)/gi;
        return  this.msg.replace(re, "<br>");
    }

}