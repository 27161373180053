import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class PostRedirectService {

    constructor(@Inject(DOCUMENT) private document: Document) { }

    postRedirect(actionUrl: string, params: URLSearchParams) {
        const form: HTMLFormElement = this.document.createElement("form");
        form.method = "POST";
        form.target = "_self";
        form.action = actionUrl;

        params.forEach((value: string, key: string) => {
            const input: HTMLInputElement = this.document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;
            form.append(input);
        });

        this.document.body.appendChild(form);
        form.submit();
    }
}