import {Pipe, PipeTransform} from '@angular/core';
import {IAvailability, IStoreAvailability} from "../commons/commons";
import {ProductVariantBriefSelector} from "../../../interfaces/general";
import {IProductBoxModel} from "../../product/common";

@Pipe({
    name: 'VariantAvailability'
})
export class VariantAvailabilityPipe implements PipeTransform {

    transform(value: ProductVariantBriefSelector): IAvailability {
        if (!value) {
            return null;
        }

        return {
            Availabilities: value?.availability?.Availabilities,
            UnitShortcut: value?.unit?.shortCut,
            ProductionFinished: value?.availability?.ProductionFinished,
            PurchasingBlocked: value?.availability?.PurchasingBlocked,
            OrderTimeDays: value?.availability?.OrderTimeDays,
            IsBuyable: value?.availability?.IsBuyable
        };
    }

}

@Pipe({
    name: 'ProductBoxModelAvailability'
})
export class ProductBoxModelAvailabilityPipe implements PipeTransform {

    transform(value: IProductBoxModel): IAvailability {
        if (!value) {
            return null;
        }

        return {
            Availabilities: value?.availability?.Availabilities,
            UnitShortcut: value?.unit?.shortCut,
            ProductionFinished: value?.availability?.ProductionFinished,
            PurchasingBlocked: value?.availability?.PurchasingBlocked,
            OrderTimeDays: value?.availability?.OrderTimeDays,
            IsBuyable: value?.availability?.IsBuyable
        };
    }

}

@Pipe({
    name: 'ProductDetailAvailability'
})
export class ProductDetailAvailabilityPipe implements PipeTransform {

    transform(value: ProductVariantBriefSelector, avails: IStoreAvailability[]): IAvailability {
        if (!value || !avails) {
            return null;
        }

        return {
            Availabilities: avails,
            UnitShortcut: value?.unit?.shortCut,
            ProductionFinished: value?.availability?.ProductionFinished,
            PurchasingBlocked: value?.availability?.PurchasingBlocked,
            OrderTimeDays: value?.availability?.OrderTimeDays,
            IsBuyable: value?.availability?.IsBuyable
        };
    }

}
