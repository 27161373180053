import {Component, OnInit} from '@angular/core';
import {FacebookLoginStatus} from "../../facebook-sdk/interfaces/FacebookLoginStatus";
import {FacebookSdkService} from "../../facebook-sdk/services/facebook-sdk.service";
import {finalize, takeUntil} from "rxjs/operators";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {DigestService, FacebookLogin} from "../../../services/digest.service";
import {FlexCaptchaService} from "../../../services/flex-captcha.service";

@Component({
    selector: 'cmp-facebook-sign-up-button',
    templateUrl: './facebook-sign-up-button.component.html'
})
export class FacebookSignUpButtonComponent extends Translatable implements OnInit {

    captchaFailed: boolean = false;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private digestSvc: DigestService,
        private fbSvc: FacebookSdkService,
        private fcSvc: FlexCaptchaService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    public executeCaptcha(): void {
        this.fcSvc.executeCaptcha('login', 'login-dialog.component.onCaptchaExecuted')
            .subscribe(res => {
                this.captchaFailed = !res.success;
                if (res.success) {
                    this.fbLogin(res.secret);
                }
            })
    }

    private fbLogin(secret: string): void {

        const $this = this;

        this.dataSvc.dataLoading = true;

        this.fbSvc.GetLoginStatus()
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => {
                        this.dataSvc.dataLoading = false;
                    }
                ))
            .subscribe(response => {

                if (response.status !== FacebookLoginStatus.Connected) {

                    $this.fbSvc.Login()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(response => {

                            if (response.status === FacebookLoginStatus.Connected) {
                                $this.SignUp(response.authResponse.accessToken, secret);
                            }

                        });
                } else {
                    $this.SignUp(response.authResponse.accessToken, secret);

                    // $this.fbSvc.GetCurrentUserInfo()
                    //     .pipe(takeUntil($this.unsubscribe))
                    //     .subscribe((userInfo) => {
                    //         const data: IAlreadySignedUpDialogConfig = {
                    //             Name: userInfo.Name,
                    //             ImageUrl: userInfo.ImageUrl
                    //         };
                    //         const config: GeneralDialogConfig<IAlreadySignedUpDialogConfig> = {
                    //             data: data,
                    //             title: '',
                    //             cssClassModifier: '',
                    //             isCloseAble: true
                    //         };
                    //
                    //         const dialogRef = $this.dialogSvc.open(AlreadySignedUpDialogComponent, config);
                    //
                    //         dialogRef.afterClosed
                    //             .pipe(takeUntil($this.unsubscribe))
                    //             .subscribe((result) => {
                    //
                    //                 if (result === DialogResult.Continue) {
                    //                     $this.SignUp(response.authResponse.accessToken);
                    //                 } else if (result === DialogResult.New) {
                    //
                    //                     $this.fbSvc.Logout().subscribe(() => {
                    //                         $this.fbSvc.Login()
                    //                             .pipe(takeUntil($this.unsubscribe))
                    //                             .subscribe(response => {
                    //
                    //                                 if (response.status === FacebookLoginStatus.Connected) {
                    //                                     $this.SignUp(response.authResponse.accessToken);
                    //                                 }
                    //
                    //                             });
                    //                     });
                    //
                    //                 }
                    //
                    //             });
                    //     });

                }

            });

    }

    private SignUp(accessToken: string, secret: string): void {
        this.dataSvc.dataLoading = true;
        this.digestSvc.login(new FacebookLogin(accessToken, secret));
    }

}
