import {CartSelector} from "../common";
import {ICartProductItem} from "../../product/common";
import {CartTokenSelector} from "../../../interfaces/general";

export enum CartStepType {
    First,
    Second,
    Third
}

export interface IGetPaymentInfoRequest {
    OrderGuid: string;
}

export interface IGetPaymentInfoResult {

}

export interface ICancelOrderRequest {
    OrderGuid: string;
}

export interface ICancelOrderResult {

}

export interface ISyncAbbCartRequest {
    Token: string;
}

export interface ISyncAbbCartResult {
    Cart: CartSelector;
    AddedProducts: ICartProductItem[];
    NotAddedProducts: ICartProductItem[];
}

export enum MergeCartType {
    KeepCurrent, KeepPrevious, Merge
}

export interface IMergeCartRequest {
    Type: MergeCartType;
    Tokens: string[];
    Current: string;
    Previous: string[];
}

export interface IMergeCartResult {
    CartTokens: CartTokenSelector[];
}

export interface IGetCartContentForTokenRequest {
    UpdateAvailability: boolean;
}