<div class="footer__inner">
    <div class="footer__col" [ngClass]="{'footer__col--active':isActiveClass('footer-col-1')}">
        <div class="footer__title" (click)="setColActiveClass('footer-col-1')">
            {{sen['footer-all-about-shopping']}}

            <div class="footer__toggle">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-down"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="footer__wrap">
            <cmp-footer-links [showQuestionFormLink]="true" [article]="articleByCode('footer-col-1')"></cmp-footer-links>
        </div>
    </div>

    <div class="footer__col" [ngClass]="{'footer__col--active':isActiveClass('footer-col-2')}">
        <div class="footer__title" (click)="setColActiveClass('footer-col-2')">
            {{sen['footer-about-company']}}

            <div class="footer__toggle">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-down"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="footer__wrap">
            <cmp-footer-links [article]="articleByCode('footer-col-2')"></cmp-footer-links>
        </div>
    </div>

    <div class="footer__col" [ngClass]="{'footer__col--active':isActiveClass('footer-col-3')}">
        <div class="footer__title" (click)="setColActiveClass('footer-col-3')">
            {{sen['footer-dont-miss']}}

            <div class="footer__toggle">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-down"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="footer__wrap">
            <cmp-footer-links [article]="articleByCode('footer-col-3')"></cmp-footer-links>
        </div>
    </div>

    <div class="footer__col footer__col--active">
        <div class="footer__title" (click)="setColActiveClass('footer-col-4')">
            {{sen['footer-contact-title']}}
        </div>

        <div class="footer__wrap">
            <cmp-footer-contact></cmp-footer-contact>
        </div>
    </div>
</div>