import {EventEmitter, Injectable, Output} from "@angular/core";
import {CategoryItemSelectorBrief} from "../interfaces/general";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";

@Injectable()
export class MenuService {
    toggleMenu: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() deactivate: EventEmitter<void> = new EventEmitter<void>();
    private ngUnsubscribe: Subject<any> = new Subject<any>();
    categories: Array<CategoryItemSelectorBrief>;

    constructor(http: HttpClient){
        http.get<CategoryItemSelectorBrief[]>(`api/category/getRootCategories`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.categories = res.map(item => ({
                    ...item,
                    isActive: false,
                }));
            });
    }

    deactivateMenu(){
        this.deactivate.emit();
    }

    public findNodeById(idToFind: number, nodes: Array<CategoryItemSelectorBrief> = this.categories): CategoryItemSelectorBrief {
        for (const node of nodes) {
            if (node.id === idToFind) {
                return node;
            }

            if (node.subCats) {
                const foundNode = this.findNodeById(idToFind, node.subCats);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return null;
    }

    public isActiveCat(idToFind: number, nodes: Array<CategoryItemSelectorBrief> = this.categories): boolean {
        return this.findNodeById(idToFind, nodes)?.isActive;
    }

    public setAllIsActive(value: boolean, nodes: Array<CategoryItemSelectorBrief> = this.categories): void {
        for (const node of nodes) {
            node.isActive = value;

            if (node.subCats) {
                this.setAllIsActive(value, node.subCats);
            }
        }
    }

    updateIsActiveById(idToUpdate: number, isFromBreadcrumb = false, nodes: Array<CategoryItemSelectorBrief> = this.categories): void {
        for (const node of nodes) {
            if (node.id === idToUpdate) {
                // Pokud je nalezený uzel aktivní, nastaví ho na false a všechny jeho potomky také
                if (node.isActive && !isFromBreadcrumb) {
                    this.setAllIsActive(false, [node]);
                } else {
                    // Pokud není aktivní, nastaví ho na true
                    node.isActive = true;
                    this.setIsActiveForSiblingsAndChildren(node,nodes);
                }
                return; // Zastavíme hledání, protože jsme našli hledaný uzel
            }

            if (node.subCats) {
                // Pokračujeme v hledání v potomcích
                this.updateIsActiveById(idToUpdate, isFromBreadcrumb, node.subCats);
            }
        }
    }

    private setIsActiveForSiblingsAndChildren(node: CategoryItemSelectorBrief, nodes: Array<CategoryItemSelectorBrief> = this.categories): void {
        const parent = this.findParentNode(node, nodes);

        if (parent) {
            for (const sibling of parent.subCats || []) {
                // Nastavíme isActive na false pro všechny sourozence
                if (sibling.id !== node.id) {
                    sibling.isActive = false;
                }
            }
        } else {
            // Pokud uzel nemá rodiče (je na nejvyšší úrovni), nastavíme isActive na false
            for (const sibling of nodes || []) {
                if (sibling.id !== node.id) {
                    sibling.isActive = false;
                }
            }
        }
        // Rekurzivně procházíme potomky a nastavujeme isActive na false
        for (const child of node.subCats || []) {
            child.isActive = false;
            this.setIsActiveForSiblingsAndChildren(child, node.subCats || []);
        }
    }

    // Pomocná metoda pro nalezení rodičovského uzlu
    private findParentNode(node: CategoryItemSelectorBrief, nodes: Array<CategoryItemSelectorBrief> = this.categories): CategoryItemSelectorBrief {
        for (const parentNode of nodes) {
            if (parentNode.subCats && parentNode.subCats.includes(node)) {
                return parentNode;
            }
            if (parentNode.subCats) {
                const foundParent = this.findParentNode(node, parentNode.subCats);
                if (foundParent) {
                    return foundParent;
                }
            }
        }
        return null;
    }
}
