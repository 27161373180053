import {Component, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import {loadFromSession, saveToSession} from "../../helpers/cookie.helper";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'cmp-notify-stripe',
    templateUrl: '../../tpl/notify-stripe.html',
    styleUrls: ['../../assets/styles/2-components/notify-stripe.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotifyStripeComponent extends Translatable {
    html: string | SafeHtml;
    showNotification: boolean = false;
    notifyStripeDismissed: boolean = false;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private sanitizer: DomSanitizer, private http: HttpClient) {
        super(dataSvc, seSvc);
        this.notifyStripeDismissed = loadFromSession('notifyStripeDismissed');
    }

    ngOnInit(): void {
        if (!this.notifyStripeDismissed) {
            this.getData();
        } else {
            setTimeout(() => {
                this.initNotifyStripeHeightVar();
            });
        }
    }

    hide(): void {
        this.showNotification = false;
        saveToSession('notifyStripeDismissed', true);

        setTimeout(() => {
            this.initNotifyStripeHeightVar();
        },100);
    }

    getData(): void {
        this.http.get<string>('api/settings/getNotifyStripe')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                if (res) {
                    this.showNotification = true;
                    this.html = this.sanitizer.bypassSecurityTrustHtml(<string>res);
                } else {
                    this.showNotification = false;
                    this.html = null;
                }
                setTimeout(() => {
                    this.initNotifyStripeHeightVar();
                },100);
            });
    }

    initNotifyStripeHeightVar() {
        let el = document.querySelector("html");
        let notifyStripeHeight = document.querySelector('.notify-stripe')?.clientHeight;
        el.style.setProperty('--notifyStripeHeight', (notifyStripeHeight ?? '0') + 'px');
    }

}
