<div class="product-list product-list--swiper product-list--box" id="{{productCarouselSource}}" *ngIf="viewType === CarouselViewType.ProductBox && items.length > 0">
    <div class="swiper-nav swiper-nav--prev">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-left" />
            </svg>
        </div>
    </div>

    <div class="swiper-nav swiper-nav--next">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-right" />
            </svg>
        </div>
    </div>

    <swiper [config]="config"
            [autoplay]="{delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true}"
            [pagination]="{clickable: true, type: 'bullets'}"
            [navigation]="{
                    nextEl: '.product-list .swiper-nav--next',
                    prevEl: '.product-list .swiper-nav--prev'
                }">
        <ng-template swiperSlide *ngFor="let product of items;">
            <cmp-product-box [fullContent]="fullContent" [showFlags]="showFlags" [productboxproduct]="product | ProductSelectorToProductBox">
            </cmp-product-box>
        </ng-template>
    </swiper>
</div>

<div class="product-list product-list--swiper product-list--box-mini" id="{{productCarouselSource}}" *ngIf="viewType === CarouselViewType.MiniProductBox && items.length > 0">
    <div class="swiper-nav swiper-nav--prev">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-left" />
            </svg>
        </div>
    </div>

    <div class="swiper-nav swiper-nav--next">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-right" />
            </svg>
        </div>
    </div>

    <swiper [config]="config"
            [autoplay]="{delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true}"
            [pagination]="{clickable: true, type: 'bullets'}"
            [navigation]="{
                nextEl: '.product-list .swiper-nav--next',
                prevEl: '.product-list .swiper-nav--prev'
            }"
            [breakpoints]="{
                '480': {slidesPerView: 2, spaceBetween: 16},
                '768': {slidesPerView: 3, spaceBetween: 20},
                '1200': {slidesPerView: 4, spaceBetween: 32}
            }"
    > <!-- This is a configuration workaround for modal/cart to make the swiper working at init and dynamically. -->
        <ng-template swiperSlide *ngFor="let product of items;">
            <cmp-mini-product-box [fromCart] = fromCart [fullContent]="fullContent" [showFlags]="showFlags" [productboxproduct]="product | ProductSelectorToProductBox">
            </cmp-mini-product-box>
        </ng-template>
    </swiper>
</div>
