import {Injectable} from "@angular/core";
import {AccountMenuItems, IAccountMenuItem} from "./common";
import {Router} from "@angular/router";
import {IdentityService} from "../../services/identity.service";

@Injectable()
export class AccountMenuItemsService {

    private _accountMenuItems: Array<IAccountMenuItem> = null;
    get accountMenuItems(): Array<IAccountMenuItem> {
        if (!this._accountMenuItems) {
            return this._accountMenuItems;
        }

        const ret: Array<IAccountMenuItem> = [];
        for (let i = 0; i < this._accountMenuItems.length; i++) {
            const item = this._accountMenuItems[i];
            if (this.canBeShown(item)) {
                ret.push(item);
            }
        }

        return ret;
    }

    get activeItem(): AccountMenuItems {

        if (!this.accountMenuItems) {
            return null;
        }
        for (let i = 0; i < this.accountMenuItems.length; i++) {
            const item = this.accountMenuItems[i];
            if (item.routerLink == this.router.url) {
                return item.sideItem;
            }
        }
        return null;
    }

    //private identity: IIdentity = null;

    constructor(private router: Router,
                private idSvc: IdentityService
    ) {

        // idSvc.Refresh();
        // idSvc.InitRights();

        // idSvc.GetIdentity().pipe(take(1)).subscribe(resIdentity => {
        //     this.identity = resIdentity;
        // });

        this._accountMenuItems = [
            {
                sideItem: AccountMenuItems.account_order_list,
                routerLink: '/muj-ucet/objednavky/',
                iconActive: 'assets/img/account/order-list-active.svg',
                iconNotActive: 'assets/img/account/order-list.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_invoice_list,
                routerLink: '/muj-ucet/faktury/',
                iconActive: 'assets/img/account/invoices-active.svg',
                iconNotActive: 'assets/img/account/invoices.svg',
                isShownInTop: true,
                isShownInSide: true
            },{
                sideItem: AccountMenuItems.account_delivery_note_list,
                routerLink: '/muj-ucet/dodaci-listy/',
                iconActive: 'assets/img/account/delivery-note-list-active.svg',
                iconNotActive: 'assets/img/account/delivery-note-list.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_settings,
                routerLink: '/muj-ucet/nastaveni',
                iconActive: 'assets/img/account/settings-active.svg',
                iconNotActive: 'assets/img/account/settings.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_collaborators,
                routerLink: '/muj-ucet/spolupracovnici',
                iconActive: 'assets/img/account/collaborators.svg',
                iconNotActive: 'assets/img/account/collaborators.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_profile,
                routerLink: '/muj-ucet/osobni-udaje',
                iconActive: 'assets/img/account/user-edit-active.svg',
                iconNotActive: 'assets/img/account/user-edit.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_b2b_invoice_data,
                routerLink: '/muj-ucet/fakturacni-udaje',
                iconActive: 'assets/img/account/user-edit-active.svg',
                iconNotActive: 'assets/img/account/user-edit.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_delivery_addresses,
                routerLink: '/muj-ucet/dodaci-adresy',
                iconActive: 'assets/img/account/delivery-addresses-active.svg',
                iconNotActive: 'assets/img/account/delivery-addresses.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_wishlists,
                routerLink: '/muj-ucet/seznamy-prani',
                iconActive: 'assets/img/account/wishlists-active.svg',
                iconNotActive: 'assets/img/account/wishlists.svg',
                isShownInTop: true,
                isShownInSide: true
            }
        ];
        this.checkItems();
    }


    private checkItems(): void {
        if (!this.accountMenuItems) {
            return
        }
        for (let i = 0; i < this.accountMenuItems.length; i++) {
            const item = this.accountMenuItems[i];
            if (item.routerLink.endsWith('/')) {
                item.routerLink = item.routerLink.substr(0, item.routerLink.length - 1);
            }
        }
    }

    private canBeShown(item: IAccountMenuItem): boolean {
        if(item.sideItem === AccountMenuItems.account_invoice_list && !this.idSvc.IsInvoiceAllowed
           || item.sideItem === AccountMenuItems.account_delivery_note_list && !this.idSvc.IsDeliveryNoteAllowed
           || item.sideItem === AccountMenuItems.account_collaborators && !this.idSvc.IsCollaboratorsAllowed
           || item.sideItem === AccountMenuItems.account_order_list && !this.idSvc.IsOrderListAllowed
           || item.sideItem == AccountMenuItems.account_settings && !this.idSvc.IsSettingsAllowed
           || item.sideItem == AccountMenuItems.account_b2b_invoice_data && !this.idSvc.IsEditB2bInvoiceDataAllowed
           || item.sideItem == AccountMenuItems.account_profile && !this.idSvc.IsEditB2cUserAllowed
           || item.sideItem == AccountMenuItems.account_delivery_addresses && !this.idSvc.IsDeliveryAddressesAllowed
        )
        {
            return false;
        }

        //here you can implement yet another filtering logic based on actual weather or human rights or your wife's mood
        return true;
    }
}