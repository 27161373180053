import {OnDestroy, OnInit, Directive} from "@angular/core";
import {DataService} from "../services/data.service";
import {SettingsService} from "../services/settings.service";
import {ICartProductItem, IPriceInfo, ProductSelector, ProductVariantDetailSelector} from "../modules/product/common";
import {SafeHtml} from "@angular/platform-browser";
import {CredentialStorage} from "../services/credential-storage.service";
import {SeoInfoSelector} from "../modules/category/interfaces";
import {HttpErrorResponse} from "@angular/common/http";
import {Subject} from "rxjs";
import {CartSelector} from "../modules/cart/common";
import {loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {IAvailability} from "../modules/availability/commons/commons";
import {AbstractControl, FormArray, FormGroup} from "@angular/forms";

declare let sha256: any;

/**
 * @description
 * A class for storing authorization data in localStorage
 * Can be instantiated via:
 * - new ai()
 * - ai.fromString()
 * */
export interface AuthInfo {
    userId: number;
    companyId: number;
    userName: string;
    displayName: string;
    loggedIn: boolean;
    validTo: string;
    newsletterSubscribed: boolean;
    isB2B: boolean;
}

/**
 * @description
 * Interfaces for passing proper request values between function calls
 * Just to have one param instead of 3 or more
 */
export interface SettingItem {
    Key: string;
    Value: any;
}

export interface UserInfo {
    userDisplayName: string;
}

/**
 * @description
 * To have some structure of the emitted object to count with
 * Possible values for "type" are:
 * 'info'
 * 'error'
 */
export interface LoginEmitterMessage {
    type: string;
    message: string;
    status?: number;
    data?: HttpErrorResponse;
    routeToIndex?: boolean;
}

export interface CategoryItemSelector extends CategoryItemSelectorBrief{
    idPath: string;
    seoUrl: string;
    imagePath: string;
    imageAlt: string;
    description: string | SafeHtml;
    bcInfo: BreadCrumbInfo[];
    articles: ArticleBriefSelector[];
    subcategories: CategoryItemSelector[];
    hasSubcategories:boolean;
    catSeoInfo: SeoInfoSelector;
    zboziCategoryText: string;
}

export interface CategoryItemSelectorBrief {
    id: number;
    parentId: number;
    level: number;
    displayName: string;
    idPath: string;
    seoUrl: string;
    subCats: CategoryItemSelectorBrief[];
    hasSubCats: boolean;
    isActive?: boolean;
}

/**
 * @description
 * Defines culture properties
 */
export interface Culture {
    code: string;
    name: string;
    cultureId: number;
    currencyCode: string;
    currencySymbol: string;
    translationKey: string;
    htmlLangAttribute: string;
}

export interface Country {
    id: number;
    name: string;
}

export interface ArticleBaseSelector {
    name: string;
    seoUrl: string;
    url: string;
}

export interface ArticleBaseWithChildrenSelector extends ArticleBaseSelector {
    childArticles: Array<ArticleBaseWithChildrenSelector>;
}

export interface ArticleBriefSelector extends ArticleBaseWithChildrenSelector {
    articleId: number;
    order: number;
    code: string;
    dateCreated: any;
    imagePath: string;
    imageTitle: string;
    annotation: string | SafeHtml;
    childArticles: Array<ArticleBriefSelector>;
    linkText?: string;
    parentName?: string;
}

export interface ArticleSelector extends ArticleBriefSelector {
    title: string;
    body: string | SafeHtml;
    extraData: string;
    dateLastUpdated?: any;
    seoDescription: string;
    creatorUserName: string;
    lastUpdatedUserName: string;
    articleIDPath: string;
    isSiteMap: boolean;
    breadCrumbInfo?: BreadCrumbInfo[];
    productIDs?: number[];
}

export interface SearchRequest {
    phrase: string;
    pageSize: number;
    pageIndex: number;
    forSuggest: boolean;
}

export interface SearchSelector {
    categories: CategoryItemSelector[];
    products: Array<ProductSelector>;
    totalProductCount: number;
}

export interface ISearchRequest extends PageRequest {
    Expression: string;
    OrderBy: string;
}

export interface ISearchResult {
    Products: ProductSelector[];
    ProductCount: number;
    Categories: CategoryItemSelector[];
    CategoryCount: number;
    Articles: ArticleBriefSelector[];
    ArticleCount: number;
}

/**
 * Collection of available validation patterns used in Settings interface
 */
export interface ValidationPatterns {
    email: RegExp;
    phone: IntRegExpDictionary;
    naturalNumber: RegExp;
    decimalNumber: RegExp;
    formattedNaturalNumber: RegExp;
    zipCodeCz: RegExp;
}

export interface DecimalSettings {
    ProductBox: number;
    Detail: number;
    Wishlist: number;
    Basket: number;
    Compare: number;
    Whisperer: number;
}

export interface CurrencyDict {
    [key: number]: string;
}

/**
 * @description
 * Structure of the application settings which are stored in app/services/settings.service.ts.
 * This enables usage of settings via dot convention both in ts and html files.
 * If you add a setting to the json file, please add it to this interface as well.
 */
export interface Settings {
    preAuth: boolean;
    preAuthToLocal: boolean; // this makes the pre-authentication less agressive but less secure
    preAuthLocalExpiration: number;
    cultures: Array<Culture>;
    currencies: CurrencyDict;
    currencyCodes: CurrencyDict;
    validationPatterns: ValidationPatterns;
    countries: Country[];
    pageSizes: any[];
    localDomain: string;
    currentServerUrl: string;
    decimalSettings: DecimalSettings;
    shopSeo: ShopSeoSelector;
    routesWithDefaultSeo: any[];
    assetPathPrefix: string;
    newImageServerUrl: string;
    noIndexRoutes: string[];
    termsAndConditions: { [key: number]: TermsAndConditionsSelector }
}

/**
 * @description
 * used to interchange info between settings service and auth.interceptor
 * for setting HttpHeaders
 */
export interface HttpRegionalSettings {
    cultureId: number;
    currencyId: number;
    comAllowed: boolean;
}

export interface StringIndexedObject<t> {
    [key: string]: t;
}

export interface NumberIndexedObject {
    [key: number]: any;
}

export interface IntRegExpDictionary {
    [key: number]: RegExp;
}

(<any>window).DebugOn = () => {
    saveToSession('isDebug', true);
    Translatable.isDebug = true;
}

(<any>window).DebugOff = () => {
    saveToSession('isDebug', false);
    Translatable.isDebug = false;
}

/**
 * Base class for all components which use translations
 * translationPrefix is used to distinguish translations for particular routes (not to take them all from db)
 */
@Directive()
export abstract class Translatable implements OnInit, OnDestroy {

    sen: StringIndexedObject<string> = {};

    public s(key: string): string {
        return this.sen[key] || `<<${key}>>`;
    }

    protected _userLoggedIn: boolean;
    protected unsubscribe: Subject<void> = new Subject<void>();
    public imageServerUrl: string;

    protected constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        this.sen = this.seSvc.sen;
        this.imageServerUrl = this.seSvc.settings.newImageServerUrl;
    }

    get userLoggedIn(): boolean {
        return CredentialStorage.userLoggedIn;
    }

    get isUserB2B(): boolean {
        return CredentialStorage.isUserB2B;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public Min(a: number, b: number): number {
        return a < b ? a : b;
    }

    public static isDebug: boolean = null;

    get IsDebug(): boolean {
        if (Translatable.isDebug === null) {
            Translatable.isDebug = loadFromSession('isDebug') || false;
        }

        return Translatable.isDebug;
    }

    public ConsoleWrite(obj: any): void {
        if (Translatable.isDebug) {
            console.log(obj);
        }
    }

    public ArrayMin(arr: number[]): number {
        return arr.reduce(function (p, v) {
            return (p < v ? p : v);
        });
    }

    public ArrayMax(arr: number[]): number {
        return arr.reduce(function (p, v) {
            return (p > v ? p : v);
        });
    }

    public GetArray(f: AbstractControl): FormArray {
        return <FormArray>f;
    }

    public GetGroup(f: AbstractControl): FormGroup {
        return <FormGroup>f;
    }
}

export interface BreadCrumbInfo {
    displayName: string;
    targetId?: number;
    targetSeoTail?: string;
    entityPrefix?: string;
    categoryIdPath?: string;
}

export interface LoginResult {
    success: boolean;
    cultureId: number;
    currencyId: number;
    companyDisplayName: string;
    tokenExpirationMinutes: string;
    cartTokens: Array<CartTokenSelector>;
    newsletterSubscribed: boolean;
    userName: string;
    reqInfo: boolean;
    isB2B: boolean;
    mergeInfo: IMergeInfo;
    userId: number;
    companyId: number;
}

export interface IMergeInfo {
    CurrentCartProducts: ICartProductItem[];
    PreviousCartProducts: ICartProductItem[];
}

export interface CartTokenSelector {
    userId: number;
    cartToken: string;
    cartName: string;
    created: Date;
    order: number;
    isCurrent: boolean;
}

export interface PreAuthRequest {
    userName: string;
    password: string;
    validTo: any;
}

export interface PreAuthResponseModel {
    success: boolean;
}

export interface CategoryInfo {
    id: number;
    name: string;
    seoUrl: string;
}

export function mapPrices(thePrices: IPriceInfo, theProduct: ProductVariantBriefSelector) {

    theProduct.priceWithVAT = thePrices.PriceWithVAT;
    theProduct.priceWithoutVAT = thePrices.PriceWithoutVAT;
    theProduct.basePrice = thePrices.BasePrice;
}

export enum AccessLevelType {
    OnlyNecessary = 0,
    ProductDetail = 1,
    Catalog = 2
}

export interface ProductVariantBriefSelector {
    id: number;
    code: string;
    ean: string;
    qtyInStock?: number;
    availability: IAvailability;
    name: string;
    annotation: string;
    description: string | SafeHtml;
    disabled: boolean;
    discontinued: boolean;
    priceWithVAT?: number;
    priceWithoutVAT?: number;
    basePrice?: number;
    basePriceWithoutVat?: number;
    discount?: number | string;
    imagePath?: string;
    imageTitle?: string;
    commonImagePath?: string;
    commonImageTitle?: string;
    seoUrl?: string;
    producerId: number;
    producerName?: string;
    producerSeoUrl: string;
    isAction?: boolean;
    isNew?: boolean;
    isSellOut?: boolean;
    isRecommended?: boolean;
    unit: UnitSelector;
    order?: number | string;
    breadcrumbInfo: BreadCrumbInfo[];
    nearestCategory?: CategoryInfo;
    langId?: number;
    ranking: number;
    isPhrase: boolean;
    vat: number;
    categoryIdPath?: string;
    minQtyOrder: number;
    maxQtyOrder: number;
    factor: number;
    variantKey: number;
    commonName: string;
    commonAnnotation: string;
    commonDescription: string;
    //commonSeoUrl:string;
    accessLevel: AccessLevelType;
    confirmed: boolean;
}

export interface IQuickOrderProduct extends ProductVariantBriefSelector {
    Count?: number;
}


/**
 * @description Represents a range of values for the comparison 'the value is >= and <='.
 */
export interface ValueRangeParameter {
    id: number;
    fromValue: number;
    toValue: number;
}

/**
 * @description Represents a parameter value for the equality comparison of product parameters.
 */
export interface ExactValueParameter {
    id: number;
    valueId: number;
}

/**
 * @description Represents the filter according to parameter values of the products.
 */
export class ParametricFilterRequestInitializer {
    exactValueParameters: ExactValueParameter[] = [];
    valueRangeParameters: ValueRangeParameter[] = [];
}

export class ParametricFilterRequest extends ParametricFilterRequestInitializer {

    static url_exactValueParam = 'param_ev';
    static url_valueRangeParam = 'param_rv';

    constructor(initializer: ParametricFilterRequestInitializer) {
        super();
        if (initializer) {
            this.exactValueParameters = initializer.exactValueParameters;
            this.valueRangeParameters = initializer.valueRangeParameters;
        }
    }

    get paramsHash(): string {
        return sha256([...this.exactValueParameters, ...this.valueRangeParameters].join('|'));
    }

    /**
     * @description Adds a condition for the equality comparison to the parametric filter.
     */
    addExactValue(id: number, valueId: number) {
        if (this.exactValueParameters && this.exactValueParameters.find(evp => evp.id == id && evp.valueId == valueId)) {
            return;
        }

        const exactValue: ExactValueParameter = {
            id: id,
            valueId: valueId
        };
        this.exactValueParameters.push(exactValue);
    }

    /**
     * @description Adds a condition for the comparison 'the value is >= and <=' to the parametric filter.
     */
    addValueRange(id: number, fromValue: number, toValue: number) {
        let from = null;
        if (fromValue !== undefined && fromValue !== null) {
            from = parseFloat(fromValue.toString());
            if (isNaN(from)) {
                from = null
            }
        }

        let to = null;
        if (toValue !== undefined && toValue !== null) {
            to = parseFloat(toValue.toString());
            if (isNaN(to)) {
                to = null
            }
        }

        const range: ValueRangeParameter = {
            id: id,
            fromValue: from,
            toValue: to
        };
        this.valueRangeParameters.push(range);
        this.valueRangeParameters = this.valueRangeParameters.filter(vr => vr.fromValue != null || vr.toValue != null);
    }

    /**
     * @description Clears all conditions for the parametric filter and sets state 'no filtering'.
     */
    clear(): void {
        this.valueRangeParameters.splice(0);
        this.exactValueParameters.splice(0);
    }

    isExactValueInFilter(paramId: number, valueId: number): boolean {
        if (!this.exactValueParameters || !this.exactValueParameters.length) {
            return false;
        }

        let foundExactValue = this.exactValueParameters.find(p => p.id == paramId && p.valueId == valueId);

        return !!foundExactValue;

    }


    isRangeParamInFilter(paramId: number): ValueRangeParameter {
        if (!this.valueRangeParameters || !this.valueRangeParameters.length) {
            return null;
        }

        let foundRangeParam = this.valueRangeParameters.find(p => p.id == paramId);

        return foundRangeParam;
    }


    public static getParamsFilterInitializerFromUrlParams(queryParams: StringIndexedObject<string | Array<string> | number | Array<number>>): ParametricFilterRequestInitializer {
        if (!queryParams) {
            return null;
        }

        const ret: ParametricFilterRequestInitializer = new ParametricFilterRequestInitializer();
        for (const queryParamsKey in queryParams) {
            if (queryParamsKey == ParametricFilterRequest.url_exactValueParam) {
                ParametricFilterRequest.fillExactValuesFromUrlParam(ret, queryParams[queryParamsKey]);
            }
            if (queryParamsKey == ParametricFilterRequest.url_valueRangeParam) {
                ParametricFilterRequest.fillRangeValuesFromUrlParam(ret, queryParams[queryParamsKey]);
            }
        }
        return ret;
    }

    private static fillExactValuesFromUrlParam(
        initializerToBeFilled: ParametricFilterRequestInitializer,
        queryParam: string | Array<string> | number | Array<number>) {
        if (!initializerToBeFilled || !queryParam) {
            return;
        }

        if (!Array.isArray(queryParam)) {
            queryParam = [queryParam.toString()];
        }

        if (!initializerToBeFilled.exactValueParameters) {
            initializerToBeFilled.exactValueParameters = [];
        }

        for (let i = 0; i < queryParam.length; i++) {
            const param = queryParam[i].toString();
            const splitted = param.split('_');
            initializerToBeFilled.exactValueParameters.push({id: Number(splitted[0]), valueId: Number(splitted[1])});
        }
    }

    private static fillRangeValuesFromUrlParam(
        initializerToBeFilled: ParametricFilterRequestInitializer,
        queryParam: string | Array<string> | number | Array<number>) {
        if (!initializerToBeFilled || !queryParam) {
            return;
        }
        if (!Array.isArray(queryParam)) {
            queryParam = [queryParam.toString()];
        }

        if (!initializerToBeFilled.valueRangeParameters) {
            initializerToBeFilled.valueRangeParameters = [];
        }

        for (let i = 0; i < queryParam.length; i++) {
            const param = queryParam[i].toString();
            const splitted = param.split('_');
            initializerToBeFilled.valueRangeParameters.push({
                id: Number(splitted[0]),
                fromValue: Number(splitted[1]),
                toValue: Number(splitted[1])
            });
        }

    }

    public convertToUrlParams(queryParamsToBeFilled: StringIndexedObject<string | Array<string> | number | Array<number>>): void {
        queryParamsToBeFilled[ParametricFilterRequest.url_exactValueParam] = null;
        queryParamsToBeFilled[ParametricFilterRequest.url_valueRangeParam] = null;
        ParametricFilterRequest.serializeExactValueParamsToQueryParams(this.exactValueParameters, <StringIndexedObject<Array<string>>>queryParamsToBeFilled);
        ParametricFilterRequest.serializeValueRangeParamsToQueryParams(this.valueRangeParameters, <StringIndexedObject<Array<string>>>queryParamsToBeFilled);
    }

    private static serializeExactValueParamsToQueryParams(
        exactValueParameters: ExactValueParameter[], queryParams: StringIndexedObject<Array<string>>) {
        if (!exactValueParameters || !exactValueParameters.length) {
            return;
        }

        if (queryParams[ParametricFilterRequest.url_exactValueParam] && !Array.isArray(queryParams[ParametricFilterRequest.url_exactValueParam])) {
            queryParams[ParametricFilterRequest.url_exactValueParam] = [<any>queryParams[ParametricFilterRequest.url_exactValueParam]];//hack, sorry
        }

        if (!queryParams[ParametricFilterRequest.url_exactValueParam]) {
            queryParams[ParametricFilterRequest.url_exactValueParam] = [];
        }

        exactValueParameters.forEach((ev) => {
            const ser = `${ev.id}_${ev.valueId}`;
            queryParams[ParametricFilterRequest.url_exactValueParam].push(ser);
        });
    }

    private static serializeValueRangeParamsToQueryParams(
        valueRangeParameters: ValueRangeParameter[],
        queryParams: StringIndexedObject<Array<string>>
    ) {
        if (!valueRangeParameters || !valueRangeParameters.length) {
            return;
        }

        if (queryParams[ParametricFilterRequest.url_valueRangeParam] && !Array.isArray(queryParams[ParametricFilterRequest.url_valueRangeParam])) {
            const tmp = <any>queryParams[ParametricFilterRequest.url_valueRangeParam];//hack, sorry
            queryParams[ParametricFilterRequest.url_valueRangeParam] = [tmp];
        }

        if (!queryParams[ParametricFilterRequest.url_valueRangeParam]) {
            queryParams[ParametricFilterRequest.url_valueRangeParam] = [];
        }

        valueRangeParameters.forEach((rv) => {
            const ser = `${rv.id}_${rv.fromValue}_${rv.toValue}`;
            queryParams[ParametricFilterRequest.url_valueRangeParam].push(ser);
        });
    }
}

export interface ErrorSelector {
    message?: string;
    subject?: string;
}

export interface PagedResponse<T> {
    total: number;
    data: T[];
}

export interface ShopSeoSelector {
    ShopName: string;
    ShopTitle: string;
    ShopDescription: string;
}

// export interface AvailabilitySelector {
//     id: number;
//     order: number;
//     name: string;
//     jsonLd: string;
//     isBuyable: boolean;
//     showProductOnShop: string;
//     exportToXmlFeeds: boolean;
// }

export interface GaSelector {
    analyticsId: string;
    adsId: string;
    adsLabel: string;
    remarkId: string;
    gtmId: string;
}

export interface FbSelector {
    applicationId: string;
    applicationToken: string;
}

export interface SeznamSelector {
    sklikConversionId: string;
    sklikRetargetingId: string;
    zboziConversionBranchId: string;
}

export interface HeurekaSelector {
    publicKey: string;
}

export interface UnitSelector {
    id: number;
    shortCut: string;
}

export interface BrandSelector {
    logoFirst: string;
    logoSecond: string;
    favicon: string;
}

export interface SoftErrorData {
    initialUrl: string;
    redirectedUrl: string;
}

export interface DomainSelector {
    id: number;
    url: string;
    cultureId: number;
    currencyId: number;
}

export interface DomainSelectorExtended extends DomainSelector {
    cultureKey: string;
    currencyKey: string;
}

export interface TermsAndConditionsSelector {
    termsUrl: string;
    gdprUrl: string;
}

export interface PageRequest {
    PageSize: number;
    PageIndex: number;
}

export interface PageResult<T> {
    Items: T[];
    Count: number;
}

export interface CurrencySelector {
    Id: number,
    Name: string,
    DisplayName: string,
    Code: string,
    RoundTo: number,
    Enabled: boolean,
    FormatString: string,
    IsMain: boolean
}

export interface CopyrightSelector {
    cultureId: number;
    text: string;
}

export interface IAddWishListProductToCartRequest {
    WishListHash: string;
}

export interface IAddOrderProductToCartRequest {
    OrderNumber: string;
}

export interface IAddWishListProductToCartResult {
    Cart: CartSelector;
    ImportedProducts: ProductVariantDetailSelector[];
    NotImportedProducts: ProductVariantDetailSelector[];
}

export interface IAddOrderProductToCartResult {
    Cart: CartSelector;
    ImportedProducts: ProductVariantDetailSelector[];
    NotImportedProducts: ProductVariantDetailSelector[];
}

export interface IFilterItem<T> {
    Id: T;
    Label: string;
    IsDisabled: boolean;
    Count: number;
}

export interface UserTokenLoginRequest {
    token: string;
}


export interface UserTokenLoginSelector {
    login: string;
    psw: string
}
export enum GtmPageType {/*
            Homepage - hlavní stránka
            Category - kategorie
            Detail – detail produktu
            Cart - stránka košíku
            Purchase - děkovná stránka
            Search - stránka s výsledky vyhledávání
            User - stránky uživatelského účtu
            Other - vše ostatní */
    homepage = 'homepage',
    category = 'category',
    detail = 'detail',
    cart = 'cart',
    purchase = 'purchase',
    search = 'search',
    user = 'user',
    other = 'other'
}