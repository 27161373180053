import {Component, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {GeneralDialogService} from "../../general-dialog/general-dialog.service";
import {AvailabilityComponent} from "../availability/availability.component";

@Component({
    selector: 'cmp-availability-ext',
    templateUrl: './availability-ext.component.html',
    styleUrls: ['../../../assets/styles/2-components/availability-ext.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AvailabilityExtComponent extends AvailabilityComponent {

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        dialogSvc: GeneralDialogService
    ) {
        super(dataSvc, seSvc, dialogSvc);
    }

    ngOnInit(): void {
    }

}
