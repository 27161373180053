import {Component} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {finalize, take, takeUntil} from "rxjs/operators";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {FormUtils} from "../../../helpers/FormUtils";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {IQuestionFormRequest} from "../commons/common";
import {QuestionService} from "../services/question.service";
import {UserService} from "../../../services/user.service";

@Component({
    selector: 'cmp-question-form',
    templateUrl: './question-form.html'
})
export class QuestionFormComponent extends Translatable {

    form: FormGroup;
    resultState: boolean = null;
    termsAndConditionsMessage: SafeHtml;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private questionSvc: QuestionService,
        private userSvc: UserService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.CreateTermAndConditionsText();

        this.CreateForm();

        if (this.userLoggedIn) {
            this.TryFillUserData();
        }
    }

    private TryFillUserData(): void {
        this.userSvc.loaded
            .pipe(takeUntil(this.unsubscribe))
            .pipe(take(1))
            .subscribe(() => {
                this.form.patchValue({
                    Email: this.userSvc?.user?.email,
                    Name: (this.userSvc?.user?.firstName + ' ' + this.userSvc?.user?.lastName).trim()
                });
            });

        this.userSvc.reloadUserContactInfo();
    }

    private CreateTermAndConditionsText(): void {
        let sen: string = this.s('app-terms-and-conditions');
        const tandc = this.seSvc.settings.termsAndConditions[this.seSvc.culture.cultureId]?.termsUrl;

        if (sen && tandc) {
            sen = sen.replace('[/a]', '</a>').replace('[a]', `<a href="${tandc}" rel="noopener" target="_blank">`);
            this.termsAndConditionsMessage = this.sanitizer.bypassSecurityTrustHtml(sen);
        }
    }

    public CreateForm(): void {
        this.form = this.fb.group({
            Subject: [null, [Validators.required]],
            Content: [null, [Validators.required]],
            Email: [null, [Validators.pattern(this.seSvc.settings.validationPatterns.email), Validators.required]],
            Name: [null, [Validators.required]]
        });
    }

    public OnSendClick(): void {
        FormUtils.MakeDirtyGroup(this.form);
        FormUtils.MakeTouchedGroup(this.form);

        if (this.form.valid) {
            const data: IQuestionFormRequest = {
                Email: this.form.get('Email').value,
                Name: this.form.get('Name').value,
                Subject: this.form.get('Subject').value,
                Content: this.form.get('Content').value,
            };

            this.dataSvc.dataLoading = true;
            this.questionSvc.SendQuestion(data)
                .pipe(takeUntil(this.unsubscribe))
                .pipe(finalize(() => {
                    this.dataSvc.dataLoading = false;
                }))
                .subscribe((val) => {
                    this.resultState = val;
                    if (val) {
                        this.ResetForm();
                    }
                });
        }
    }

    private ResetForm(): void {
        this.form.reset();
    }
}
