<div class="cookies-panel" *ngIf="showWarning && !isCookiesModalVisible">
    <div class="cookies-panel__header">
        <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/img-cookies.svg'" alt="Cookies">

        <div class="cookies-panel__title heading-h1">
            {{sen['cookies-panel--title']}}
        </div>
    </div>

    <div class="cookies-panel__text">
        <div [innerHTML]="cookiesConsentTexts?.consentText"></div>
    </div>

    <div class="cookies-panel__actions">
        <button class="btn btn--medium btn--conversion" (click)="OnOkButtonClick()">
            {{sen['cookies-panel--button-ok']}}
        </button>

        <button class="btn btn--medium btn--conversion" (click)="enableRequired()">
            {{sen['cookies-consent-functional-only']}}
        </button>

        <button class="btn btn--medium btn--secondary" (click)="OnCustomButtonClick()">
            {{sen['cookies-panel--button-custom']}}
        </button>
    </div>
</div>