<div class="modal-buy">
    <div class="modal-buy__title heading-h1">
        {{sen['cart-modal-title-insert']}}
    </div>

    <div class="modal-buy__inner">
        <div class="modal-buy-product">
            <a [routerLink]="['/v/' + cartItem.productId + '/' + cartItem.productDetail?.seoUrl]" (click)="closeCartAddDialog()" class="modal-buy-product__image">
                <picture>
                    <source [srcset]="(productImagePathPrefix + '/cart_product/190/190/' + cartItem.productDetail?.imagePath) | webp" type="image/webp"/>
                    <img [src]="productImagePathPrefix + '/cart_product/190/190/' + cartItem.productDetail?.imagePath" [alt]="cartItem.productDetail?.name"/>
                </picture>
            </a>

            <div class="modal-buy-product__content">
                <a [routerLink]="['/v/' + cartItem.productDetail?.id + '/' + cartItem.productDetail?.seoUrl]" class="modal-buy-product__title" (click)="closeCartAddDialog()">
                    {{cartItem.productDetail?.name}}
                </a>

                <div class="modal-buy-product__amount">
                    {{cartItem.count}} {{cartItem.productDetail?.unit.shortCut}}
                </div>

                <div class="prices">
                    <ng-container *ngIf="isUserB2B">
                        <div class="prices__item prices__item--main">
                            <cmp-price [price]="cartItem.productDetail?.priceWithoutVAT"></cmp-price>
                            <span>{{s('without-vat')}}</span>
                        </div>

                        <div class="prices__item prices__item--base">
                            <cmp-price [price]="cartItem.productDetail?.priceWithVAT"></cmp-price>
                            <span>{{s('with-vat')}}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isUserB2B">
                        <div class="prices__item prices__item--main">
                            <cmp-price [price]="cartItem.productDetail?.priceWithVAT"></cmp-price>
                            <span>{{s('with-vat')}}</span>
                        </div>

                        <div class="prices__item prices__item--base">
                            <cmp-price [price]="cartItem.productDetail?.priceWithoutVAT"></cmp-price>
                            <span>{{s('without-vat')}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-buy__actions">
        <a href="javascript:void(0);" (click)="closeCartAddDialog()" class="btn btn--medium btn--secondary">
            <div class="icon icon--w10">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-left" />
                </svg>
            </div>

            {{sen['cart-back']}}
        </a>

        <a [routerLink]="['/kosik']" (click)="closeCartAddDialog()" class="btn btn--medium btn--conversion">
            {{sen['show-cart']}}

            <div class="icon icon--w10">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right" />
                </svg>
            </div>
        </a>
    </div>
</div>

<div class="modal-buy-related" *ngIf="relatedItems.length > 0">
    <div class="modal-buy-related__title heading-h1">
        {{sen['cart-change-modal--other-also-buy']}}
    </div>

    <div class="modal-buy-related__in">
        <div class="swiper-nav swiper-nav--prev">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-left" />
                </svg>
            </div>
        </div>

        <div class="swiper-nav swiper-nav--next">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-right" />
                </svg>
            </div>
        </div>

        <swiper [config]="swiperConfig" [autoplay]="{delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true}"
                [navigation]="{
                    nextEl: '.modal-buy-related .swiper-nav--next',
                    prevEl: '.modal-buy-related .swiper-nav--prev'
                }"
                [breakpoints]="{
                    '480': {slidesPerView: 2, spaceBetween: 20},
                    '768': {slidesPerView: 3, spaceBetween: 20}
                }"
        > <!-- This is a configuration workaround for modal/cart to make the swiper working at init and dynamically. -->
            <ng-template swiperSlide *ngFor="let product of relatedItems">
                <cmp-product-box [productboxproduct]="product | ProductSelectorToProductBox"></cmp-product-box>
            </ng-template>
        </swiper>
    </div>
</div>
