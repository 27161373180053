import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MenuComponent} from "./menu.component";
import {RouterModule} from "@angular/router";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {ArticleModule} from "../articles/article.module";
import {HeaderOptionModule} from "../header-option/header-option.module";
import {SubMenuComponent} from "./sub-menu.component";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomPipesModule,
        ArticleModule,
        HeaderOptionModule
    ],
    declarations: [
        MenuComponent,
        SubMenuComponent
    ],
    exports: [
        MenuComponent,
        SubMenuComponent
    ]
})

export class MenuModule {
}
