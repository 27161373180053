<ng-container *ngIf="IsDebug">
    <div class="debug-box">
        <div class="debug-box__header" (click)="OnToggleButtonClick()">
            <div class="debug-box__header-title">
                Debug box
            </div>
            <div class="debug-box__header-buttons">
                <button class="debug-box__header-button">
                    &#709;
                </button>
            </div>
        </div>
        <div class="debug-box__content" [ngClass]="{'debug-box__content--visible': isVisible}">
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>