<div class="newsletter">
	<div class="container">
		<div class="newsletter__in">
			<div class="newsletter__left">
				<img class="newsletter__image" [src]="seSvc.settings.assetPathPrefix + '/assets/img/img-newsletter.svg'" alt="Newsletter" width="64" height="64" />

				<div class="newsletter__heading">
					<div class="newsletter__text">
						{{sen['subscribe-newsletter-first-row']}}
					</div>

					<div class="newsletter__title heading-h3">
						{{sen['subscribe-newsletter-second-row']}}
					</div>
				</div>
			</div>

			<div class="newsletter__right">
				<ng-container *ngIf="regStatus.status === 'None'; else elseBlock" [formGroup]="newsForm">
					<div class="newsletter__form">
						<div class="newsletter__icon">
							<div class="icon">
								<svg>
									<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#email" />
								</svg>
							</div>
						</div>

						<input type="text" class="newsletter__input" [placeholder]="'Zadejte Váš @ e-mail'" formControlName="email" (keyup)="inputKeyUp($event)">

						<button class="newsletter__btn btn btn--primary btn--medium" [disabled]="newsForm.invalid" (click)="executeCaptcha()">
							{{sen['subscribe-newsletter']}}
						</button>
					</div>

					<div class="newsletter__policy">
						<div class="form-checkbox">
							<input type="checkbox" id="gdprNews" formControlName="gdprNews">

							<label for="gdprNews">
								{{sen['newsletter-gdpr-agreement']}}
								<a [href]="gdprUrl" target="_blank" rel="noopener">
									{{sen['newsletter-gdpr-agreement-2']}}
								</a>
							</label>

							<div class="validation" *ngIf="newsForm.controls['gdprNews'].invalid && newsForm.controls['email'].valid">
								<cmp-val-msg [msg]="sen['reg-invalid-gdpr']"></cmp-val-msg>
							</div>
						</div>
					</div>

					<div class="validation" *ngIf="newsForm.controls['email'].invalid && newsForm.controls['email'].dirty && newsForm.controls['email'].touched">
						<cmp-val-msg [msg]="sen['reg-invalid-email']"></cmp-val-msg>
					</div>
				</ng-container>

				<ng-template #elseBlock>
					<div class="alert" [ngClass]="{'alert--success': !isFailure, 'alert--error': isFailure}">
						{{statusMsg}}
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>