export class CultureFactory {
    GetCulture(): number {
        const userLang = navigator.language || (<any>navigator).userLanguage;
        let cultureIdDefault: number = null;

        switch (userLang) {
            case 'cs-CZ':
            case 'cs':
                cultureIdDefault = 34;
                break;
            default:
                cultureIdDefault = 34;
                break;
        }

        return cultureIdDefault;
    }
}