import {Pipe, PipeTransform} from '@angular/core';
import {IProductBoxModel, ProductSelector} from "../../product/common";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'ProductSelectorToProductBox'
})
export class ProductSelectorToProductBoxPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    transform(data: ProductSelector): IProductBoxModel {
        let displayName: string;
        let displayAnnotation: string;
        let displayImagePath: string;
        let displayImageTitle: string;
        let displayCode: string;
        let displayEan: string;

        let discount = (data.minPriceWithVAT / data.minBasePrice - 1) * 100;
        if (Math.abs(discount) < 1) discount = 0;

        if (data.variantsCount === 1) {
            displayName = data.variants[0].name;
            displayAnnotation = data.variants[0].annotation;
            displayImagePath = data.variants[0].imagePath;
            displayCode = data.variants[0].code;
        } else {
            displayName = data.commonName;
            displayAnnotation = data.commonAnnotation;
            displayImagePath = data.variants[0].commonImagePath ? data.variants[0].commonImagePath : data.imagePath;
            displayCode = null;
            displayEan = null;
        }

        let priceRange: Array<number> = null;
        let priceWithoutVatRange: number[] = null;
        if (data && data.variantsCount > 1) {
            priceRange = [data.minPriceWithVAT, data.maxPriceWithVAT];
            priceWithoutVatRange = [data.minPriceWithoutVAT, data.maxPriceWithoutVAT];
        }

        const res: IProductBoxModel = {
            accessLevel: data.variants[0].accessLevel,
            discount: discount,
            variantId: data.variants[0].id,
            name: displayName,
            annotation: displayAnnotation,
            description: this.sanitizer.bypassSecurityTrustHtml(<string>data.commonDescription),
            isNew: data.commonIsNew,
            isAction: data.commonIsAction,
            isSellOut: data.commonIsSellOut,
            isRecommended: data.commonIsRecommended,
            images: data.images,
            rating: data.rating,
            code: displayCode,
            ean: displayEan,
            producerName: data.producerName,
            basePrice: data.minBasePrice,
            basePriceWithoutVat: data.minBasePriceWithoutVat,
            priceWithVAT: data.minPriceWithVAT,
            priceWithVatRange: priceRange,
            priceWithoutVatRange: priceWithoutVatRange,
            priceWithoutVAT: data.minPriceWithoutVAT,
            variantKey: data.variantKey,
            unit: data.variants[0].unit,
            minQtyOrder: data.variants[0].minQtyOrder,
            maxQtyOrder: data.variants[0].maxQtyOrder,
            factor: data.variants[0].factor,
            qtyInStock: data.sumQtyInStock,
            availability: data.variants[0].availability,
            urlToDisplay: null,
            imagePath: displayImagePath,
            imageTitle: displayImageTitle,
            isComparable: data.variantsCount < 2,
            variantsCount: data.variantsCount,
            discontinued: data.variants[0].discontinued,
            confirmed: data.variants[0].confirmed,
            count: null
        };

        if(data.variants.length > 1) {
            res.urlToDisplay = `/vk/${data.variantKey}/${data.variantKeySeoUrl}/`;
        } else {
            res.urlToDisplay = `/v/${data.variants[0].id}/${data.variants[0].seoUrl}/`;
        }

        return res;
    }

}
