import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FooterColumnsListComponent} from "./footer-column-list.component";
import {FooterLinksComponent} from "./footer-links.component";
import {FooterContactComponent} from "./footer-contact.component";
import {FooterStoreComponent} from "./footer-store.component";
import {QuestionFormModule} from "../question-form/question-form.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        QuestionFormModule
    ],
    declarations: [
        FooterColumnsListComponent,
        FooterLinksComponent,
        FooterContactComponent,
        FooterStoreComponent
    ],
    exports: [
        FooterColumnsListComponent,
        FooterLinksComponent,
        FooterContactComponent,
        FooterStoreComponent
    ]
})

export class FooterModule {
}
