import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef, EventEmitter,
    Injectable,
    Injector, Type
} from "@angular/core";
import {GeneralDialogComponent} from "./general-dialog.component";
import {GeneralDialogInjector, GeneralDialogRef, IMessageBoxButton, IMessageBoxConfig} from "./common";
import {GeneralDialogConfig} from "./general-dialog-config";
import {take} from "rxjs/operators";
import {GeneralDialogMessageBoxComponent} from "./general-dialog-message-box/general-dialog-message-box.component";

@Injectable()
export class GeneralDialogService {
    dialogComponentRef: ComponentRef<GeneralDialogComponent>[] = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
    }

    private appendDialogComponentToBody(config: GeneralDialogConfig): GeneralDialogRef {
        const map = new WeakMap();
        map.set(GeneralDialogConfig, config);

        const dialogRef = new GeneralDialogRef();
        map.set(GeneralDialogRef, dialogRef);

        const sub = dialogRef.afterClosed.subscribe(() => {
            // close the dialog
            this.removeDialogComponentFromBody();
            sub.unsubscribe();
        });

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GeneralDialogComponent);
        const componentRef = componentFactory.create(new GeneralDialogInjector(this.injector, map));

        componentRef.instance.isClosable = config?.isCloseAble ?? false;

        if(config && config.cssClassModifier){
            componentRef.instance.cssClassModifier = config?.cssClassModifier;
        }
        if(config && config.title){
            componentRef.instance.title = config.title;
        }

        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        this.dialogComponentRef.push(componentRef);

        this.dialogComponentRef[this.dialogComponentRef.length - 1].instance.onClose.subscribe(() => {
            this.removeDialogComponentFromBody();
        });

        return dialogRef;
    }

    private removeDialogComponentFromBody() {
        this.appRef.detachView(this.dialogComponentRef[this.dialogComponentRef.length - 1].hostView);
        this.dialogComponentRef[this.dialogComponentRef.length - 1].destroy();
        this.dialogComponentRef.pop();
    }

    public open(componentType: Type<any>, config: GeneralDialogConfig): GeneralDialogRef {
        if (!config) {
            config = GeneralDialogConfig.Empty;
        }
        const dialogRef = this.appendDialogComponentToBody(config);

        this.dialogComponentRef[this.dialogComponentRef.length - 1].instance.childComponentType = componentType;

        return dialogRef;
    }

    public close(): void {
        this.removeDialogComponentFromBody();
    }

    public showMessageBox<T>(title: string, message: string, buttons: IMessageBoxButton<T>[] = [], dialogClassModifier: string = ''): EventEmitter<T> {
        const config: GeneralDialogConfig<IMessageBoxConfig<T>> = {
            data: {
                message: message,
                buttons: buttons
            },
            cssClassModifier: dialogClassModifier,
            isCloseAble: false,
            title: title
        };

        let ref = this.open(GeneralDialogMessageBoxComponent, config);

        let emitter = new EventEmitter<T>();
        ref.afterClosed
            .pipe(take(1))
            .subscribe((result: T) => {
                emitter.emit(result);
                emitter.complete();
            });

        return emitter;
    }
}
