<div class="header-options">
    <div class="header-options__icon">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#globe"/>
            </svg>
        </div>
    </div>

    <div class="header-options__text">
        <strong>{{sen[selectedCulture.translationKey]}}</strong> {{seSvc.settings.currencyCodes[seSvc.currencyId]}}
        ({{seSvc.settings.currencies[seSvc.currencyId]}})
    </div>

    <div *ngIf="currencyOptions.length > 1 ||  seSvc.settings.cultures.length > 1" class="header-options-box">
        <div class="header-options-box__in">
            <div class="header-options-box__col">
                <div class="header-options-language" *ngFor="let item of seSvc.settings.cultures" (click)="setCulture(item.cultureId)">
                    <div class="header-options-language__image">
                        <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/flag-' + item.code + '.png'" alt="{{sen[item.translationKey]}}">
                    </div>

                    <div class="header-options-language__value">
                        {{sen[item.translationKey]}}
                    </div>
                </div>
            </div>

            <div class="header-options-box__col">
                <div class="header-options-currency" *ngFor="let item of currencyOptions" (click)="setCurrency(item)">
                    <div class="header-options-currency__symbol">
                        {{seSvc.settings.currencies[item]}}
                    </div>

                    <div class="header-options-currency__value">
                        {{seSvc.settings.currencyCodes[item]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>