<div class="help-box" [class]="classModificator ? 'help-box--'+classModificator : ''">
    <div class="help-box__subtitle">
        {{sen['ask-expert--line1']}}
    </div>

    <div class="help-box__title heading-h4">
        {{sen['ask-expert--line2']}}
    </div>

    <div class="help-box__text">
        {{sen['ask-expert--line3']}}
    </div>

    <div class="help-box__in">
        <div class="help-box-item">
            <div class="help-box-item__image">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone" />
                    </svg>
                </div>
            </div>

            <div class="help-box-item__content">
                <a href="tel: {{sen['ask-expert--contact1--line2']}}" class="help-box-item__value">
                    {{sen['ask-expert--contact1--line2']}}
                </a>

                <div class="help-box-item__text">
                    {{sen['ask-expert--contact1--line1']}}
                </div>
            </div>
        </div>

        <div class="help-box-item">
            <div class="help-box-item__image">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#email" />
                    </svg>
                </div>
            </div>

            <div class="help-box-item__content">
                <a href="mailto: {{sen['ask-expert--contact2--line2']}}" class="help-box-item__value">
                    {{sen['ask-expert--contact2--line2']}}
                </a>
            </div>
        </div>
    </div>
</div>