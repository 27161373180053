import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProductCarouselComponent} from "./product-carousel.component";
import {ProductBoxModule} from "../product-box/product-box.module";
import {HelperComponentsModule} from "../helper-components/helper-components.module";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {ProductCarouselV2} from "./product-carousel-v2.component";
import {SwiperModule} from "swiper/angular";

@NgModule({
    imports: [
        CommonModule,
        ProductBoxModule,
        HelperComponentsModule,
        ImageCacheBustModule,
        SwiperModule
    ],
    declarations: [ProductCarouselComponent,ProductCarouselV2],
    exports: [ProductCarouselComponent, ProductCarouselV2]
})

export class ProductCarouselModule {}