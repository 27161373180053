import {Component, OnInit} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector: 'cmp-footer-store',
    templateUrl: '../../tpl/footer-store.html'
})

export class FooterStoreComponent extends Translatable implements OnInit{
    constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }
}
