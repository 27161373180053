<div class="availability-ext" (click)="OnAvailabilityClick()" *ngIf="data">
    <ng-container *ngIf="centralAmount > 0; else elseNoAmount">
        <ng-container *ngIf="data.ProductionFinished === false; else elseDoprodej">
            <div class="availability-ext__content availability-ext__content--green">
                <ng-container *ngTemplateOutlet="icon"></ng-container>

                <div class="availability-ext__value">
                    {{sen['availability--in-stock']}}
                    <cmp-availability-amount [amount]="centralAmount"></cmp-availability-amount>
                    <span [innerHTML]="data?.UnitShortcut"></span>
                    v eshopu

                    <div class="availability-tooltip availability-tooltip--detail">
                        <div class="availability-tooltip__icon">
                            i
                        </div>

                        <div class="availability-tooltip__in">
                            {{sen['availability--next-orders']}} {{data.OrderTimeDays}} {{sen['availability--delivery-to--days']}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #elseDoprodej>
            <!--Doprodej-->
            <div class="availability-ext__content availability-ext__content--brown">
                <ng-container *ngTemplateOutlet="icon"></ng-container>

                <div>
                    {{sen['availability--clearance-sale']}}
                    <cmp-availability-amount [round]="false" [amount]="totalAmount"></cmp-availability-amount>
                    <span [innerHTML]="data?.UnitShortcut"></span>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-template #elseNoAmount>
        <ng-container *ngIf="data.PurchasingBlocked === false; else elseUkonceno">
            <!--Dodání do 14 dnů-->
            <div class="availability-ext__content availability-ext__content--orange">
                <ng-container *ngTemplateOutlet="icon"></ng-container>

                <div class="availability-ext__value">
                    {{sen['availability--delivery-to']}} {{data.OrderTimeDays}} {{sen['availability--delivery-to--days']}}
                </div>
            </div>
        </ng-container>

        <ng-template #elseUkonceno>
            <!--Prodej ukoncen-->
            <div class="availability-ext__content availability-ext__content--red">
                <ng-container *ngTemplateOutlet="icon"></ng-container>

                <div class="availability-ext__value">
                    {{sen['availability--sale-ended']}}
                </div>
            </div>
        </ng-template>
    </ng-template>

    <div class="availability-ext__delivery" *ngIf="totalAmount > 0">
        {{s('availability--for-personal-pickup-1')}}
        <strong>
            <cmp-availability-amount [amount]="totalAmount" [round]="!data.ProductionFinished"></cmp-availability-amount>
            <span [innerHTML]="data?.UnitShortcut"></span>
        </strong>
        {{s('availability--for-personal-pickup-2')}}

        <strong *ngIf="inStockStoreCount === 1">
            {{inStockStoreCount}}
            {{s('availability--for-personal-pickup-3')}}
        </strong>

        <strong *ngIf="inStockStoreCount > 1">
            {{inStockStoreCount}}
            {{s('availability--for-personal-pickup-3-more')}}
        </strong>
    </div>
</div>

<!------------------------------------------------------------->

<!--<ng-container *ngIf="centralAmount > 0; else elseNotOnCentral">-->
<!--    <div class="availability-ext availability-ext&#45;&#45;green" (click)="OnAvailabilityClick()">-->
<!--        <div class="availability-ext__content">-->
<!--            <div class="icon">-->
<!--                <svg>-->
<!--                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#boxes"/>-->
<!--                </svg>-->
<!--            </div>-->

<!--            <div>-->
<!--                <ng-container *ngTemplateOutlet="amount; context: {value: centralAmount, unit: data?.UnitShortcut}"></ng-container>-->

<!--                v eshopu-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="availability-ext__delivery">-->
<!--            {{s('availability&#45;&#45;for-personal-pickup-1')}}-->
<!--            <strong>{{totalAmount}}</strong>-->
<!--            {{s('availability&#45;&#45;for-personal-pickup-2')}}-->

<!--            <strong *ngIf="inStockStoreCount === 1">-->
<!--                {{inStockStoreCount}}-->
<!--                {{s('availability&#45;&#45;for-personal-pickup-3')}}-->
<!--            </strong>-->

<!--            <strong *ngIf="inStockStoreCount > 1">-->
<!--                {{inStockStoreCount}}-->
<!--                {{s('availability&#45;&#45;for-personal-pickup-3-more')}}-->
<!--            </strong>-->
<!--        </div>-->
<!--    </div>-->
<!--</ng-container>-->

<!--<ng-template #elseNotOnCentral>-->
<!--    <ng-container *ngIf="totalAmount > 0; else elseNotInStock">-->
<!--        <div class="availability-ext availability-ext&#45;&#45;orange" (click)="OnAvailabilityClick()">-->
<!--            <div class="availability-ext__content">-->
<!--                <div class="icon">-->
<!--                    <svg>-->
<!--                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#boxes"/>-->
<!--                    </svg>-->
<!--                </div>-->

<!--                <ng-container *ngTemplateOutlet="amount; context: {value: totalAmount, unit: data?.UnitShortcut}"></ng-container>-->
<!--                pro osobní odběr na {{inStockStoreCount}} prodejnách-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->

<!--    <ng-template #elseNotInStock>-->
<!--        <div class="availability-ext availability-ext&#45;&#45;red" (click)="OnAvailabilityClick()">-->
<!--            <div class="availability-ext__content">-->
<!--                <div class="icon">-->
<!--                    <svg>-->
<!--                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#boxes"/>-->
<!--                    </svg>-->
<!--                </div>-->

<!--                {{s('availability&#45;&#45;not-in-stock')}}-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-template>-->
<!--</ng-template>-->

<!--<ng-template #amount let-value="value" let-unit="unit">-->
<!--    <ng-container *ngIf="value > 1000; else elseLessThanThousand">-->
<!--        > 1000 <span class="availability-ext__unit" [innerHTML]="unit"></span>-->
<!--    </ng-container>-->
<!--    <ng-template #elseLessThanThousand>-->
<!--        {{s('availability&#45;&#45;in-stock')}} {{value}} <span class="availability-ext__unit" [innerHTML]="unit"></span>-->
<!--    </ng-template>-->
<!--</ng-template>-->

<ng-template #icon>
    <div class="icon">
        <svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#boxes"/>
        </svg>
    </div>
</ng-template>
