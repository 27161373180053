export enum AccountMenuItems {
    account = 'account-h1',
    account_order_list = 'account-order-list',
    account_invoice_list = 'account-invoice-list',
    account_delivery_note_list = 'account-delivery-note-list',
    account_settings = 'account-settings-h1',
    account_collaborators = 'account-collaborators',
    account_profile = 'account-profile-h1',
    account_b2b_invoice_data = 'edit-invoice-data',
    account_wishlists = 'account-wishlists',
    account_delivery_addresses = 'account-delivery-addresses'
}

export interface IAccountMenuItem {
    isShownInTop: boolean;
    isShownInSide: boolean;
    sideItem: AccountMenuItems;
    routerLink: string;
    iconActive: string;
    iconNotActive: string;
}
