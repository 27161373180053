<div class="modal-window__text modal-window__text--center">
    {{s('merge-cart-dialog--description')}}
</div>

<div class="modal-merge">
    <div class="modal-merge__in">
        <div class="modal-merge__col">
            <div class="modal-merge__title heading-h4">
                {{s('merge-cart-dialog--cart-current-title')}}
            </div>

            <div class="modal-merge__products">
                <div class="modal-merge__products__in scrollbar">
                    <ng-container *ngFor="let item of config.data?.MergeInfo?.CurrentCartProducts">
                        <ng-container *ngTemplateOutlet="cartProductItem; context: { item: item }"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="modal-merge__col">
            <div class="modal-merge__title heading-h4">
                {{s('merge-cart-dialog--cart-previous-title')}}
            </div>

            <div class="modal-merge__products">
                <div class="modal-merge__products__in scrollbar">
                    <ng-container *ngFor="let item of config.data?.MergeInfo?.PreviousCartProducts">
                        <ng-container *ngTemplateOutlet="cartProductItem; context: { item: item }"></ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-merge__actions">
        <button class="btn btn--medium btn--primary" (click)="SelectCurrentCart()">
            {{s('merge-cart-dialog--continue-cart-1')}}
        </button>

        <button class="btn btn--medium btn--primary" (click)="SelectPreviousCart()">
            {{s('merge-cart-dialog--continue-cart-2')}}
        </button>

        <button class="btn btn--medium btn--conversion" (click)="SelectMergeCart()">
            {{s('merge-cart-dialog--merge-cart')}}
        </button>
    </div>
</div>

<ng-template #cartProductItem let-item="item">
    <div class="modal-merge-product">
        <div class="modal-merge-product__main">
            <div class="modal-merge-product__image">
                <img src="{{imageServerUrl}}/common/40/40/{{item.Product.imagePath}}">
            </div>

            <div class="modal-merge-product__title">
                {{item.Product.code}} {{item.Product.name}}
            </div>
        </div>

        <div class="modal-merge-product__amount">
            {{item.Count}} <span [innerHTML]="item.Product?.unit?.shortCut"></span>
        </div>
    </div>
</ng-template>