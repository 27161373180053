/**
 * @description
 * Serves as a container for front end application settings
 * Place only things that are really needed
 */
import {Injectable} from '@angular/core';
import {
    BrandSelector,
    Culture,
    GaSelector,
    HeurekaSelector,
    DomainSelector,
    SettingItem,
    Settings,
    SeznamSelector,
    StringIndexedObject, CartTokenSelector, CopyrightSelector
} from "../interfaces/general";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromLocalStorage, loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {IntegrationService} from "../interceptors/integration.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {CartTokenService} from "../modules/cart/cart-token.service";
import {ReplaySubject} from "rxjs";
import {CultureFactory} from "../implementations/CultureFactory";
import {FacebookSdkService} from "../modules/facebook-sdk/services/facebook-sdk.service";
import {GoogleApiService} from "../modules/google-api/services/google-api.service";
import {CurrencyFactory} from "../implementations/CurrencyFactory";

let settings: Settings =
    {
        preAuth: false,
        preAuthToLocal: true,
        preAuthLocalExpiration: 12,
        // cartAdjustAction: "ask",
        pageSizes: [{value: 12}, {value: 24}, {value: 48}],
        countries: [
            {
                id: 326,
                name: 'Česká republika'
            },
            {
                id: 462,
                name: 'Slovenská republika'
            }
        ],
        cultures: [
            {
                code: "cs-CZ",
                cultureId: 34,
                name: "Česky",
                currencyCode: "CZK",
                currencySymbol: "Kč",
                translationKey: "app-czech",
                htmlLangAttribute: "cs"
            }
        ],
        validationPatterns: {
            email: /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,8})$/,
            naturalNumber: /^[1-9][0-9]*$/,
            decimalNumber: /^(\d*[.,])?\d+$/,
            formattedNaturalNumber: /^(\d{1,3} (\d{3} )*\d{3}|\d{1,3})$|^[1-9][0-9]*$/,
            phone: {
                34: /^(\+420|\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                102: /^(\+420|\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                50: /^(\+420|\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/
            },
            zipCodeCz: /^\d{3}\s?\d{2}$/
        },
        localDomain: "http://localhost/elimacz",
        currentServerUrl: "",
        decimalSettings: null,
        currencies: {
            3: "Kč",
            2: "€"
        },
        currencyCodes: {
            3: "CZK",
            2: "EUR"
        },
        shopSeo: null,
        routesWithDefaultSeo: [/^$/, /^\/$/],
        assetPathPrefix: 'app/common/classic',
        newImageServerUrl: null,
        noIndexRoutes: [],
        termsAndConditions: {}
    };

export function GetLocaleFactory(seSvc: SettingsService) {
    return seSvc.getLocale()
}

@Injectable()
export class SettingsService {

    passwordRegExpPattern: string;
    passwordPolicyMessage: string

    startUpSettingsAreSet: ReplaySubject<any> = new ReplaySubject<any>(1);//puvodne jsem mel EventEmitter,
    //ale Tomas Novosad narazil na problem, kdy k subscribu v CookiesConsentService
    //dochazelo az po emitnuti v setStartUpSettings
    //tak to menim na ReplySubject, tam to lupne dycky

    get cartMaxStepSessionKey(): string {
        return 'cartMaxStep';
    }

    private _cacheBustKey: string;
    get cacheBustKey(): string {
        return this._cacheBustKey;
    }

    set cacheBustKey(value: string) {
        this._cacheBustKey = value;
    }

    private _availabilitySetting: AvailabilitySetting;
    get availabilitySetting(): AvailabilitySetting {
        return this._availabilitySetting;
    }

    set availabilitySetting(value: AvailabilitySetting) {
        this._availabilitySetting = value;
    }

    private _sen: StringIndexedObject<string>;
    get sen(): StringIndexedObject<string> {
        return this._sen;
    }

    set sen(value: StringIndexedObject<string>) {
        this._sen = value;
        Sentences.sen = value;
    }

    private _culture: Culture;
    get culture(): Culture {
        return this._culture;
    }

    set culture(value: Culture) {
        this._culture = value;
    }

    private _gaSettings: GaSelector;
    get gaSettings(): GaSelector {
        return this._gaSettings;
    }

    set gaSettings(value: GaSelector) {
        this._gaSettings = value;
    }

    private _seznamSettings: SeznamSelector;
    get seznamSettings(): SeznamSelector {
        return this._seznamSettings;
    }

    set seznamSettings(value: SeznamSelector) {
        this._seznamSettings = value;
    }

    private _heurekaSettings: HeurekaSelector;
    get heurekaSettings(): HeurekaSelector {
        return this._heurekaSettings;
    }

    set heurekaSettings(value: HeurekaSelector) {
        this._heurekaSettings = value;
    }

    private _brand: BrandSelector;
    get brand(): BrandSelector {
        return this._brand;
    }

    set brand(value: BrandSelector) {
        this._brand = value;
    }

    private _copyright: CopyrightSelector;
    get copyright(): CopyrightSelector {
        return this._copyright;
    }

    set copyright(value: CopyrightSelector) {
        this._copyright = value;
    }

    private _domains: DomainSelector[];
    get domains(): DomainSelector[] {
        return this._domains;
    }

    set domains(value: DomainSelector[]) {
        this._domains = value;
    }

    private _activeUrlInfo: DomainSelector;
    get activeUrlInfo(): DomainSelector {
        return this._activeUrlInfo;
    }

    set activeUrlInfo(value: DomainSelector) {
        this._activeUrlInfo = value;
    }

    private _bindCurrencyAndCultureToDomain: boolean;
    get bindCurrencyAndCultureToDomain(): boolean {
        return this._bindCurrencyAndCultureToDomain;
    }

    set bindCurrencyAndCultureToDomain(value: boolean) {
        this._bindCurrencyAndCultureToDomain = value;
    }

    private _supportMail: string;
    get supportMail(): string {
        return this._supportMail;
    }

    set supportMail(value: string) {
        this._supportMail = value;
    }

    private _commerceConnectorShopId: string;
    get commerceConnectorShopId(): string {
        return this._commerceConnectorShopId;
    }

    set commerceConnectorShopId(value: string) {
        this._commerceConnectorShopId = value;
    }

    settings: Settings;
    domainCacheBust: string;
    sharedAppDefaults: StringIndexedObject<any>;
    currencyId: number;
    comAllowed: boolean = false;

    private _modalOpen: boolean = false;
    get modalOpen(): boolean {
        return this._modalOpen;
    }

    set modalOpen(value: boolean) {
        this._modalOpen = value;
    }


    constructor(
        saSvc: SharedAppSettings,
        private http: HttpClient,
        private iSvc: IntegrationService,
        private cartTokenSvc: CartTokenService,
        private fbSvc: FacebookSdkService,
        private googleSvc: GoogleApiService) {

        this.settings = settings;
        this.sharedAppDefaults = saSvc.Defaults;

        let cultureIdDefault: number = new CultureFactory().GetCulture();
        let currencyIdDefault: number = new CurrencyFactory().GetCurrency();

        this.culture = this.settings.cultures.find((c) => c.cultureId == cultureIdDefault);
        this.currencyId = currencyIdDefault;

        this.domainCacheBust = encodeURIComponent(`${location.protocol}//${location.hostname}`);
        let cult = <Culture>loadFromSession('cult');
        if (cult) {
            this.culture = cult;
        }
        let curr = loadFromSession('curr');
        if (curr) {
            this.currencyId = curr;
        }

        this.iSvc.httpRegional.cultureId = this.culture.cultureId;

    }

    setCulture(cultureId: number, currencyId: number) {
        this.setCultureAndReload(false, cultureId, currencyId);
    }

    setCultureAndReload(reload: boolean, cultureId: number, currencyId: number) {
        this.culture = this.settings.cultures.filter(c => c.cultureId == cultureId)[0];
        saveToSession('cult', this.culture);

        this.currencyId = currencyId;
        saveToSession('curr', this.currencyId);

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);

        if (this.bindCurrencyAndCultureToDomain) {
            this.activeUrlInfo = this.domains.filter(f => f.cultureId === cultureId)[0];
            location.href = this.activeUrlInfo.url;
        } else {
            if (reload) location.reload();
        }
    }

    private setStartUpSettings(settingMap: Map<string, any>): void {
        this.activeUrlInfo = settingMap.get('ActiveServerUrl');
        this.settings.noIndexRoutes = JSON.parse(settingMap.get('NoIndexRoutes'));
        this.settings.currentServerUrl = this.activeUrlInfo.url;
        this.domains = settingMap.get('Domains');
        this.bindCurrencyAndCultureToDomain = JSON.parse(settingMap.get('BindCurrencyAndCultureToDomain'));
        this.settings.preAuth = settingMap.get('PreAuthEnabled') === "true";
        this.settings.decimalSettings = JSON.parse(settingMap.get('DecimalSettings'));
        this.settings.shopSeo = JSON.parse(settingMap.get('ShopSeoSettings'))[this.activeUrlInfo.cultureId];
        this.settings.assetPathPrefix = settingMap.get('AssetPathPrefix');
        this.settings.newImageServerUrl = settingMap.get('ImageServer');
        this.availabilitySetting = settingMap.get('Availability');
        this.cacheBustKey = settingMap.get('ImageCacheBust');
        this.gaSettings = settingMap.get('GaSettings');
        this.seznamSettings = settingMap.get('SeznamSettings');
        this.heurekaSettings = settingMap.get('HeurekaSettings');
        this.brand = JSON.parse(settingMap.get('Logo'));
        this.passwordRegExpPattern = settingMap.get("PasswordRegExpPattern");
        this.passwordPolicyMessage = settingMap.get('PasswordPolicyMessage');
        this.settings.termsAndConditions = JSON.parse(settingMap.get('TermsAndConditionsUrl'));
        this.supportMail = settingMap.get('SupportMail');
        this.commerceConnectorShopId = settingMap.get('CommerceConnectorSettings');

        this.copyright = (JSON.parse(settingMap.get("Copyright")) as CopyrightSelector[]).find(f => f.cultureId == this.activeUrlInfo.cultureId);

        this.fbSvc.Init({
            ApplicationId: settingMap.get('FacebookSettings')?.applicationId
        });

        this.googleSvc.Init({
            ClientId: settingMap.get('GoogleSettings')?.ClientId
        });

        let pa = this.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');
        this.comAllowed = pa || !this.settings.preAuth;

        this.setInitialCurrencyAndCultureByUrl();
        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);

        this.startUpSettingsAreSet.next();
    }

    private setUpApplication(input: any): any {
        const m = SettingsService.makeSettingsMap(input['StartUpSettings']);
        this.setStartUpSettings(m);
        return this.makeSentenceObject(input['Translations']);
    }

    private setInitialCurrencyAndCultureByUrl(): void {
        if (this.bindCurrencyAndCultureToDomain) {
            this.culture = this.settings.cultures.filter(f => f.cultureId === this.activeUrlInfo.cultureId)[0];
            this.currencyId = this.activeUrlInfo.currencyId;
        }
        saveToSession('cult', this.culture);
        saveToSession('curr', this.currencyId);
        this.iSvc.httpRegional.cultureId = this.culture.cultureId;
        this.iSvc.httpRegional.currencyId = this.currencyId;
    }

    //the first called function
    //synchronous
    getCartTokens(): Promise<any> {

        const url = `api/cart-token?dcb=${this.domainCacheBust}`;

        return this.http.get<Array<CartTokenSelector>>(url).toPromise()
            .then((res) => {
                this.cartTokenSvc.cartTokens = res;
                return this.getAllTranslations();
            });
    }

    private getAllTranslations(): any {
        let options = {headers: new HttpHeaders()};
        options.headers.set('F-CC', `cu:${this.culture.cultureId},cy:${this.currencyId}`);

        this.iSvc.httpRegional = {
            comAllowed: this.comAllowed,
            cultureId: this.culture.cultureId,
            currencyId: this.currencyId
        };

        let result = this.http.get(`api/lang?dcb=${this.domainCacheBust}`, options)
            .pipe(
                map(res => this.setUpApplication(res)),
                tap(res => {
                    this.sen = res;
                    this.iSvc.httpRegional.comAllowed = this.comAllowed;
                })
            );
        return result.toPromise()
    }

    getLocale(): string {
        return this.culture.code;
    }

    private makeSentenceObject(input: Object[]): Object {
        let tmp: StringIndexedObject<any> = {};
        if (input) {
            input.forEach((e: any) => tmp[e['Key']] = e['Value']);
        }
        return tmp;
    }

    private static makeSettingsMap(items: SettingItem[]): Map<string, any> {
        let m: Map<string, any> = new Map();
        for (let item of items) {
            m.set(item.Key, item.Value);
        }
        return m;
    }

    private static setHtmlLangAttribute(lang: string): void {
        document.querySelector('html').setAttribute('lang', lang);
    }


}

export enum AvailabilitySetting {
    onlyText = 'onlyText',
    onlyNumber = 'onlyNumber',
    both = 'both'
}

export class Sentences {
    public static sen: StringIndexedObject<any>;
}