<div class="whisperer whisperer--search">
    <ng-container *ngIf="!loading; else elseLoading">
        <div *ngIf="(suggestResult?.Products?.length || suggestResult?.Categories?.length || suggestResult?.Articles?.length); else nothing" class="whisperer__in" (click)="onComponentClick($event)">
            <div class="whisperer__wrap">
                <ng-container *ngIf="suggestResult?.Products?.length">
                    <div class="whisperer__section">
                        <div class="whisperer__title heading-h4">
                            {{sen['app-products']}} ({{suggestResult?.ProductCount}})
                        </div>

                        <div class="whisperer__list whisperer__list--products">
                            <ng-container *ngFor="let product of suggestResult.Products">
                                <ng-container *ngIf="product.variantsCount < 2">
                                    <a [routerLink]="['/v/' + product.variants[0].id + '/' +  product.variants[0].seoUrl]" (click)="linkClicked()" class="whisperer-product">
                                        <div class="whisperer-product__image">
                                            <picture>
                                                <source [srcset]="(imagePathPrefix + '/suggest/150/150/' + product.variants[0].imagePath) | webp" type="image/webp"/>
                                                <img [src]="imagePathPrefix + '/suggest/150/150/' + product.variants[0].imagePath" [alt]="product.variants[0].name" [title]="product.variants[0].name">
                                            </picture>
                                        </div>

                                        <div class="whisperer-product__content">
                                            <div class="whisperer-product__title" [innerHTML]="(product.variants[0].name ? product.variants[0].name : product.variants[0].code) | searchExpressionHighlight : searchExpressionWords">
                                            </div>
                                        </div>

                                        <div class="whisperer-product__prices">
                                            <div class="prices prices--smaller prices--right">
                                                <ng-container *ngIf="isUserB2B">
                                                    <div class="prices__item prices__item--main">
                                                        <cmp-price [price]="product.variants[0].priceWithoutVAT"></cmp-price>
                                                        <span>{{sen['without-vat']}}</span>
                                                    </div>

                                                    <div class="prices__item prices__item--base">
                                                        <cmp-price [price]="product.variants[0].priceWithVAT"></cmp-price>
                                                        <span>{{sen['with-vat']}}</span>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="!isUserB2B">
                                                    <div class="prices__item prices__item--main">
                                                        <cmp-price [price]="product.variants[0].priceWithVAT"></cmp-price>
                                                        <span>{{sen['with-vat']}}</span>
                                                    </div>

                                                    <div class="prices__item prices__item--base">
                                                        <cmp-price [price]="product.variants[0].priceWithoutVAT"></cmp-price>
                                                        <span>{{sen['without-vat']}}</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </a>
                                </ng-container>

                                <ng-container *ngIf="product.variantsCount > 1">
                                    <a [routerLink]="['/v/' + product.variants[0].id + '/' +  product.variants[0].seoUrl]" (click)="linkClicked()" class="whisperer-product">
                                        <div class="whisperer-product__image">
                                            <picture>
                                                <source [srcset]="(imagePathPrefix + '/suggest/60/60/' + product.imagePath) | webp" type="image/webp"/>
                                                <img [src]="imagePathPrefix + '/suggest/60/60/' + product.imagePath" [alt]="product.commonName" [title]="product.commonName">
                                            </picture>
                                        </div>

                                        <div class="whisperer-product__content">
                                            <div class="whisperer-product__title" [innerHTML]="product.commonName | searchExpressionHighlight : searchExpressionWords">
                                            </div>
                                        </div>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <div class="whisperer__section" *ngIf="suggestResult?.Categories?.length">
                    <div class="whisperer__title heading-h4">
                        {{sen['app-category']}} ({{suggestResult?.CategoryCount}})
                    </div>

                    <div class="whisperer__list whisperer__list--categories">
                        <ng-container *ngFor="let category of suggestResult.Categories">
                            <a [routerLink]="['/c/' + category.id + '/' + category.seoUrl]" class="whisperer__item" (click)="linkClicked()" [innerHTML]="category.displayName | searchExpressionHighlight : searchExpressionWords">
                            </a>
                        </ng-container>
                    </div>
                </div>

                <div class="whisperer__section" *ngIf="suggestResult?.Articles?.length">
                    <div class="whisperer__title heading-h4">
                        {{sen['flex-suggest--article-title']}} ({{suggestResult?.ArticleCount}})
                    </div>

                    <div class="whisperer__list whisperer__list--articles">
                        <div class="whisperer__item" *ngFor="let a of suggestResult.Articles">
                            <a [routerLink]="a.seoUrl" [innerHTML]="a.name | searchExpressionHighlight : searchExpressionWords" (click)="linkClicked()"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="whisperer__actions">
                <a [routerLink]="['vysledky-vyhledavani']" [queryParams]="{q:phrase}" class="btn btn--medium btn--primary" (click)="linkClicked()">
                    {{sen['app-show-all']}}
                </a>
            </div>
        </div>

        <ng-template #nothing>
            <div class="whisperer__in">
                <div class="whisperer__section" (click)="onComponentClick($event)">
                    <div class="whisperer__not-found">{{sen['app-suggest-nothing']}}</div>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-template #elseLoading>
        <div class="whisperer__in">
            <div class="whisperer__section" (click)="onComponentClick($event)">
                <div class="whisperer__not-found">{{sen['search--loading']}}</div>
            </div>
        </div>
    </ng-template>
</div>
