import {EventEmitter, Injectable} from "@angular/core";
import {ProductVariantBriefSelector} from "../interfaces/general";
import {HttpClient} from "@angular/common/http";
import {CartItemSelectorBrief} from "../modules/cart/common";
import {IWishList, IWishListItem} from "../modules/account/common";
import {tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {CredentialStorage} from "./credential-storage.service";

declare let StringView: any;

export interface ISaveCartResult {
    WishList: IWishList;
    WishLists: IWishList[];
}

@Injectable()
export class WishListService {

    private _wishLists: IWishList[];
    onWishListsChanged: EventEmitter<IWishList[]>;

    get wishLists(): IWishList[] {
        return this._wishLists;
    }

    constructor(
        private http: HttpClient
    ) {
        this.onWishListsChanged = new EventEmitter<IWishList[]>();

        this._wishLists = null;
        if (CredentialStorage.userLoggedIn) {
            this.getWishLists();
        }
    }

    private getWishLists(): void {
        let url = 'api/wish-lists/wish-lists-for-user';
        this.http.get<IWishList[]>(url)
            .subscribe(res => {
                this._wishLists = res;
                this.onWishListsChanged.emit(this._wishLists);
            });
    }

    createNewList(name: string): EventEmitter<string> {
        let url = 'api/wish-lists/add-new';
        let newListNameBase64 = new StringView(name).toBase64();

        const currentHashes = this._wishLists.map(wl => wl.hash);
        const emitter = new EventEmitter<string>();

        this.http.get<IWishList[]>(`${url}/${newListNameBase64}`)
            .subscribe(res => {
                this._wishLists = res;

                const filtered = this._wishLists.filter(wl => currentHashes.indexOf(wl.hash) == -1);
                if (filtered && filtered.length) {
                    emitter.emit(filtered[0].hash);
                }

                this.onWishListsChanged.emit(this._wishLists);
            });
        return emitter;
    }

    deleteWishList(hash: string): void {
        let url = `api/wish-lists/delete/${hash}`;
        this.http.get<boolean>(url)
            .subscribe(res => {
                if (res) {
                    this.getWishLists();
                }
            })
    }

    addItemToWishList(wishListHash: string, variantId: number): EventEmitter<ProductVariantBriefSelector[]> {
        let emitter = new EventEmitter<ProductVariantBriefSelector[]>();
        let url = `api/wish-lists/add-item/${wishListHash}/${variantId}`;


        this.http.get<IWishList>(url)
            .subscribe(res => {

                const index = this._wishLists.findIndex(wl => wl.hash == wishListHash);
                this._wishLists[index] = res;

                const mapped = res.items?.map(i => i.variant);
                emitter.emit(mapped);
            });

        return emitter;
    }

    changeCount(wishListHash: string, variantId: number, newCount: number): EventEmitter<boolean> {
        let emitter = new EventEmitter<boolean>();
        let url = `api/wish-lists/change-count/${wishListHash}/${variantId}/${newCount}`;


        this.http.get<boolean>(url)
            .subscribe(res => {
                emitter.emit(res);
            });

        return emitter;
    }

    removeFromWishList(wishListHash: string, variantId: number): EventEmitter<IWishListItem[]> {

        let emitter = new EventEmitter<IWishListItem[]>();
        let url = `api/wish-lists/remove-item/${wishListHash}/${variantId}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                const index = this._wishLists.findIndex(wl => wl.hash == wishListHash);
                this._wishLists[index] = res;
                emitter.emit(res.items);
            });
        return emitter;
    }


    getWishList(wishListHash: string): EventEmitter<IWishList> {

        let emitter = new EventEmitter<IWishList>();
        let url = `api/wish-lists/${wishListHash}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                if (!this._wishLists) {
                    this._wishLists = [];
                }

                const index = this._wishLists.findIndex(wl => wl.hash == res.hash);
                if (index == -1) {
                    this._wishLists.push(res);
                } else {
                    this._wishLists[index] = res;
                }
                emitter.emit(res);
                emitter.complete();
            }, () => {
                emitter.complete();
            });
        return emitter;
    }

    isOnList(wishListHash: string, productId: number): boolean {
        if (!this._wishLists) {
            return false;
        }

        let filteredLists = this._wishLists.filter(wl => wl.hash == wishListHash);
        if (!filteredLists) {
            return false;
        }

        let list = filteredLists[0];
        if (!list || !list.items) {
            return false;
        }

        let filtered = list.items.map(i => i.variant.id).filter(id => id == productId);
        if (!filtered) {
            return false;
        }

        return filtered[0] && filtered[0] == productId;
    }

    generateCartItemsForAddToCart(hash: string) {
        return this._wishLists.find(wl => wl.hash == hash)?.items?.map((i) => {
            const ret: CartItemSelectorBrief = {
                productId: i.variant.id,
                count: i.count
            };
            return ret;
        })
    }

    eraseWishList(wishListHash: string): EventEmitter<any> {
        let emitter = new EventEmitter<any>();
        let url = `api/wish-lists/erase/${wishListHash}`;

        this.http.get<boolean>(url)
            .subscribe(res => {
                emitter.emit(res);
            });
        return emitter;
    }

    public SaveCart(): Observable<ISaveCartResult> {
        return this.http.post<ISaveCartResult>('api/wish-lists/save-cart', {})
            .pipe(tap((res) => {
                this._wishLists = res.WishLists;
                this.onWishListsChanged.emit(this._wishLists);
            }));
    }
}