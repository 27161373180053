import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../../general-dialog/common";
import {IAvailabilityDialogConfig} from "../commons/commons";
import {Translatable} from "../../../interfaces/general";

@Component({
    selector: 'cmp-availability-dialog',
    templateUrl: './availability-dialog.component.html',
    styleUrls: ['../../../assets/styles/2-components/modal-window/modal-availability.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AvailabilityDialogComponent extends Translatable implements OnInit {

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        public config: GeneralDialogConfig<IAvailabilityDialogConfig>,
        public dialogRef: GeneralDialogRef
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

}
