import {EventEmitter, Injectable, Injector} from "@angular/core";
import {
    CategoryConfig, CategoryDetailDataSelector, OrderByOptions,
    ProductRequest,
    ProductResponseModel
} from "../modules/category/interfaces";
import {ParametricFilterRequest, StringIndexedObject} from "../interfaces/general";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {finalize, take, takeUntil} from "rxjs/operators";
import {ParameterSelector} from "../modules/product-parameters-filter/common";
import {ProducerSelectorBrief} from "../modules/producer-filter/common";
import {DataService} from "./data.service";
import {ErrorService} from "./error.service";
import {SharedCategorySettings, SharedProductSettings} from "../shared-settings/shared-settings";
import {SettingsService} from "./settings.service";
import {PageStateUrlService} from "./page-state-url.service";
import {ProductSelector} from "../modules/product/common";
import {ISelectBoxItemModel} from "../modules/select-box/interfaces/ISelectBoxitemModel";
import {IStoreFilterItem} from "../modules/stores/common";


declare let StringView: any;

export interface IProductDataChangeArgs {
    Products: ProductSelector[];
    NewProducts: ProductSelector[];
}

@Injectable({
    providedIn: null, deps: [PageStateUrlService]
})
export class CategoryService {

    private http: HttpClient;
    private dataSvc: DataService;
    private seSvc: SettingsService;
    private errSvc: ErrorService;
    private sharedCatSettingsSvc: SharedCategorySettings;
    private lastCategoryId: number;

    //orderByOptions: OrderByOptions[];
    orderByOptions: ISelectBoxItemModel<OrderByOptions, number>[] = [];
    ngUnsubscribe: Subject<any> = new Subject();

    //url parameters
    private url_pageSizeIndex: string = 'psi';
    private url_pageIndex: string = 'pi';
    private url_producer: string = 'pr';
    private url_stores: string = 'st';
    private url_spot: string = 's';
    private url_showAvailableOnly: string = 'sao';
    private url_selectedPriceRangeMin: string = 'spr0';
    private url_selectedPriceRangeMax: string = 'spr1';
    private url_orderBy: string = 'o';

    private url_flags: StringIndexedObject<string>;

    //

    get categoryId(): number {
        return this.config.categoryId;
    }

    get pageSizeIndex(): number {
        return this.config.pageSizeIndex;
    }

    set pageSizeIndex(value: number) {
        this.config.pageSizeIndex = value;
    }


    get pageIndex(): number {
        return this.config.pageIndex;
    }

    set pageIndex(value: number) {
        this.config.pageIndex = value;
    }


    get pageSize(): number {
        return this.config.pageSize;
    }

    set pageSize(value: number) {
        this.config.pageSize = value;
    }


    get orderByIndex(): number {
        return this.config.orderByIndex;
    }

    set orderByIndex(value: number) {
        this.config.orderByIndex = value;
    }

    get cultureId(): number {
        return this.config.cultureId;
    }

    set cultureId(value: number) {
        this.config.cultureId = value;
    }

    get pageSizes(): any[] {
        return this.config.pageSizes;
    }

    set pageSizes(value: any[]) {
        this.config.pageSizes = value;
    }

    get selectedOrderBy(): OrderByOptions {
        return this.config.selectedOrderBy;
    }

    set selectedOrderBy(value: OrderByOptions) {
        this.config.selectedOrderBy = value;
    }

    get showAvailableOnly(): boolean {
        return this.config.showAvailableOnly;
    }

    set showAvailableOnly(value: boolean) {
        this.config.showAvailableOnly = value;
    }

    get paramsHash(): string {
        return this.config.paramsHash;
    }

    set paramsHash(value: string) {
        this.config.paramsHash = value;
    }

    get selectedProducers(): Array<number> {
        return this.config.selectedProducers;
    }

    set selectedProducers(value: Array<number>) {
        this.config.selectedProducers = value;
    }

    get selectedStores(): Array<number> {
        return this.config.selectedStores;
    }

    set selectedStores(value: Array<number>) {
        this.config.selectedStores = value;
    }

    get selectedSpots(): Array<number> {
        return this.config.selectedSpots;
    }

    set selectedSpots(value: Array<number>) {
        this.config.selectedSpots = value;
    }

    constructor(private injector: Injector, private pageStateUrlSvc: PageStateUrlService, private sharedProductSettings: SharedProductSettings) {
        this.fillUrlFlags();
        this.http = <HttpClient>this.injector.get(HttpClient);
        this.dataSvc = <DataService>this.injector.get(DataService);
        this.errSvc = <ErrorService>this.injector.get(ErrorService);
        this.sharedCatSettingsSvc = <SharedCategorySettings>this.injector.get(SharedCategorySettings);
        this.seSvc = <SettingsService>this.injector.get(SettingsService);

        this.orderByOptions = [
            {
                Value: 0,
                Data: {id: this.sharedCatSettingsSvc.Sorting.price_asc, name: 'cat-order-price-asc'},
                IsDisabled: false
            },
            {
                Value: 1,
                Data: {id: this.sharedCatSettingsSvc.Sorting.price_desc, name: 'cat-order-price-desc'},
                IsDisabled: false
            },
            {
                Value: 2,
                Data: {id: this.sharedCatSettingsSvc.Sorting.BestSelling, name: 'category-best-selling-products'},
                IsDisabled: false
            },
            {
                Value: 3,
                Data: {id: this.sharedCatSettingsSvc.Sorting.name_asc, name: 'cat-order-alpha-asc'},
                IsDisabled: false
            },
            {
                Value: 4,
                Data: {id: this.sharedCatSettingsSvc.Sorting.name_desc, name: 'cat-order-alpha-desc'},
                IsDisabled: false
            }
        ];

        this.init();
    }

    afterSvcInit: EventEmitter<any> = new EventEmitter<any>();
    filterDataChanged: EventEmitter<any> = new EventEmitter<any>();
    productDataChanged: EventEmitter<IProductDataChangeArgs> = new EventEmitter<IProductDataChangeArgs>();

    private _config: CategoryConfig;
    // private set config(value: CategoryConfig) {
    //     this._config = value;
    // }

    get config(): CategoryConfig {
        return this._config;
    }

    actionCount: number;
    recommendedCount: number;
    newsCount: number;
    sellOutCount: number;
    availableOnlyCount: number;
    parameters: Array<ParameterSelector>;
    prices: Array<number>;

    get priceMin(): number {
        if (!this.prices || !this.prices.length) {
            return null;
        }
        return Math.min(...this.prices);
    }

    get priceMax(): number {
        if (!this.prices || !this.prices.length) {
            return null;
        }
        return Math.max(...this.prices);
    }


    private parametricFilter: ParametricFilterRequest;

    products: ProductResponseModel;
    producers: Array<ProducerSelectorBrief>;
    stores: IStoreFilterItem[];

    private init() {
        this.parametricFilter = new ParametricFilterRequest(null);

        this._config = this.getNewConfig();

        this.fillData(null);

        this.afterSvcInit.emit();
    }

    private getNewConfig(): CategoryConfig {
        return new CategoryConfig({
            selectedStores: [],
            pageSizeIndex: 0,
            pageIndex: 1,
            selectedProducers: [],
            selectedPriceRange: [null, null],
            selectedFlags: [],
            selectedOrderBy: this.orderByOptions[0].Data,
            orderByIndex: 0,
            filterIsActive: false,
            showAvailableOnly: false,
            paramsHash: this.parametricFilter.paramsHash,
            pageSizes: this.seSvc.settings.pageSizes,
            selectedSpots: []
        });
    }

    getProductRequest(): ProductRequest {

        let productRequest: ProductRequest = {
            categoryId: this.config.categoryId,
            cultureId: this.config.cultureId,
            pageIndex: this.config.pageIndex,
            pageSize: this.config.pageSize,
            priceRange: [
                this.config.selectedPriceRange[0],
                this.config.selectedPriceRange[1]
            ],
            orderByKeys: [(this.orderByIndex > 0 ? this.orderByOptions.find(x=>x.Value == this.orderByIndex).Data.id : this.config.selectedOrderBy.id)],
            producers: this.config.selectedProducers,
            flags: this.config.selectedFlags,
            showAvailableOnly: this.config.showAvailableOnly,
            parametricFilter: this.parametricFilter,
            spots: this.selectedSpots,
            stores: this.selectedStores
        };

        return productRequest;
    }

    getCategoryDetailData(): void {

        /*
        prechazime na PWA, custom cacheovani v servisach je obsolete

        //obsolete:
        //v tomto miste je jiste, ze obe ridici udalosti v pripade inicializace kategorie,
        //tedy zmena url a afterViewInit jiz prodbehly
        //pokud se uzivatel vraci z detailu, jinymi slovy: pokud mame zobrazit
        //stejnou kategorii, jako posledne,
        //staci overit, ze najeka data na servise mame, a pak vypalit udalost, ze data se zmenila a komponenty si nactou data, ktera jsou aktualne v servise
        //
        if (initCall && this.config.categoryId == this.lastCategoryId && this.products) {
            this.productDataChanged.emit();
            this.filterDataChanged.emit();
            return;
        }
        */

        this.lastCategoryId = this.config.categoryId;

        this.setParamsIntoUrl();

        const req = this.getProductRequest();
        let productRequestString = new StringView(JSON.stringify(req)).toBase64();

        this.dataSvc.dataLoading = true;
        this.http.get<CategoryDetailDataSelector>(`api/category/category-detail-data?qs=${productRequestString}`)
            .pipe(
                take(1),
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                }),
                takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.fillData(res);
                this.productDataChanged.emit();
                this.filterDataChanged.emit();


            });
    }


    private fillData(dataObject: CategoryDetailDataSelector): void {
        if (dataObject) {
            this.actionCount = dataObject.actionCount;
            this.newsCount = dataObject.newsCount;
            this.recommendedCount = dataObject.recommendedCount;
            this.sellOutCount = dataObject.sellOutCount;
            this.availableOnlyCount = dataObject.availableOnlyCount;
            if (this.availableOnlyCount == 0) {
                this.config.showAvailableOnly = false;
            }
            this.parameters = dataObject.parameters.filter(f => f.productCount > 0);
            this.prices = dataObject.prices;

            this.producers = dataObject.producers;
            this.products = dataObject.products;
            this.stores = dataObject.stores;
        } else {
            this.actionCount = null;
            this.newsCount = null;
            this.recommendedCount = null;
            this.sellOutCount = null;
            this.availableOnlyCount = null;
            this.parameters = null;
            this.prices = null;
            this.producers = null;
            this.products = null;
            this.stores = null;
        }
    }

    getOnlyProductsData(append: boolean = false): void {
        //this method is called to retrieve data
        //so it is valid to presume all the page paramaters - pagesize, pageindex, producersfilter etc.
        //are set
        //so we can now save them into url
        this.setParamsIntoUrl();

        this.dataSvc.dataLoading = true;
        /**
         * Stringify the request and base64 encode it for better transmission
         */

        let productRequestString = new StringView(JSON.stringify(this.getProductRequest())).toBase64();
        this.http.get<ProductResponseModel>(`api/product?qs=${productRequestString}`)
            .pipe(
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res) => {
                    const prds = res.products;

                    if (append) {
                        const tmp = [...this.products.products, ...res.products];
                        this.products = res;
                        this.products.products = tmp;
                    } else {
                        this.products = res;
                    }

                    this.productDataChanged.emit({
                        NewProducts: prds,
                        Products: this.products.products
                    });
                },
                (err) => {
                    this.dataSvc.dataLoading = false;
                    this.errSvc.handleError(err)
                });
    }

    urlChangeOccured(id: any): void {
        let catId = parseInt(id);

        if (catId == this.lastCategoryId) {
            return;
        }

        this.init();

        this.config.categoryId = catId;
        this.config.cultureId = this.seSvc.culture.cultureId;
    }


    getSelectedFlags(): string[] {
        return this.config.selectedFlags;
    }

    setSelectedFlags(flags: string[]): void {
        this.config.selectedFlags = flags;
    }

    getPriceRange(): Array<number> {
        if (!this.config.selectedPriceRange) {
            this.config.selectedPriceRange = [null, null];
        }
        return this.config.selectedPriceRange;
    }

    setPriceRange(numbers: Array<number>) {
        if (!numbers || !Array.isArray(numbers) || numbers.length == 0) {
            numbers = [null, null];
        } else if (numbers.length == 1) {
            numbers = [numbers[0], null];
        } else {
            numbers = [numbers[0], numbers[1]];
        }
        this.config.selectedPriceRange = numbers;
    }

    setParametricfilter(paramFilter: ParametricFilterRequest): void {
        this.parametricFilter = paramFilter;
    }

    getParametricfilter(): ParametricFilterRequest {
        return this.parametricFilter;
    }

    setParamsIntoUrl() {
        this.pageStateUrlSvc.setParams(this.createUrlParams());
    }

    private createUrlParams(): StringIndexedObject<string | Array<string> | number | Array<number>> {
        const queryParams: StringIndexedObject<string | Array<string> | number | Array<number>> = {};

        //paging properties:
        queryParams[this.url_pageSizeIndex] = this.pageSizeIndex > 0 ? this.pageSizeIndex : null;
        queryParams[this.url_pageIndex] = this.pageIndex > 1 ? this.pageIndex : null;
        //

        //parametric filter:
        this.parametricFilter.convertToUrlParams(queryParams);
        //

        //producers:
        queryParams[this.url_producer] = null;
        if (this.selectedProducers && this.selectedProducers.length) {
            queryParams[this.url_producer] = this.selectedProducers;
        }

        //spots:
        queryParams[this.url_spot] = null;
        if (this.selectedSpots?.length) {
            queryParams[this.url_spot] = this.selectedSpots;
        }
        //

        //show available only:
        queryParams[this.url_showAvailableOnly] = null;
        if (this.showAvailableOnly) {
            queryParams[this.url_showAvailableOnly] = 1;
        }
        //


        //selected price range - price filteringing
        queryParams[this.url_selectedPriceRangeMin] = null;
        if (this.config.selectedPriceRange
            && this.config.selectedPriceRange.length
            && this.config.selectedPriceRange[0] != null) {
            queryParams[this.url_selectedPriceRangeMin] = this.config.selectedPriceRange[0];
        }

        queryParams[this.url_selectedPriceRangeMax] = null;
        if (this.config.selectedPriceRange
            && this.config.selectedPriceRange.length > 1
            && this.config.selectedPriceRange[1] != null) {
            queryParams[this.url_selectedPriceRangeMax] = this.config.selectedPriceRange[1];
        }
        //

        //flags
        for (const flag in this.url_flags) {
            queryParams[this.url_flags[flag]] = null;
            if (this.config.selectedFlags && this.config.selectedFlags.indexOf(flag) > -1) {
                queryParams[this.url_flags[flag]] = 1;
            }
        }
        //

        //producers:
        queryParams[this.url_stores] = null;
        if (this.selectedStores?.length) {
            queryParams[this.url_stores] = this.selectedStores;
        }
        //

        // order
        if (this.orderByIndex) {
            queryParams[this.url_orderBy] = this.orderByIndex;
        } else {
            queryParams[this.url_orderBy] = undefined;
        }
        //

        return queryParams;
    }


    loadDataFromQueryString() {
        const queryParams = this.pageStateUrlSvc.getParameters();

        //page properties:
        if (Number(queryParams[this.url_pageSizeIndex]) > -1) {
            this.pageSizeIndex = Number(queryParams[this.url_pageSizeIndex]);
        } else {
            this.pageSizeIndex = 0;
        }

        if (Number(queryParams[this.url_pageIndex]) > 0) {
            this.pageIndex = Number(queryParams[this.url_pageIndex]);
        } else {
            this.pageIndex = 1;
        }
        //

        //parametric filter:
        this.parametricFilter = new ParametricFilterRequest(ParametricFilterRequest.getParamsFilterInitializerFromUrlParams(queryParams));
        //

        //producers:
        this.selectedProducers = [];

        if (queryParams[this.url_producer]) {
            let urlProducers: Array<string> = <Array<string>>queryParams[this.url_producer];
            if (!Array.isArray(queryParams[this.url_producer])) {
                urlProducers = [<any>queryParams[this.url_producer]];
            }
            this.selectedProducers = urlProducers.map((p) => Number(p));
        }
        //

        //producers:
        this.selectedSpots = [];

        if (queryParams[this.url_spot]) {
            let urlSpots: Array<string> = <Array<string>>queryParams[this.url_spot];
            if (!Array.isArray(queryParams[this.url_spot])) {
                urlSpots = [<any>queryParams[this.url_spot]];
            }
            this.selectedSpots = urlSpots.map((p) => Number(p));
        }
        //

        //show available only:
        if (queryParams[this.url_showAvailableOnly]) {
            this.showAvailableOnly = true;
        } else {
            this.showAvailableOnly = false;
        }


        //selected price range - price filteringing
        this.config.selectedPriceRange = [null, null];
        if (queryParams[this.url_selectedPriceRangeMin] != null) {
            this.config.selectedPriceRange[0] = Number(queryParams[this.url_selectedPriceRangeMin]);
        }

        if (queryParams[this.url_selectedPriceRangeMax] != null) {
            this.config.selectedPriceRange[1] = Number(queryParams[this.url_selectedPriceRangeMax]);
        }

        //flags
        this.loadFlagsFromQueryString(queryParams);
        //

        //stores
        this.selectedStores = [];

        if (queryParams[this.url_stores]) {
            let urlStores: Array<string> = <Array<string>>queryParams[this.url_stores];
            if (!Array.isArray(queryParams[this.url_stores])) {
                urlStores = [<any>queryParams[this.url_stores]];
            }
            this.selectedStores = urlStores.map((p) => Number(p));
        }
        //

        this.orderByIndex = parseInt(queryParams[this.url_orderBy] as string);
        if (!this.orderByIndex || this.orderByIndex < 0 || this.orderByOptions.length <= this.orderByIndex) {
            this.orderByIndex = 0;
        }
    }

    private loadFlagsFromQueryString(queryParams: StringIndexedObject<string | Array<string> | number | Array<number>>) {
        const selectedFlags: Array<string> = [];
        for (const flag in this.url_flags) {
            if (queryParams[this.url_flags[flag.toString()]]) {
                selectedFlags.push(flag.toString());
            }
        }
        this.config.selectedFlags = selectedFlags;
    }

    private fillUrlFlags() {
        this.url_flags = {};
        this.url_flags[this.sharedProductSettings.Flags.bNew] = 'fn';
        this.url_flags[this.sharedProductSettings.Flags.bAction] = 'fa';
        this.url_flags[this.sharedProductSettings.Flags.bSellOut] = 'fs';
        this.url_flags[this.sharedProductSettings.Flags.bRecommended] = 'fr';
    }

    public resetFilters(): void {
        this.config.selectedStores = [];
        this.config.selectedProducers = [];
        this.config.selectedPriceRange = [null, null];
        this.config.selectedFlags = [];
        this.config.selectedSpots = [];
        this.parametricFilter.clear();
        this.config.showAvailableOnly = false;
        this.config.filterIsActive = false;

        this.filterDataChanged.emit();
        this.getCategoryDetailData();
    }

}

