import {Injectable} from '@angular/core';
import {AsyncSubject, Observable} from "rxjs";
import {IGoogleUserInfo} from "../interfaces/IGoogleUserInfo";
import {IGoogleSettings} from "../interfaces/IGoogleSettings";

declare let gapi: any, google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleApiService {

    scriptLoaded: AsyncSubject<boolean> = new AsyncSubject();
    ClientId: string;

    constructor() {
    }

    public Init(settings: IGoogleSettings): void {
        this.ClientId = settings.ClientId;
        this.addScript('https://accounts.google.com/gsi/client').then(() => {

            const handleCredentialResponse = (response: any) => {
                console.log("Encoded JWT ID token: " + response.credential);
                // probrat s Jendou proc ne?
                // this.digestSvc.login(new GoogleLogin(response.credential, null));
            }
            google.accounts.id.initialize({
                client_id: settings.ClientId,
                callback: handleCredentialResponse
            });

            this.scriptLoaded.next(true);
            this.scriptLoaded.complete();
        });
    }

    public oneTapLogin(): void {
        google.accounts.id.prompt(); // also display the One Tap dialog
    }

    public renderBtn(selector: string): void {
        const button = document.querySelectorAll(selector)[0];
        if(!button) console.error('Missing anchor element to render');
        google.accounts.id.renderButton(
            button,
            {theme: "outline", size: "large"}
        );
    }

    /*public IsSignedIn(): Observable<boolean> {
        const o: Observable<boolean> = new Observable(o => {
            this.scriptLoaded.subscribe(() => {

                const isSignedIn: boolean = gapi.auth2.getAuthInstance().isSignedIn.get();
                o.next(isSignedIn);
                o.complete();

            });
        });

        return o;
    }*/

    public Login(): Observable<IGoogleUserInfo> {
        const o: Observable<IGoogleUserInfo> = new Observable(o => {
            this.scriptLoaded.subscribe(() => {

                const options = new gapi.auth2.SigninOptionsBuilder();
                options.setPrompt('select_account');

                gapi.auth2.getAuthInstance().signIn(options).then(function (auth: any) {
                    console.log(auth);

                    auth.reloadAuthResponse().then((d: any) => {
                        const basicAuth = auth.getBasicProfile();

                        const user: IGoogleUserInfo = {
                            AccessToken: d.access_token,
                            Id: basicAuth.getId(),
                            Name: basicAuth.getName(),
                            ImageUrl: basicAuth.getImageUrl()
                        }
                        o.next(user);
                        o.complete();
                    });


                }, function (error: any) {
                    console.log(error);
                });

            });
        });

        return o;
    }

    public Logout(): Observable<void> {

        const o: Observable<void> = new Observable(o => {
            this.scriptLoaded.subscribe(() => {

                gapi.auth2.getAuthInstance().signOut().then(() => {
                    o.next();
                    o.complete();
                });

            });
        });

        return o;
    }

    public GetCurrentUserInfo(): Observable<IGoogleUserInfo> {
        const o: Observable<IGoogleUserInfo> = new Observable(o => {
            this.scriptLoaded.subscribe(() => {
                const auth = gapi.auth2.getAuthInstance().currentUser.get();
                const basicAuth = auth.getBasicProfile();

                auth.reloadAuthResponse().then((d: any) => {
                    const userInfo: IGoogleUserInfo = {
                        AccessToken: d.access_token,
                        Id: basicAuth.getId(),
                        Name: basicAuth.getName(),
                        ImageUrl: basicAuth.getImageUrl()
                    }

                    o.next(userInfo);
                    o.complete();
                });

            });
        });

        return o;
    }

    private addScript(src: string): Promise<any> {
        return new Promise<any>((resolve) => {
            const id = 'google-sign-in-api';
            let js: HTMLScriptElement = null;
            let fjs = document.getElementsByTagName('script')[0];
            if (document.getElementById(id)) {
                return;
            }
            js = <HTMLScriptElement>document.createElement('script');
            js.id = id;
            js.src = src;
            js.onload = () => {
                resolve(null);
            }
            fjs.parentNode.appendChild(js);
        });
    }
}
