import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {IRights} from "../modules/rights/commons/commons";
import {tap} from "rxjs/operators";
import {CredentialStorage} from "./credential-storage.service";

export interface IIdentity {
    UserId: number;
    Rights: IRights;
}

@Injectable({
    providedIn: 'root'
})
export class IdentityService {

    private identity: IIdentity = null;

    constructor(
        private http: HttpClient
    ) {
    }

    public GetIdentity(): Observable<IIdentity> {

        if (this.identity) {
            return of(this.identity);
        } else {
            return this.Refresh();
        }
    }

    public Refresh(): Observable<any> {

        return this.http.get<IIdentity>('api/user/identity')
            .pipe(tap(x => {
                this.identity = x;
            }));
    }

    public get IsInvoiceAllowed(): boolean {
        if(!this.identity?.Rights?.UserRights || CredentialStorage.isUserB2B === false) {
            return false;
        }

        if(this.identity?.Rights?.IsAdmin) {
            return true;
        }

        return !this.identity.Rights.UserRights.some(ur => ur.RightCode == "deny-invoice");
    }

    public get IsDeliveryNoteAllowed(): boolean {
        if(!this.identity?.Rights?.UserRights  || CredentialStorage.isUserB2B === false) {
            return false;
        }

        if(this.identity?.Rights?.IsAdmin) {
            return true;
        }

        return !this.identity.Rights.UserRights.some(ur => ur.RightCode == "deny-delivery-note");
    }

    public get IsCollaboratorsAllowed(): boolean {
        if(!this.identity?.Rights?.IsAdmin || CredentialStorage.isUserB2B === false) {
            return false;
        }

        return true;
    }

    public get IsOrderListAllowed(): boolean {
        if (CredentialStorage.isUserB2B === false) {
            return true;
        }

        if(this.identity?.Rights?.IsAdmin) {
            return true;
        }

        if(!this.identity?.Rights?.UserRights) {
            return false;
        }

        return !this.identity.Rights.UserRights.some(ur => ur.RightCode == "deny-order");
    }

    public get IsAddToCartAndOrderAllowed(): boolean {
        if (CredentialStorage.isUserB2B === false) {
            return true;
        }

        if(this.identity?.Rights?.IsAdmin) {
            return true;
        }

        if(!this.identity?.Rights?.UserRights) {
            return false;
        }

        return !this.identity.Rights.UserRights.some(ur => ur.RightCode == "deny-cart");
    }

    public get IsSettingsAllowed(): boolean {
        if (CredentialStorage.isUserB2B === false) {
            return true;
        }

        if(this.identity?.Rights?.IsAdmin) {
            return true;
        }

        return false;
    }

    public get IsEditB2bInvoiceDataAllowed(): boolean {
        if (CredentialStorage.isUserB2B === false) {
            return false;
        }

        if(this.identity?.Rights?.IsAdmin) {
            return true;
        }

        return false;
    }

    public get IsEditB2cUserAllowed(): boolean {
        if (CredentialStorage.isUserB2B === false) {
            return true;
        }

        return false;
    }

    public get IsDeliveryAddressesAllowed(): boolean {
        if (CredentialStorage.isUserB2B === false) {
            return true;
        }

        if (CredentialStorage.isUserB2B === true
            && this.identity?.Rights?.IsAdmin)
        {
            return true;
        }

        return false;
    }

}
