import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
    ProductBoxModelAvailabilityPipe,
    ProductDetailAvailabilityPipe,
    VariantAvailabilityPipe
} from "./pipes/variant-availability.pipe";
import {StoreAvailabilityComponent} from './store-availability/store-availability.component';
import {AvailabilityComponent} from "./availability/availability.component";
import { AvailabilityDialogComponent } from './availability-dialog/availability-dialog.component';
import { AvailabilityExtComponent } from './availability-ext/availability-ext.component';
import { AvailabilityAmountComponent } from './availability-amount/availability-amount.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AvailabilityComponent,
        VariantAvailabilityPipe,
        ProductBoxModelAvailabilityPipe,
        StoreAvailabilityComponent,
        AvailabilityDialogComponent,
        AvailabilityExtComponent,
        AvailabilityAmountComponent,
        ProductDetailAvailabilityPipe
    ],
    exports: [
        AvailabilityComponent,
        VariantAvailabilityPipe,
        ProductBoxModelAvailabilityPipe,
        StoreAvailabilityComponent,
        AvailabilityExtComponent,
        ProductDetailAvailabilityPipe
    ],
    providers: []
})
export class AvailabilityModule {
}
