<div *ngIf="article?.childArticles?.length > 0" class="footer-links">
    <ng-container *ngFor="let child of article?.childArticles" >
        <a  *ngIf="isArticleItemAllowed(child) && (child.url?.length && child.url.startsWith('http'))" [href]="child.url" class="footer-links__item">
            {{child.name}}
        </a>

        <a *ngIf="isArticleItemAllowed(child) && (!child.url?.length || !child.url.startsWith('http'))" [routerLink]="child.seoUrl" class="footer-links__item">
            {{child.name}}
        </a>
    </ng-container>

    <a *ngIf="showQuestionFormLink" href="javascript:void(0)" class="footer-links__item" (click)="OnSendQuestionClick()">
        {{s('send-question')}}
    </a>
</div>