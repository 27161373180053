import {AfterViewInit, Component, OnInit} from '@angular/core';
import {GoogleApiService} from "../../google-api/services/google-api.service";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

@Component({
    selector: 'cmp-google-sign-up-button',
    templateUrl: './google-sign-up-button.component.html'
})
export class GoogleSignUpButtonComponent extends Translatable implements OnInit, AfterViewInit {

    captchaFailed: boolean = false;
    clientId: string;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private gapiSvc: GoogleApiService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.clientId = this.gapiSvc.ClientId;
    }

    ngAfterViewInit() {
        this.gapiSvc.scriptLoaded.subscribe({
            next: () => {
                this.gapiSvc.renderBtn('#g-btn');
            }
        })
    }
}
