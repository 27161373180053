import {Injectable} from "@angular/core";
import {PageViewEvent, PageViewService} from "./page-view.service";
import {SettingsService} from "./settings.service";
import {GtmPageType} from "../interfaces/general";
import {UserService} from "./user.service";
import {OrderItemSelector, OrderSelector} from "../modules/cart/common";


@Injectable({
    providedIn: 'root'
})
export class GtmEventsService {

    private get isGtmOrGa(): boolean {
        if (this.seSvc.gaSettings.gtmId || this.seSvc.gaSettings.analyticsId) {
            return true;
        }
        return false;
    }

    constructor(private seSvc: SettingsService, private pvSvc: PageViewService,
                private usrSvc: UserService) {
        this.pvSvc.onPageView.subscribe((evt) => {
            this.onPageView(evt);
        });
    }


    private onPageView(evt: PageViewEvent) {
        if (!evt?.url) {
            return;
        }
        /*
        setting GTM page_view
         */
        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: 'page_view',
                page: {
                    type: GtmPageType.other,
                    path: evt.url
                },
                currency: this.seSvc.settings.currencyCodes[this.seSvc.currencyId].toUpperCase(),

                language: this.getLanguage(),

                user: this.getUser(),
            };
            if (evt?.routeData?.gtmPageType) {
                event.page.type = evt.routeData.gtmPageType;
            }

            (<any>window).dataLayer.push(event);
        }
    }

    addToCart(items: Array<GtmProductItem>) {
        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: 'add_to_cart',
                ecommerce: {
                    items: items
                },
                _clear: true
            };

            (<any>window).dataLayer.push(event);
        }
    }

    registrationSubmit() {
        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: "registration_submit"
            };

            (<any>window).dataLayer.push(event);
        }
    }

    registrationSuccess() {
        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: "registration_success",
            };

            (<any>window).dataLayer.push(event);
        }
    }

    viewItem(item: GtmProductItemBase) {
        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: "view_item",
                ecommerce: {
                    items: [item]
                }
            };

            (<any>window).dataLayer.push(event);
        }
    }

    beginCheckout(items: Array<GtmProductItemBase>) {
        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: "begin_checkout",
                ecommerce: {
                    items: items
                }
            };

            (<any>window).dataLayer.push(event);
        }
    }

    purchase(order: OrderSelector) {
        const pi: GtmPurchaseInfo = {
            transaction_id: order?.header?.id.toString(),
            currency: order.header?.currencyCode?.toUpperCase(),
            tax: this.getOrderTax(order?.items),
            value: this.getOrderValue(order),
            shipping: order?.header.shippingTypePriceWithVat,
            items: this.getOrderItems(order?.items)
        };

        if (this.isGtmOrGa) {
            (<any>window).dataLayer = (<any>window).dataLayer || [];
            const event = {
                event: "purchase",
                ecommerce: pi
            };
            console.log(event);
            (<any>window).dataLayer.push(event);
        }
    }


    private getLanguage(): string {
        const cultureCode = this.seSvc.culture.code;
        const index = cultureCode.indexOf('-');
        return cultureCode.substring(0, index);
    }

    private getUser() {
        if (!this.usrSvc?.user) {
            return null;
        }

        return {
            id: this.usrSvc.user.id.toString()
        }
    }

    private getOrderTax(items: OrderItemSelector[]): number {
        if (!items || !items.length) {
            return 0;
        }

        let tax = 0;
        items.forEach((item) => {

            if (item?.typeItem !== 'PRODUCT' && item?.typeItem !== 'GIFT') {
                return;
            }

            tax += item.priceSumWithVat - item.priceSumWithoutVat;
        });
        return Math.round((tax + Number.EPSILON) * 100) / 100;
    }

    private getOrderValue(order: OrderSelector): number {
        if (!order || !order.header) {
            return 0;
        }

        const ship = order.header.shippingTypePriceWithVat ? order.header.shippingTypePriceWithVat : 0;
        const pay = order.header.paymentPriceWithVat ? order.header.paymentPriceWithVat : 0;

        const value =
            order.header.finalPrice
            - ship - pay;
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    private getOrderItems(items: OrderItemSelector[]) {
        if (!items || !items.length) {
            return null;
        }

        const ret: Array<GtmProductItem> = [];
        items.filter(i => i.typeItem == 'PRODUCT' || i.typeItem == 'GIFT').forEach((item) => {
            ret.push({
                item_name: item.name,
                item_brand: item.producerName,
                item_category: item.categoryName,
                item_id: item.code,
                quantity: item.count,
                price: item.priceWithVat
            });
        });

        return ret;


    }
}

export interface GtmProductItemBase {
    item_id: string, //"KESU_100"
    item_name: string, //"Kešu ořechy"
    item_brand: string, //"CzechFood a.s."
    item_category: string, //"Potraviny"
    price: number, //16.00
}

export interface GtmProductItem extends GtmProductItemBase {
    quantity: number //1
}

export interface GtmPurchaseInfo {
    transaction_id: string;
    value: number;
    tax: number;
    shipping: number;
    currency: string;
    items: Array<GtmProductItem>;

}