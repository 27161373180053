<div class="store-availability">
    <ng-container *ngFor="let item of data?.Availabilities">
        <div class="store-availability__item">
            <div class="store-availability__item-col">
                <!--Skladem ikona-->
                <ng-container *ngIf="item?.Amount > 0; else elseNoQuantity1">
                    <button style="background-color: green; width: 20px; height: 20px;"></button>
                </ng-container>

                <!--Neni skladem ikona-->
                <ng-template #elseNoQuantity1>
                    <button style="background-color: red; width: 20px; height: 20px;"></button>
                </ng-template>
            </div>

            <div class="store-availability__item-col store-availability__item-col--flexible">
                {{item?.Store?.Name}}
            </div>

            <div class="store-availability__item-col">
                <ng-container *ngIf="item?.Amount > 0; else elseNoQuantity">
                    <ng-container *ngIf="item?.Amount > 1000; else elseLessThanThousand">
                        > 1000 {{data?.UnitShortcut}}
                    </ng-container>
                    <ng-template #elseLessThanThousand>
                        {{sen['availability--in-stock']}} {{item?.Amount}} <span [innerHTML]="data?.UnitShortcut"></span>
                    </ng-template>
                </ng-container>
                <ng-template #elseNoQuantity>
                    {{sen['availability--not-in-stock']}}
                </ng-template>
            </div>
        </div>
    </ng-container>
</div>