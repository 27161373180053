<div class="search" [class.search--whisperer]="suggestVisible">
    <div class="search__toggle" (click)="toggleSearch()">
        <div class="icon" style="cursor:pointer">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#search" />
            </svg>
        </div>
    </div>

    <div class="search__inner">
        <form class="search__form" action="#" (submit)="onFormSubmit()">
            <div class="search__icon">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#search" />
                    </svg>
                </div>
            </div>

            <input noAutoComplete id="js-search" type="text" #phrase class="js-search search__input" (keyup)="searchKeyUp($event)" (keypress)="searchKeyPress($event, phrase.value)" name="q" [placeholder]="'Hledejte podle názvu, kategorie, kódu ...'">

            <button class="search__btn btn btn--medium btn--primary" (click)="search(phrase.value)" type="button">
                {{sen['app-search']}}
            </button>
        </form>

        <cmp-flex-suggest-list *ngIf="(suggestVisible || loading) && expression.length > 2" [loading]="loading" [expression]="expression" [suggestResult]="suggestResult" (queryHide)="suggestVisible = false" [phrase]="phrase.value"></cmp-flex-suggest-list>
    </div>
</div>
