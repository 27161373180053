import {ArticleBriefSelector, PageRequest, PageResult} from "../../interfaces/general";

export class ArticleConfigInitializer {
    pageSizeIndex?: number;
    pageIndex?: number = 1;
    pageSizes: any[];
}

export class ArticleConfig extends ArticleConfigInitializer {

    constructor(init: ArticleConfigInitializer) {
        super();
        this.pageSizeIndex = init.pageSizeIndex;
        this.pageIndex = init.pageIndex;
        this.pageSizes = init.pageSizes;
    }

    public get pageSize(): number {

        if (!this.pageSizes || this.pageSizeIndex == null || this.pageSizeIndex < 0) {
            return null;
        }
        return this.pageSizes[this.pageSizeIndex].value;
    }

    public set pageSize(value: number) {
        if (!this.pageSizes || !value) {
            return;
        }

        let pageSize = this.pageSizes.find(ps => ps.value == value);
        if (pageSize) {
            this.pageSizeIndex = this.pageSizes.indexOf(pageSize);
        }
    }
}

export interface IArtPageRequest extends PageRequest{
    ParentId: string;
}

export interface IArtPageResult extends PageResult<ArticleBriefSelector>{
    title: string;
    name: string;
    seoDescription: string;
    seoUrl: string;
    imagePath: string;
    isSiteMap: boolean;
    annotation: string;
}