<!--<button class="modal-login__facebook btn btn--medium" style="background-color: indianred" (click)="executeCaptcha()">
	G
	<div class="icon">
		<svg>
			<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#google"/>
		</svg>
	</div>

	{{sen['login-by-google-account']}}
</button>
<label *ngIf="captchaFailed">Captcha failed</label>


<div id="g_id_onload"
	 data-client_id="825409065287-vhf0863fbpkhelmcolqkf7akja8fak45.apps.googleusercontent.com"
	 data-context="signin"
	 data-ux_mode="popup"
	 data-login_uri="https://advacz.org"
	 data-itp_support="true">
</div>

<div class="g_id_signin"
	 data-type="standard"
	 data-shape="rectangular"
	 data-theme="outline"
	 data-text="signin_with"
	 data-size="large"
	 data-logo_alignment="left">
</div>-->

<div id="g-btn"></div>