import {Injectable} from '@angular/core';
import {FlexCaptchaService} from "../../../services/flex-captcha.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IQuestionFormRequest} from "../commons/common";
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class QuestionService {

    constructor(
        private http: HttpClient,
        private fcSvc: FlexCaptchaService,
    ) {

    }

    public SendQuestion(request: IQuestionFormRequest): Observable<boolean> {
        const s: Subject<boolean> = new Subject<boolean>();

        this.fcSvc.executeCaptcha('sendquestion', 'sendquestion')
            .subscribe(res => {
                if (res.success) {
                    const headers = new HttpHeaders({'Captcha-Secret': res.secret});
                    this.http.post<boolean>('api/question/send-question', request, {headers: headers})
                        .subscribe((res2) => {
                            s.next(res2);
                            s.complete();
                        }, (e) => {
                            s.error(e);
                            s.complete();
                        })
                } else {
                    s.error(null);
                    s.complete();
                }
            });

        return s;
    }
}
