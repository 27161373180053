<div [class]="'modal-window' + (cssClassModifier?(' modal-window--'+cssClassModifier):'')" (mousedown)="closeDialog()">
    <div class="modal-window__wrap" (mousedown)="$event.stopPropagation()">
        <div class="modal-window__close" (click)="closeDialog()" *ngIf="isClosable">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle" />
                </svg>
            </div>
        </div>

        <div class="modal-window__title heading-h1" *ngIf="title">
            {{title}}
        </div>

        <ng-container generalDialogInsertion></ng-container>
    </div>
</div>